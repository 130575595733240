import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {API_URLS, Endpoints} from '@odp/shared';
import * as CONST from '../../../tokens/constants';
import {DrugResult} from '../../../models/view-models/screen-data-models';

@Injectable({
  providedIn: 'root'
})
export class ScreeningDataApiService {
  constructor(@Inject(API_URLS) private configuration: Endpoints, private httpClient: HttpClient) {}

  public getHeatmapData(libraryId: number, pageId: number, pageSize: number) {
    return this.httpClient.get<any[]>(
      `${this.configuration.covid19ApiUrl}/sample/data/${libraryId}/${pageId}/${pageSize}`
    );
  }
  public getSuggestions(text: string) {
    return this.httpClient
      .get<any>(`${this.configuration.covid19ApiUrl}/util/autocom?top=10&query=${text}`)
      .pipe(map((res) => res.suggestions));
  }
  public sortSelection(order: string, assayId: string, libraryId: number, pageNum: number, pageSize: number) {
    return this.httpClient.get<any[]>(
      `${this.configuration.covid19ApiUrl}/sample/sort/${order}/${libraryId}/${assayId}/${pageNum}/${pageSize}`
    );
  }
  public search(item: string): Observable<any> {
    return this.httpClient.get(`${this.configuration.covid19ApiUrl}/sample/data/search/${item}`);
  }

  public getSampleData(sampleId: number) {
    return this.httpClient.get<any[]>(`${this.configuration.covid19ApiUrl}/qhts/sdid/${sampleId}`);
  }

  public getCurveChart(sampleId: number): Observable<string> {
    return this.httpClient.get<string>(`${this.configuration.covid19ApiUrl}/extapi/curve/sdid/${sampleId}/400/350`);
  }

  public getSmileImage(smiles: string) {
    const smilesEncoded = encodeURI(smiles).replace(/\+/g, '%2B').replace(/#/g, '%23');

    return this.httpClient.get<any>(
      `${this.configuration.covid19ApiUrl}/route/ext/structure?size=220&smiles=${smilesEncoded}`
    );
  }

  public getDrugNameData(SummaryId: string) {
    return this.httpClient.get<DrugResult>(`${this.configuration.covid19ApiUrl}/qhts/sample/summary/${SummaryId}`);
  }

  public getNCATSInxight(CASId: string) {
    return this.httpClient.get<any[]>(`${this.configuration.covid19ApiUrl}/extapi/unii/${CASId}`);
  }

  public getPerformAgents(drugId: string) {
    return this.httpClient.get<any[]>(`${this.configuration.covid19ApiUrl}/qhts/sample/perform/${drugId}`);
  }

  public getMultisdid(payloadId: any) {
    const body = {
      payload: {sampleDataIds: [payloadId], settings: {width: 350, height: 350, legend: false}}
    };
    return this.httpClient.post<any[]>(`${this.configuration.covid19ApiUrl}/extapi/curve/multisdid`, body.payload);
  }
}
