import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {InVivoQuery} from '../models/dtos/in-vivo/invivo-ql';

@Injectable({
  providedIn: 'root'
})
export class InVivoQL extends Query<InVivoQuery, {}> {
  override document = gql`
    {
      variantinvivos {
        edges {
          node {
            id
            reportNumber
            provider
            title
            dataSource
            reportedDate
            studyType
            therapeuticClasses
            therapeuticAgents
            model
            modelStrain
            modelSource
            therapeutic1Class
            therapeutic1
            administrationMethod1
            dose1
            regimen1
            treatment1Notes
            therapeutic1Details
            therapeutic2Class
            therapeutic2
            administrationMethod2
            dose2
            regimen2
            treatment2Notes
            therapeutic2Details
            variants
            variantModifications
            whoDesignation
            referenceStrain
            inoculationMethod
            inoculationDose
            challengeNotes
            challengeDetails
            clinicalManifestastions
            viralLoad
            histopathology
            neutralization
            antibodyResponse
            otherDatabaseReportNumber
            animalModelsLinkedPage
            dataUpdatedDate
            transmission
            rechallenge
          }
        }
      }
    }
  `;
}
