import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'app-datasource-renderer',
  template: ` <app-external-link [url]="params?.value" [text]="'Link'"></app-external-link> `,
  styles: ['app-external-link {color: #007bff; cursor: pointer;}  ::ng-deep i {font-size: .7rem;}']
})
export class DatasourceRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
