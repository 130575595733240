import {Injectable} from '@angular/core';
import {RWEPoint} from '../../../models/dtos/rwe/rwe';
import {SelectedRWEFilters} from '../rwe-filters/rwe-filters.component';

@Injectable({
  providedIn: 'root'
})
export class RWEFilterService {
  constructor() {}

  public filterPoints(allRWEPoints: RWEPoint[], filters: SelectedRWEFilters): RWEPoint[] {
    const result = [];
    const colfilters = filters.cols;
    for (const record of allRWEPoints) {
      if (
        this.searchInFields(colfilters.get('Treatment') ?? [], record.drug1 || record.drug2 || record.drug3) &&
        (this.searchForChecked(colfilters.get('Endpoint') ?? [], 'Hospitalization', record.hospitalizationEndpoint) ||
          this.searchForChecked(colfilters.get('Endpoint') ?? [], 'Mortality', record.mortalityEndpoint) ||
          this.searchForChecked(colfilters.get('Endpoint') ?? [], 'Other', record.otherEndpoint)) &&
        this.searchInFields(colfilters.get('Lineage (Variant)') ?? [], record.lineage)
      ) {
        result.push(record);
      }
    }
    return result;
  }

  private searchForChecked(data: string[], colName: string, entryValue: string): boolean {
    // the data.length === 0 check allows all records to be returned if no filters are selected
    if (data.length === 0 || data[0] === colName) {
      if (entryValue.trim() === 'Yes') {
        return true;
      }
      if (entryValue.trim() === 'No') {
        return false;
      }
    }
    // not sure what this is for frankly (TT)
    if (data.indexOf(colName) > -1) {
      return !!entryValue;
    }
    return false;
  }

  private searchDateRange(dateRange: any, reportDate: string) {
    if (dateRange) {
      if (!dateRange.startDate) {
        return true;
      }
      if (
        Date.parse(reportDate) >= Date.parse(dateRange.startDate) &&
        Date.parse(reportDate) <= Date.parse(dateRange.endDate)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  private searchInFields(data: string[], record: string) {
    if (!data.length) {
      return true;
    }
    let found = false;
    for (const d of data) {
      if (d.trim() === record.trim()) {
        found = true;
      }
      if (record.indexOf(';') > -1) {
        const entries = record.split(';');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
      if (record.indexOf(',') > -1) {
        const entries = record.split(',');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
    }
    return found;
  }
}
