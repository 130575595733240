import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {HeterologousBoosterQL} from '../../../queries/heterologousbooster-query';
import {map, Observable} from 'rxjs';
import {HeterologousBoosterPoint} from '../../../models/dtos/heterologous-booster/heterologousbooster';
import {API_URLS, Endpoints} from '@odp/shared';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {
  HeterologousBoosterEdge,
  HeterologousBoosterQuery
} from '../../../models/dtos/heterologous-booster/heterologousbooster-ql';

@Injectable({
  providedIn: 'root'
})
export class HeterologousBoosterApiService {
  private variantBaseUrl!: string;

  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private heterologousBoosterQL: HeterologousBoosterQL
  ) {
    this.variantBaseUrl = configuration.graphqlEndpoint; // hit the postgres endpoint
  }

  public getHeterologousBooster(): Observable<HeterologousBoosterPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<HeterologousBoosterQuery>({query: this.heterologousBoosterQL.document})
      .pipe(
        map((result: {data: HeterologousBoosterQuery}) => {
          return this.mapHeterologousBoosterToEntry(result.data.heterologousboosters.edges);
        })
      );
  }

  public getHeterologousBoostersByVac1Boost1Class(vacBoost1: string): Observable<HeterologousBoosterPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<HeterologousBoosterQuery>({
        query: this.heterologousBoosterQL.getHeterologousBoostersByVac1Boost1Class,
        variables: {vacBoost1: vacBoost1, boost1Class: 'Heterologous'}
      })
      .pipe(
        map((result: {data: HeterologousBoosterQuery}) => {
          return this.mapHeterologousBoosterToEntry(result.data.heterologousboosters.edges);
        })
      );
  }

  public mapHeterologousBoosterToEntry(heterologousEntry: HeterologousBoosterEdge[]): HeterologousBoosterPoint[] {
    const enteries = heterologousEntry.map((e) => {
      const heterologous: HeterologousBoosterPoint = {
        hbReportNumber: Math.round(Number(e.node.hbReportNumber)),
        dataTitle: e.node.dataTitle,
        dataSource: e.node.dataSource,
        dataSourceType: e.node.dataSourceType,
        dataDate: e.node.dataDate,
        sampleSource: e.node.sampleSource,
        vacPrime: e.node.vacPrime,
        vacBoost1: e.node.vacBoost1,
        boost1Class: e.node.boost1Class,
        assayCat: e.node.assayCat,
        assayType: e.node.assayType,
        assayTypeMod: e.node.assayTypeMod,
        viralSublineage: e.node.viralSublineage,
        vaccineActivity: e.node.vaccineActivity,
        boost1TimeUnit: e.node.boost1TimeUnit,
        boost1Time1: e.node.boost1Time1,
        boost1Time1Unit: e.node.boost1Time1 ? `${e.node.boost1Time1} ${e.node.boost1TimeUnit}` : '',
        boost1Effect1: e.node.boost1Effect1,
        boost1Time2: e.node.boost1Time2,
        boost1Time2Unit: e.node.boost1Time2 ? `${e.node.boost1Time2} ${e.node.boost1TimeUnit}` : '',
        boost1Effect2: e.node.boost1Effect2,
        boost1Time3: e.node.boost1Time3,
        boost1Time3Unit: e.node.boost1Time3 ? `${e.node.boost1Time3} ${e.node.boost1TimeUnit}` : '',
        boost1Effect3: e.node.boost1Effect3,
        id: ''
      };
      return heterologous;
    });
    return enteries;
  }
}
