<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          <span class="italics">In vivo</span> Variant Studies
          <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span>
        </h3>
        <div class="pb-4">
          <div>
            Browse high-level summaries of published / shared datasets with in vivo models of SARS-CoV-2 variant
            infection.
          </div>
          <div>
            <a
              class="font-size-mid"
              href="javascript:void(0)"
              placement="right"
              ngbPopover="The OpenData Portal currently curates metadata from in vivo studies reporting use of SARS-CoV-2 variant strains in challenge models"
              >(Which <span class="italics">in vivo</span> studies are being collected here?)</a
            >
          </div>
        </div>

        <a
          href="{{ variantBaseUrl }}/route/invivo/download/all"
          class="btn btn-primary bg-white download-all"
          *ngIf="!dataLoading"
        >
          Download <span class="italics">in vivo</span> study table
        </a>
      </div>
    </div>
  </div>
</div>
