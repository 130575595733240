<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container nav-container">
  <div class="col-12 p-0 py-3">
    <p><a [routerLink]="['/covid19/']">Home </a> > SARS-CoV-2 Screening Data > GS-441524 Studies</p>
  </div>
  <div class="col-12 p-0 py-3">
    <div class="h3">GS-441524 Studies</div>
    <div>Updated 03.26.2024</div>
  </div>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Background">
      <div class="tab-content active background">
        <div class="row">
          <div class="col-md-12 subtitle mt-4">Project Goal</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <p>NCATS is evaluating GS-441524 as a potential treatment for SARS-CoV-2 infection.</p>

            <p>
              Remdesivir is a phosphoramidate prodrug of nucleoside analog GS-441524, which is itself a prodrug of the
              active compound GS-441524 triphosphate (GS-443902). GS-443902 functions as a pseudosubstrate of SARS-CoV-2
              RNA dependent RNA polymerase (RdRP), an enzyme essential for viral RNA replication in host cells.
            </p>

            <p>
              GS-441524, together with its prodrugs/analogs, may have potential to be developed as an oral drug
              candidate for COVID-19 treatment.
              <strong
                >NCATS is working to assess the oral bioavailability of GS-441524, and is evaluating its in vitro and in
                vivo ADME (absorption, distribution, metabolism, and elimination) properties to determine its
                suitability as a potential clinical candidate.</strong
              >
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 subtitle mt-4">Candidate</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-5">
            <div class="row sample-info">
              <div class="col-md-6 info-name">Name :</div>
              <div class="col-md-6 info-value">GS-441524</div>
            </div>
            <div class="row sample-info">
              <div class="col-md-6 info-name">NCATS ID :</div>
              <div class="col-md-6 info-value">NCGC00686670</div>
            </div>
            <div class="row sample-info">
              <div class="col-md-6 info-name">PubChem CID :</div>
              <div class="col-md-6 info-value">
                <a href="https://pubchem.ncbi.nlm.nih.gov/compound/44468216" target="_blank">44468216</a>
              </div>
            </div>
            <div class="row sample-info">
              <div class="col-md-6 info-name">Molecular Weight :</div>
              <div class="col-md-6 info-value">291.26</div>
            </div>
          </div>
          <div class="col-md-7">
            <img src="./assets/svg/GS-441524-10.svg" style="width: 300px" />
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="DMPK Studies">
      <div class="tab-content">
        <div class="row title-section">
          <div class="col-md-12 subtitle mt-4 p-0">DMPK Summary</div>
          <p>Complete descriptions of these studies, including detailed methods, have been published:</p>

          <p>
            <strong
              >Preclinical Pharmacokinetics and In Vitro Properties of GS-441524, A Potential Oral Drug Candidate for
              COVID-19 Treatment</strong
            >. Amy Q. Wang*, Natalie R. Hagen, Elias C. Padilha, Mengbi Yang, Pranav Shah, Catherine Z. Chen, Wenwei
            Huang, Pramod Terse, Philip Sanderson, Wei Zheng, and Xin Xu*. Front. Pharmacol. (2022).
            <a target="_blank" href="https://doi.org/10.3389/fphar.2022.918083">doi: 10.3389/fphar.2022.918083.</a> 
          </p>

          <p>
            <strong>UPLC-MS/MS Method Development for GS-441524 and Preclinical Pharmacokinetic Studies.</strong> Wang
            AQ*, Natalie R. Hagen, Wenwei Huang, and Xu X. HPLC2022: International Chromatographic Conference (June,
            2022).
          </p>
          <div class="col-md-12 subtitle mt-4">GS-441524: PK Property Summary</div>
        </div>
        <div class="row pt-2">
          <div class="col-md-12">
            <div class="container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" class="h5 font-weight-bold" colspan="2">IV Administration*</th>
                    <th scope="col" class="h5 font-weight-bold" colspan="5">PO Administration**</th>
                  </tr>
                  <tr>
                    <th scope="col">Species</th>
                    <th scope="col">CL<sub>p</sub> (mL/min/kg)</th>
                    <th scope="col">Vd<sub>ss</sub> (L/kg)</th>
                    <th scope="col">C<sub>max</sub> (ng/mL)</th>
                    <th scope="col">T<sub>max</sub> (hr)</th>
                    <th scope="col">AUC (ng.hr/mL)</th>
                    <th scope="col">t<sub>1/2</sub> (hr)</th>
                    <th scope="col">F (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Mouse</th>
                    <td>26</td>
                    <td>2.4</td>
                    <td>582</td>
                    <td>1.5</td>
                    <td>2540</td>
                    <td>3.9</td>
                    <td>39</td>
                  </tr>
                  <tr>
                    <th scope="row">Rat</th>
                    <td>25</td>
                    <td>2.2</td>
                    <td>193</td>
                    <td>3.8</td>
                    <td>2170</td>
                    <td>3.4</td>
                    <td>33</td>
                  </tr>
                  <tr>
                    <th scope="row">Dog</th>
                    <td>4.1</td>
                    <td>0.92</td>
                    <td>6010</td>
                    <td>0.28</td>
                    <td>19000</td>
                    <td>4.1</td>
                    <td>85</td>
                  </tr>
                  <tr>
                    <th scope="row">Cyno</th>
                    <td>9.5</td>
                    <td>1.1</td>
                    <td>59.4</td>
                    <td>2.0</td>
                    <td>734</td>
                    <td>7.7</td>
                    <td>8.3</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12 ml-1 pt-3">
            <strong>*</strong> IV dose: 5 mg/kg from mouse/rat; 2 mg/kg from dog/cyno
          </div>
          <div class="col-md-12 ml-1"><strong>**</strong> PO dose: 10 mg/kg from mouse/rat; 5 mg/kg from dog/cyno</div>
        </div>
        <div class="row">
          <div class="col-md-12 subtitle mb-2 mt-4">GS-441524 PK Property Overview</div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul>
              <li>Low to moderate clearance (CL<sub>p</sub>)</li>
              <li>Moderate volume of distribution (Vd<sub>ss</sub>)</li>
              <li>Large variation in oral bioavailability (F), from 8.3% in cyno to 85% in dog</li>
              <li>GS-441524 for these studies was purchased from AK Scientific and characterized by NCATS</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 subtitle pk-study-header mb-2 mt-2" (click)="toggleSection('mouse')">
            PK Study: C57 Mouse&nbsp;&nbsp;<i
              class="fas {{ studyDisplayStatuses['mouse'] ? 'fa-chevron-down' : 'fa-chevron-up' }}"
            ></i>
          </div>
        </div>
        <div class="row" [hidden]="!studyDisplayStatuses['mouse']">
          <div class="col-md-2 mt-1 mb-1"><strong>Route/Dosing :</strong></div>
          <div class="col-md-9 mt-1 mb-1">IV 5 mg/kg or PO 10 mg/kg</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Study Details :</strong></div>
          <div class="col-md-9 mt-1 mb-1">n=3 per time point, non-fasted</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Sample Collected :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Plasma collection over 24 hr</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Measurement :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Drug concentration via UPLC-MS/MS</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Formulation :</strong></div>
          <div class="col-md-9 mt-1 mb-1">
            5% ethanol, 30% PG, 45% PEG-400, and 20% water + 1 equivalent HCI (up to 2.5 mg/mL, freshly prepared)<br />
            (Formulation protocol provided by <i>Gilead Sciences</i>)
          </div>

          <div class="col-md-12">
            <div class="row" id="study_mouse_property"></div>
            <div class="row pt-3">
              <div class="col-md-6">
                <div class="h6">Calculated PK Properties</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">IV Administration</th>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">PO Administration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CL<sub>p</sub> (mL/min/kg)</td>
                      <td>26</td>
                      <td>C<sub>max</sub> (ng/mL)</td>
                      <td>582</td>
                    </tr>
                    <tr>
                      <td>Vd<sub>ss</sub> (L/kg)</td>
                      <td>2.4</td>
                      <td>T<sub>max</sub> (hr)</td>
                      <td>1.5</td>
                    </tr>
                    <tr>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>3.3</td>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>3.9</td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td>AUC (ng.hr/mL)</td>
                      <td>2540</td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td>F (%)</td>
                      <td>39</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <div class="h6 pl-4">C57 Mouse PK</div>
                <div #mouse_chart style="width: 547px"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 subtitle pk-study-header mb-2 mt-2" (click)="toggleSection('rat')">
            PK Study: SD Rat&nbsp;&nbsp;<i
              class="fas {{ studyDisplayStatuses['rat'] ? 'fa-chevron-down' : 'fa-chevron-up' }}"
            ></i>
          </div>
        </div>
        <div class="row" [hidden]="!studyDisplayStatuses['rat']">
          <div class="col-md-2 mt-1 mb-1"><strong>Route/Dosing :</strong></div>
          <div class="col-md-9 mt-1 mb-1">IV 5 mg/kg or PO 10 mg/kg</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Study Details :</strong></div>
          <div class="col-md-9 mt-1 mb-1">n=3-4 per route, fasted</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Sample Collected :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Plasma collection over 24 hr</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Measurement :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Drug concentration via UPLC-MS/MS</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Formulation :</strong></div>
          <div class="col-md-9 mt-1 mb-1">
            5% ethanol, 30% PG, 45% PEG-400, and 20% water + 1 equivalent HCI (up to 2.5 mg/mL, freshly prepared)<br />
            (Formulation protocol provided by <i>Gilead Sciences</i>)
          </div>
          <div class="col-md-12">
            <div class="row" id="study_rat_property"></div>
            <div class="row pt-3">
              <div class="col-md-6">
                <div class="h6">Calculated PK Properties</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">IV Administration</th>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">PO Administration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CL<sub>p</sub> (mL/min/kg)</td>
                      <td>25</td>
                      <td>C<sub>max</sub> (ng/mL)</td>
                      <td>193</td>
                    </tr>
                    <tr>
                      <td>Vd<sub>ss</sub> (L/kg)</td>
                      <td>2.2</td>
                      <td>T<sub>max</sub> (hr)</td>
                      <td>3.8</td>
                    </tr>
                    <tr>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>2.7</td>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>3.4</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>AUC (ng.hr/mL)</td>
                      <td>2170</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>F (%)</td>
                      <td>33</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <div class="h6 pl-4">Rat PK</div>
                <div #rat_chart style="width: 547px"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 subtitle pk-study-header mb-2 mt-2" (click)="toggleSection('dog')">
            PK Study: Dog&nbsp;&nbsp;<i
              class="fas {{ studyDisplayStatuses['dog'] ? 'fa-chevron-down' : 'fa-chevron-up' }}"
            ></i>
          </div>
        </div>
        <div class="row" [hidden]="!studyDisplayStatuses['dog']">
          <div class="col-md-2 mt-1 mb-1"><strong>Route/Dosing :</strong></div>
          <div class="col-md-9 mt-1 mb-1">IV 2 mg/kg or PO 5 mg/kg</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Study Details :</strong></div>
          <div class="col-md-9 mt-1 mb-1">n=3 per course, fasted</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Sample Collected :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Plasma & urine, over 48 hr</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Measurement :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Drug concentration via UPLC-MS/MS</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Formulation :</strong></div>
          <div class="col-md-9 mt-1 mb-1">
            5% ethanol, 30% PG, 45% PEG-400, and 20% water + 1 equivalent HCI (up to 2.5 mg/mL, freshly prepared)<br />
            (Formulation protocol provided by <i>Gilead Sciences</i>)
          </div>
          <div class="col-md-12">
            <div class="row" id="study_dog_property"></div>
            <div class="row pt-3">
              <div class="col-md-6">
                <div class="h6">Calculated PK Properties</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">IV Administration</th>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">PO Administration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CL<sub>p</sub> (mL/min/kg)</td>
                      <td>41</td>
                      <td>C<sub>max</sub> (ng/mL)</td>
                      <td>6010</td>
                    </tr>
                    <tr>
                      <td>Vd<sub>ss</sub> (L/kg)</td>
                      <td>0.92</td>
                      <td>T<sub>max</sub> (hr)</td>
                      <td>0.28</td>
                    </tr>
                    <tr>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>4.0</td>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>4.1</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>AUC (ng.hr/mL)</td>
                      <td>19000</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>F (%)</td>
                      <td>85</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <div class="h6 pl-4">Dog PK</div>
                <div #dog_chart style="width: 547px"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 subtitle pk-study-header mb-2 mt-2" (click)="toggleSection('cyno')">
            PK Study: Cynomolgus Macaque&nbsp;&nbsp;<i
              class="fas {{ studyDisplayStatuses['cyno'] ? 'fa-chevron-down' : 'fa-chevron-up' }}"
            ></i>
          </div>
        </div>
        <div class="row" [hidden]="!studyDisplayStatuses['cyno']">
          <div class="col-md-2 mt-1 mb-1"><strong>Route/Dosing :</strong></div>
          <div class="col-md-9 mt-1 mb-1">IV 2 mg/kg or PO 5 mg/kg</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Study Details :</strong></div>
          <div class="col-md-9 mt-1 mb-1">n=3 per course, fasted</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Sample Collected :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Plasma & urine, over 48 hr</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Measurement :</strong></div>
          <div class="col-md-9 mt-1 mb-1">Drug concentration via UPLC-MS/MS</div>
          <div class="col-md-2 mt-1 mb-1"><strong>Formulation :</strong></div>
          <div class="col-md-9 mt-1 mb-1">
            5% ethanol, 30% PG, 45% PEG-400, and 20% water + 1 equivalent HCI (up to 2.5 mg/mL, freshly prepared)<br />
            (Formulation protocol provided by <i>Gilead Sciences</i>)
          </div>
          <div class="col-md-12">
            <div class="row"></div>
            <div class="row pt-3">
              <div class="col-md-6">
                <div class="h6">Calculated PK Properties</div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">IV Administration</th>
                      <th scope="col" colspan="2" class="text-center h5 font-weight-bold">PO Administration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CL<sub>p</sub> (mL/min/kg)</td>
                      <td>9.5</td>
                      <td>C<sub>max</sub> (ng/mL)</td>
                      <td>59.4</td>
                    </tr>
                    <tr>
                      <td>Vd<sub>ss</sub> (L/kg)</td>
                      <td>1.1</td>
                      <td>T<sub>max</sub> (hr)</td>
                      <td>2.0</td>
                    </tr>
                    <tr>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>2.4</td>
                      <td>t<sub>1/2</sub> (hr)</td>
                      <td>7.7</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>AUC (ng.hr/mL)</td>
                      <td>734</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>F (%)</td>
                      <td>8.3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <div class="h6 pl-4">Cynomolgus Macaque PK<sup>*</sup></div>
                <div #cyno_chart style="width: 547px"></div>
                <div class="pl-4">*Concentration at 48 hr was &lt; LLOQ of 1 ng/mL</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Toxicology Studies">
      <div class="tab-content">
        <div class="row mt-4">
          <div class="col-md-12 subtitle-no-size h3">GS-441524: 7-Day Dose Range Finding Toxicology</div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            Non-GLP Dose Range Finding Toxicology (DRF) studies in three species are complete. These studies inform
            species selection and doses for GLP toxicology studies.
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-8">
            <div class="container">
              <table class="table table-bordered">
                <thead>
                  <tr class="table-head font-weight-bold">
                    <th scope="col">Species</th>
                    <th scope="col">High dose (mg/kg)</th>
                    <th scope="col">C<sub>max</sub> (ng/mL)*</th>
                    <th scope="col">AUC<sub>last</sub> (ng.hr/mL)*</th>
                    <th scope="col">MFD** (mg/kg/day)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Rat</th>
                    <td>750</td>
                    <td>1,800 (M)<br />2,800 (F)</td>
                    <td>24,000 (M)<br />33,000 (F)</td>
                    <td>1,500</td>
                  </tr>
                  <tr>
                    <th scope="row">Dog</th>
                    <td>1,000</td>
                    <td>29,000 (F)</td>
                    <td>160,000 (F)</td>
                    <td>2,000</td>
                  </tr>
                  <tr>
                    <th scope="row">Cyno</th>
                    <td>500</td>
                    <td>430 (M)<br />600 (F)</td>
                    <td>2,600 (M)<br />3,900 (F)</td>
                    <td>1,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pl-4 mt-1">* day 7 PK parameters</div>
        </div>
        <div class="row">
          <div class="col-md-12 pl-4 mt-1">** Maximum feasible dose</div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12 subtitle-black">GS-441524 Study Overview</div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <ul>
              <li>PO, BID dosing</li>
              <li>
                Formulation: 5% ethanol, 30% PG, 45% PEG-400, 20% water, 1 equiv. HCl (rat); powder in capsule (dog);
                0.5% CMC in water (cyno)
              </li>
              <li>GS-441524 purchased from Alichem and characterized by NCATS</li>
              <li>Preliminary data, final study reports pending</li>
              <li>
                GS-441524 well tolerated at the maximum feasible dose, supporting progression to GLP toxicology studies
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 subtitle-black">GS-441524: GLP Toxicology</div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <p>IND enabling studies completed.</p>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Clinical Actvities">
      <div class="tab-content">
        <div class="row mt-4">
          <div class="col-md-12 subtitle">Chemistry, Manufacturing and Controls (CMC)</div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">Drug product manufacture has been completed.</div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12 subtitle">GS-441524: Clinical Studies</div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <p>An IND application has been submitted to the FDA.</p>
            <p>
              A Phase 1 clinical trial - “GS-441524 for COVID-19 SAD, FE, and MAD Study in Health Subjects” (<a
                href="https://clinicaltrials.gov/study/NCT06274853"
                >NCT06274853</a
              >) - was registered on February 2024.
            </p>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
