<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="main pb-3" *ngIf="variantDrug">
  <div class="container-fluid hero">
    <div class="container py-4">
      <div class="row px-3 pb-3">
        <div class="col-9 p-0">
          <p class="mb-0">
            <a [routerLink]="['/covid19']">Home </a> >
            <a [routerLink]="['/covid19/therapeutic-glossary']"> Therapeutic Glossary </a> >
            {{ variantDrug.drugName }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!dataLoading && variantDrug; else loader">
    <div class="table-container container-fluid py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div [ngSwitch]="drug.glossaryFdastatus">
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #74a722"
                *ngSwitchCase="'FDA approved'"
                >{{ drug.glossaryFdastatus }}</span
              >
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #c9e0a5; color: black"
                *ngSwitchCase="'EUA approved'"
                >{{ drug.glossaryFdastatus }}</span
              >
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #a75522"
                *ngSwitchCase="'FDA revoked'"
                >{{ drug.glossaryFdastatus }}</span
              >
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #e0aca5; color: black"
                *ngSwitchCase="'EUA revoked'"
                >{{ drug.glossaryFdastatus }}</span
              >

              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #bfbf15"
                *ngSwitchCase="'Investigational'"
                >{{ drug.glossaryFdastatus }}</span
              >
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #226fa7"
                *ngSwitchCase="'Research reagent'"
                >{{ drug.glossaryFdastatus }}</span
              >
              <span
                class="fda-status badge badge-secondary rounded-0 px-3"
                style="background-color: #3c3c3c"
                *ngSwitchCase="'Discontinued'"
                >{{ drug.glossaryFdastatus }}</span
              >
            </div>
            <div class="bg-white p-3">
              <div class="row">
                <div class="col-12 title">
                  <h2>{{ variantDrug.drugName }}</h2>
                </div>
                <div class="col-12">
                  <div class="drug-class">{{ variantDrug.drugClass }}</div>
                  <div class="glossary-moa font-italic">{{ drug.glossaryMoa }}</div>
                </div>
                <div class="col-12 mt-3" *ngIf="variantDrug.drugIconUrlPng">
                  <img class="img-fluid mx-auto d-block" src="{{ variantDrug.drugIconUrlPng }}" alt="" />
                </div>
                <div class="col-12 p-0">
                  <div class="nav flex-column nav-pills me-3 mt-3" aria-orientation="vertical">
                    <a
                      (click)="scroll(summary, 'summary')"
                      class="nav-link"
                      [ngClass]="{selectedNav: onScrollTap === 'summary'}"
                      type="a"
                      role="tab"
                      >Summary</a
                    >
                    <a
                      (click)="scroll(about, 'about')"
                      class="nav-link"
                      [ngClass]="{selectedNav: onScrollTap === 'about'}"
                      type="a"
                      role="tab"
                      >About</a
                    >
                    <a
                      *ngIf="numberOfHDatapoint > 0"
                      (click)="scroll(hBooster, 'hBooster')"
                      class="nav-link"
                      [ngClass]="{selectedNav: onScrollTap === 'hBooster'}"
                      type="a"
                      role="tab"
                      >Heterologous Booster Data</a
                    >
                    <a
                      *ngIf="numberOfMDatapoint > 0"
                      (click)="scroll(mBooster, 'mBooster')"
                      class="nav-link"
                      [ngClass]="{selectedNav: onScrollTap === 'mBooster'}"
                      type="a"
                      role="tab"
                      >Multivalent Booster Data</a
                    >
                    <!-- <a href="therapeutic-glossary/{{ drug.id }}#vitro" class="nav-link" type="a" role="tab"
                      >In vitro Variant Data</a
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div #summary class="bg-white p-3">
              <h3 class="sub-title">Summary</h3>
              <p class="glossary-summary">
                {{ drug.glossarySummary }}
              </p>
            </div>
            <div #about class="bg-white p-3 mt-3">
              <h3 class="sub-title">About</h3>
              <div class="row mb-4">
                <div class="col-md-5 font-weight-bold">Therapeutic Class</div>
                <div class="col-md-7">
                  <span>{{ variantDrug.drugClass }}</span>
                </div>
                <div class="col-md-5 font-weight-bold">Subclass</div>
                <div class="col-md-7">
                  <span>
                    <span *ngIf="variantDrug.drugClass === 'Vaccine'">
                      {{ drug.glossaryVaxtype }}
                    </span>
                    <span *ngIf="variantDrug.drugClass === 'Neutralizing antibody'">
                      {{ drug.glossaryAntibodyType }}
                    </span>
                    <span
                      *ngIf="variantDrug.drugClass !== 'Neutralizing antibody' && variantDrug.drugClass !== 'Vaccine'"
                    >
                      <span>na</span>
                    </span>
                  </span>
                </div>
                <div class="col-md-5 font-weight-bold">Mechanism of Action</div>
                <div class="col-md-7">
                  <span>{{ drug.glossaryMoa }}</span>
                </div>
              </div>
              <h3 class="sub-title">Development Status</h3>
              <div class="row mb-4">
                <div class="col-md-5 font-weight-bold">Developer</div>
                <div class="col-md-7">
                  <span>{{ variantDrug.drugCompany }}</span>
                </div>
                <div class="col-md-5 font-weight-bold">US (FDA) Approval Status</div>
                <div class="col-md-7">
                  <span>{{ drug.glossaryFdastatus }}</span>
                  <div class="dots" [ngSwitch]="drug.glossaryFdastatus">
                    <span *ngSwitchCase="'FDA approved'">
                      <span class="dot dot-green"></span>
                      <span class="dot dot-green"></span>
                      <span class="dot dot-green"></span>
                    </span>

                    <span *ngSwitchCase="'EUA approved'">
                      <span class="dot dot-light-green"></span>
                      <span class="dot dot-light-green"></span>
                      <span class="dot dot-light-green"></span>
                    </span>

                    <span *ngSwitchCase="'FDA revoked'">
                      <span class="dot dot-brown"></span>
                      <span class="dot dot-brown"></span>
                      <span class="dot dot-brown"></span>
                    </span>

                    <span *ngSwitchCase="'EUA revoked'">
                      <span class="dot dot-pink"></span>
                      <span class="dot dot-pink"></span>
                      <span class="dot dot-pink"></span>
                    </span>

                    <span *ngSwitchCase="'Investigational'">
                      <span class="dot dot-dusty-green"></span>
                      <span class="dot dot-dusty-green"></span>
                      <span class="dot dot-grey"></span>
                    </span>

                    <span *ngSwitchCase="'Research reagent'">
                      <span class="dot dot-blue"></span>
                      <span class="dot dot-grey"></span>
                      <span class="dot dot-grey"></span>
                    </span>

                    <span *ngSwitchCase="'Discontinued'">
                      <span class="dot dot-black"></span>
                      <span class="dot dot-grey"></span>
                      <span class="dot dot-grey"></span>
                    </span>
                  </div>
                </div>
                <div class="col-md-5 font-weight-bold">US Highest Phase Reached</div>
                <div class="col-md-7">
                  <span>{{ drug.glossaryStatus }}</span>
                </div>
                <div class="col-md-5 font-weight-bold">Approved Outside of US?</div>
                <div class="col-md-7">
                  <span>
                    {{ drug.glossaryOusapproval }}
                  </span>
                </div>
              </div>
              <h3 class="sub-title">Identifiers</h3>
              <div class="row mb-4">
                <div class="col-md-3 font-weight-bold">Alias</div>
                <div class="col-md-9">
                  <span *ngIf="variantDrug.alias1">{{ variantDrug.alias1 }}</span>
                  <span *ngIf="variantDrug.alias2">, {{ variantDrug.alias2 }}</span>
                  <span *ngIf="variantDrug.alias3">, {{ variantDrug.alias3 }}</span>
                  <span *ngIf="variantDrug.alias4">, {{ variantDrug.alias4 }}</span>
                  <span *ngIf="variantDrug.alias5">, {{ variantDrug.alias5 }}</span>
                </div>
                <div class="col-md-3 font-weight-bold">SMILES</div>
                <div class="col-md-9">{{ drug.glossarySmiles }}</div>
                <div class="col-md-3 font-weight-bold">CAS Number</div>
                <div class="col-md-9">{{ variantDrug.cas }}</div>
                <div class="col-md-3 font-weight-bold">UNII</div>
                <div class="col-md-9">{{ variantDrug.unii }}</div>
              </div>
              <h3 class="sub-title">Reference</h3>
              <div *ngFor="let refLink of $any(refrenceLinks)">
                <a href="{{ refLink }}">{{ refLink }}</a>
              </div>
            </div>
            <span #hBooster>
              <div *ngIf="numberOfHDatapoint > 0" class="bg-white p-3 mt-3">
                <div class="row">
                  <div class="col-6">
                    <h3 class="sub-title">Heterologous Booster Data</h3>
                  </div>
                  <div class="col-6 text-right">
                    <h4>
                      <span class="pink-bold-text">{{ numberOfHDatapoint }}</span>
                      {{ numberOfHDatapoint > 1 ? 'data points' : 'data point' }}
                    </h4>
                  </div>
                </div>
                <p class="glossary-summary">
                  Curated therapeutic activity data showing the effectiveness of
                  <span class="font-weight-bold">{{ variantDrug.drugName }}</span> when used as a heterologous booster,
                  relative to homologous booster control data.
                </p>
                <app-dataviewer
                  *ngIf="numberOfHDatapoint > 0"
                  [dataset]="snapshotDataHBooster"
                  legendtext="* Heterologous booster activity relative to matched homologous comparator. Data shown as average of relative activity, followed by range of data."
                ></app-dataviewer>
                <p>
                  View detailed activity table at
                  <a [routerLink]="['/variant/heterologous-booster']"> Heterologous Booster Database</a> page.
                </p>
              </div>
            </span>
            <span #mBooster>
              <div *ngIf="numberOfMDatapoint > 0" class="bg-white p-3 mt-3">
                <div class="row">
                  <div class="col-6">
                    <h3 class="sub-title">Multivalent Booster Data</h3>
                  </div>
                  <div class="col-6 text-right">
                    <h4>
                      <span class="pink-bold-text">{{ numberOfMDatapoint }}</span>
                      {{ numberOfMDatapoint > 1 ? 'data points' : 'data point' }}
                    </h4>
                  </div>
                </div>
                <p class="glossary-summary">
                  Curated therapeutic activity data showing the effectiveness of the multivalent vaccine booster
                  <span class="font-weight-bold">{{ variantDrug.drugName }}</span
                  >, relative to monovalent booster control data.
                </p>
                <app-dataviewer
                  *ngIf="numberOfMDatapoint > 0"
                  [dataset]="snapshotDataMBooster"
                  legendtext="* Multivalent vaccine booster activity relative to matched monovalent booster comparator. Data shown as average of relative activity, followed by range of data."
                ></app-dataviewer>
                <p>
                  View detailed activity table at COVID-19
                  <a [routerLink]="['/variant/multivalent-booster']">Multivalent Booster Database</a> page.
                </p>
              </div>
            </span>

            <!-- <div id="#vitro" class="bg-white p-3 mt-3">
              <h3 class="sub-title">In vitro Variant Data</h3>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-view [noMargin]="true" [showCC]="true" *ngIf="!dataLoading"></app-footer-view>
</div>
<ng-template #loader>
  <div class="main-content col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
  <app-footer-view [showCC]="true"></app-footer-view>
</ng-template>
