<div class="container-fluid">
  <div class="container pt-4">
    <div class="row px-3 pb-3">
      <div class="col-9 p-0">
        <p class="mb-0"><a [routerLink]="['/']">Home </a> > SARS-CoV-2 Screening Data > SARS-CoV-2 Assays</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 page-title">
        <h1>SARS-CoV-2 Assays</h1>
        <p>
          The assays below have been developed to cover a wide spectrum of the SARS-CoV-2 life cycle, including both
          viral and human (host) targets. Detailed assay information, high-throughput screening protocols and individual
          screen datasets are accessible from the table below. A compilation of all NCATS SARS-CoV-2 screening data can
          be downloaded using the following link.
          <a href="https://opendata.ncats.nih.gov/public/odp/assay/All_SARS_CoV2_Datasets.csv" download>
            <button class="btn btn-secondary">Download all SARS-CoV-2 Datasets</button>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
