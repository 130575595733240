<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="main pb-3">
  <div class="container-fluid hero">
    <div class="container py-4">
      <div class="row px-3 pb-3">
        <div class="col-9 p-0">
          <p class="mb-0"><a [routerLink]="['/covid19']">Home </a> > Therapeutic Glossary</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 page-title">
          <h2>OpenData Portal COVID-19 Therapeutic Glossary</h2>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!dataLoading; else loader">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>At a Glance</h2>
          <div class="row">
            <div *ngIf="pieData" class="col-md-4 p-3">
              <div class="glance-continers p-2 h-100">
                <h4><span class="glance-title">Therapeutics</span> | Class</h4>
                <div class="m-0 p-0">
                  <app-d3-pie [pieData]="pieData"></app-d3-pie>
                </div>
              </div>
            </div>
            <div *ngIf="approvalStatus" class="col-md-4 p-3">
              <div class="glance-continers p-2 h-100">
                <h4><span class="glance-title">Therapeutics</span> | Number by Approval Status</h4>
                <div class="m-0 p-0">
                  <app-d3-horizontal-chart [horizontalChartData]="approvalStatus"></app-d3-horizontal-chart>
                </div>
              </div>
            </div>
            <div *ngIf="topTen" class="col-md-4 p-3">
              <div class="glance-continers p-2 h-100">
                <h4><span class="glance-title">Therapeutics</span> | Number of Data Points (Top Ten Shown)</h4>
                <div class="m-0 p-0">
                  <app-d3-horizontal-chart-topten [horizontalChartData]="topTen"></app-d3-horizontal-chart-topten>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container container-fluid py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <div id="filter" class="bg-white p-3 px-1">
              <div class="d-block">
                <h5>FILTER BY</h5>
              </div>

              <div *ngFor="let filterEntry of $any(filterValues) | keyvalue : originalOrder">
                <div class="border-top py-3">
                  <div
                    class="column-name"
                    (click)="filterExpanded.set($any(filterEntry.key), !filterExpanded.get($any(filterEntry.key)))"
                  >
                    <span>{{ filterEntry.key }}:</span>
                    <div>
                      <span *ngIf="!filterExpanded.get($any(filterEntry.key))" class="ml-3 expand-arrow arrow-right"
                        ><i class="fas fa-angle-down"></i
                      ></span>
                      <span *ngIf="filterExpanded.get($any(filterEntry.key))" class="ml-3 expand-arrow arrow-right"
                        ><i class="fas fa-angle-up"></i
                      ></span>
                    </div>
                  </div>
                  <div *ngIf="filterExpanded.get($any(filterEntry.key))" class="filter-values py-2 px-1">
                    <div *ngFor="let filterId of filterEntry.value">
                      <div>
                        <label class="check-label">
                          <input
                            type="checkbox"
                            class="checkmark"
                            (change)="toggleFilter(filterId, $any(filterEntry.key))"
                          />
                          <div class="filter-window" [title]="filterId">
                            <div>{{ filterId }}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9 co-sm-12">
            <div id="input-wrapper" class="mb-2 mt-0">
              <i class="fa fa-search" id="search-icon"></i>
              <input #searchInput placeholder="Search by Keyword" id="input-box" class="my-2" [formControl]="control" />
              <i class="fas fa-times ml-3" id="clear-search" (click)="clearSearchKeyWords()"></i>
            </div>
            <div class="jump-to">
              <span>Browse:</span>
              <a href="javascript:void(0)" (click)="scrollIntoView('a-start')">A</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('b-start')">B</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('c-start')">C</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('d-start')">D</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('e-start')">E</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('f-start')">F</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('g-start')">G</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('h-start')">H</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('i-start')">I</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('j-start')">J</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('k-start')">K</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('l-start')">L</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('m-start')">M</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('n-start')">N</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('o-start')">O</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('p-start')">P</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('q-start')">Q</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('r-start')">R</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('s-start')">S</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('t-start')">T</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('u-start')">U</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('v-start')">V</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('w-start')">W</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('x-start')">X</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('y-start')">Y</a>
              <a href="javascript:void(0)" (click)="scrollIntoView('z-start')">Z</a>
            </div>
            <span>
              <div id="ds-number">{{ count }} therapeutics found</div></span
            >
            <!-- Desktop View-->
            <table class="w-100">
              <tr>
                <div class="w-100" id="no-result" *ngIf="count === 0">Your search did not match any datasets.</div>
              </tr>
            </table>

            <ng-container *ngFor="let item of groups | keyvalue">
              <div class="w-100 p-3 mb-3 bg-white" *ngFor="let drug of item.value; first as isFirst">
                <div class="info" [attr.id]="isFirst ? item.key.toLowerCase() + '-start' : ''">
                  <div class="about">
                    <div class="title">
                      <a class="drug-name" routerLink="{{ drug.id }}">
                        {{ drug.drugName }}
                      </a>
                      <div [ngSwitch]="drug.glossaryFdastatus" class="fda-status">
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #74a722"
                          *ngSwitchCase="'FDA approved'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #c9e0a5; color: black"
                          *ngSwitchCase="'EUA approved'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #a75522"
                          *ngSwitchCase="'FDA revoked'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #e0aca5; color: black"
                          *ngSwitchCase="'EUA revoked'"
                          >{{ drug.glossaryFdastatus }}</span
                        >

                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #bfbf15"
                          *ngSwitchCase="'Investigational'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #226fa7"
                          *ngSwitchCase="'Research reagent'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                        <span
                          class="fda-status badge badge-secondary rounded-0 px-3"
                          style="background-color: #3c3c3c"
                          *ngSwitchCase="'Discontinued'"
                          >{{ drug.glossaryFdastatus }}</span
                        >
                      </div>
                      <!-- <div
                        *ngIf="drug.glossaryFdastatus"
                        class="study-type p-1"
                        [ngClass]="{approved: drug.glossaryFdastatus.indexOf('Approved') > -1}"
                      >
                        {{ drug.glossaryFdastatus }} -->
                      <!-- </div> -->
                    </div>
                    <div class="drug-info py-1">
                      <div class="class">{{ drug.drugClass }}</div>
                      <div *ngIf="drug.glossaryMoa" class="moa">| {{ drug.glossaryMoa }}</div>
                    </div>
                  </div>
                  <div class="points">
                    <div class="circle-container">
                      <div class="circles">
                        <div class="yellow-c"></div>
                        <div class="red-c"></div>
                        <div class="blue-c"></div>
                      </div>
                      <div></div>
                    </div>

                    <div>
                      <div>
                        <span class="count">
                          {{ drug.activityPointCount }}
                        </span>
                        <span class="desc"> data points </span>
                      </div>
                      <div class="pl-4 d-variants">OpenData Portal Variants</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-view [noMargin]="true" [showCC]="true" *ngIf="!dataLoading"></app-footer-view>
</div>
<ng-template #loader>
  <div class="main-content col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
  <app-footer-view [showCC]="true"></app-footer-view>
</ng-template>
