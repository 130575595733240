import {Component, OnInit} from '@angular/core';
import * as d3 from 'd3';
import {VariantsMutations} from '../../../models/dtos/trace';
import {TraceAssayApiService} from '../../../services/api/trace-api/trace-api-new.service';
@Component({
  selector: 'app-trace-mutation',
  templateUrl: './trace-mutation.component.html',
  styleUrls: ['./trace-mutation.component.scss']
})
export class TraceMutationComponent implements OnInit {
  public mutations: Map<string, VariantsMutations[]> = new Map<string, VariantsMutations[]>();
  constructor(private traceApi: TraceAssayApiService) {}

  ngOnInit(): void {
    this.traceApi.getVariantsMutations().subscribe((mutations: VariantsMutations[]) => {
      this.mutations = d3.group(mutations, (d) => d.variantLineage);
    });
  }
}
