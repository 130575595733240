<table class="mb-3 w-100">
  <thead>
    <tr>
      <th *ngFor="let header of headers" class="py-1">
        {{ header }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entry of dataEntries">
      <td *ngFor="let item of entry" class="py-1">
        <span *ngIf="item.name === 'Booster'" class="font-weight-bold">{{ item.value }}</span>
        <span *ngIf="item.name !== 'Booster'">{{ item.value }}</span>
      </td>
    </tr>
  </tbody>
</table>
<p class="legend-text">{{ legendtext }}</p>
