<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container">
  <div class="row py-3 dataset my-5 px-3">
    <div class="d-block">
      <h3>Glossary | Variant Therapeutic Data on OpenData Portal</h3>
      <ul class="ml-3">
        <li class="my-3">
          <b>Full Variant:</b> A viral variant that contains the full set of lineage-defining mutations for that
          variant. It is important to note that not all research laboratories share the same definition of
          lineage-defining mutations. Examples of variant definition includes those provided by the
          <a
            target="_blank"
            href="https://www.cdc.gov/coronavirus/2019-ncov/cases-updates/variant-surveillance/variant-info.html"
            >CDC</a
          >
          and <a target="_blank" href="https://outbreak.info/situation-reports">outbreak.info</a>. These variants can
          either be field-isolated, or engineered in the laboratory from a wild-type reference strain.
        </li>
        <li class="my-3">
          <b>Partial Variant:</b> A viral variant that contains two or more lineage-defining mutations for that variant,
          but not the full-set of mutations. These variants are engineered in the laboratory from a wild-type reference
          strain.
        </li>
        <li class="my-3">
          <b>Single Mutation Variant:</b> A viral variant that contains only a single amino acid mutation from a
          wild-type strain. These variants are engineered in the laboratory from a wild-type reference strain.
        </li>
        <li class="my-3">
          <b>Pseudovirus:</b> The data was generated from a
          <a [routerLink]="['/covid19/variant/assays/7/Pseudotyped virus assay']" target="_blank"
            >Pseudotyped virus assay</a
          >.
        </li>
        <li class="my-3">
          <b>Live Virus:</b> The data was generated from a
          <a [routerLink]="['/covid19/variant/assays/5/Live virus replication assay']" target="_blank"
            >Live virus replication assay</a
          >.
        </li>
        <li class="my-3"><b>Mutations:</b> On the OpenData portal, variant mutations are reported as:</li>
        <ul class="ml-5">
          <li>
            amino acid residue in wild-type | amino acid position | mutated amino acid residue <br />
            <i
              >e.g. N501Y: the amino acid asparagine (N) at the 501 position in the spike protein has been replaced by a
              tyrosine (Y)</i
            >
          </li>
          <li>
            or in the cases with mutations containing amino acid deletions:<br />
            <i>amino acid residue in wild-type | amino acid position | ‘del’</i> <br />e.g. H69del, V70del, etc
          </li>
          <li>or it is noted as ‘Not Reported’ if no information for the mutations used has been provided</li>
        </ul>
        <li class="my-3">
          <b>Lineages:</b> There are many ways to define and name variants (e.g. Pangolin, Nextstrain). The OpenData
          portal adopts the Pango alpha/numeric nomenclature system (1,2) in our variant naming. Despite their common
          use in media and other reports, we are purposely avoiding using geographic locales when referencing variants
          because such usage can lead to stigmatization, inaccurate perceptions, and reporting delays. It is well
          documented that communities can suffer economically and socially when linked to a viral disease or a viral
          variant. Therefore, we, along with scientific and political leaders around the world, are urging that variants
          not be referred to based on their perceived geographic origin. For those unfamiliar with the Pango naming
          system, please see
          <a href="https://en.wikipedia.org/wiki/Variants_of_SARS-CoV-2" target="_blank"
            >this variant table on Wikipedia for translation.</a
          >
        </li>
      </ul>
    </div>
    <div class="d-block text-right text-secondary w-100">
      <small> Last updated April 26th, 2021 </small>
    </div>
  </div>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
