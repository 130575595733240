import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DrugResult} from '../../../models/view-models/screen-data-models';
import {ScreeningDataApiService} from '../../../services/api/data-browser/screening-data-api.service';

@Component({
  selector: 'app-data-browser-summary',
  templateUrl: './data-browser-summary.component.html',
  styleUrls: ['./data-browser-summary.component.scss']
})
export class DataBrowserSummaryComponent implements OnInit {
  public drugId!: any;
  public drug: DrugResult | null = null;
  public error = false;
  public dataLoading = false;
  constructor(private route: ActivatedRoute, private drugNameApi: ScreeningDataApiService) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.paramMap.get('id');
    this.error = false;
    this.dataLoading = true;
    this.drugNameApi.getDrugNameData(this.drugId).subscribe(
      (data) => {
        this.dataLoading = false;
        this.drug = data;
      },
      (error) => (this.error = true)
    );
  }
}
