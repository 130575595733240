import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {ActivityCirculatingVarQuery} from '../models/dtos/cirulating-variants/activity-circulating-point';

@Injectable({
  providedIn: 'root'
})
export class ActivityCirculatingVaraintsQL extends Query<ActivityCirculatingVarQuery, {}> {
  override document = gql`
    query therapeuticActivity(
      $cdcVariantFilter: [String!]
      $subLineageFilter: [String!]
      $lineageFilter: [String!]
      $dateFilter: String
    ) {
      variant3Sublineage(
        cdcVariants: $cdcVariantFilter
        viralSublineage: $subLineageFilter
        viralLineage: $lineageFilter
        dataUpdatedDate: $dateFilter
      ) {
        viralLineage
        viralSublineage
        viralName
        viralType
        viralProteinFullPartial
        drugActivity1NumericFold
        drugName
        drugClass
        drugRef
        dataTitle
        dataSourceType
        dataUpdatedDate
        assayType
        viralAaMutation
        reportNumber
        dataDate
        viralLineageFullName
        dataSourceUrls
      }
    }
  `;
}
