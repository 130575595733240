<div class="mt-3">
  <div class="mb-5" *ngFor="let assay of assayTrace | keyvalue">
    <h4 class="mb-4 font-weight-bold">{{ assay.key }}</h4>
    <div class="mb-5 pl-5" *ngFor="let assayItem of assay.value">
      <h5 class="font-weight-bold">{{ assayItem.assayType }}</h5>
      <div class="row mb-4" *ngIf="assayItem.assayOverview">
        <div class="col-2">Overview:</div>
        <div class="col-9">{{ assayItem.assayOverview }}</div>
      </div>
      <div class="row" *ngIf="assayItem.assayTarget">
        <div class="col-2">Target:</div>
        <div class="col-9">{{ assayItem.assayTarget }}</div>
      </div>
      <div class="row" *ngIf="assayItem.detectionType">
        <div class="col-2">Detection Type:</div>
        <div class="col-9">{{ assayItem.detectionType }}</div>
      </div>
      <div class="row" *ngIf="assayItem.cellLine1 || assayItem.cellLine2 || assayItem.cellLine3">
        <div class="col-2">Cell Line:</div>
        <div class="col-9">{{ assayItem.cellLine1 }} {{ assayItem.cellLine2 }} {{ assayItem.cellLine3 }}</div>
      </div>
      <div class="row" *ngIf="assayItem.interpretationNotesLink">
        <div class="col-2">Interpretation Notes:</div>
        <div class="col-9">{{ assayItem.interpretationNotesLink }}</div>
      </div>
      <div class="row" *ngIf="assayItem.protocolLink">
        <div class="col-2">Protocol:</div>
        <div class="col-9">
          <a href="{{ assayItem.protocolLink }}">{{ assayItem.protocolLink }}</a>
        </div>
      </div>
    </div>
    <hr />
  </div>
</div>
