// @ts-nocheck
import {Component, OnInit, Input} from '@angular/core';
import {DatasetsApiService} from '../../../services/api/datasets-api-graphql/datasets-api.service';

@Component({
  selector: 'app-dataviewer',
  templateUrl: './dataviewer.component.html',
  styleUrls: ['./dataviewer.component.scss']
})
export class DataviewerComponent implements OnInit {
  @Input() dataset: any[];
  @Input() legendtext: string;

  headers: string[] = [];
  dataEntries: any[][] = [];

  ngOnInit(): void {
    if (this.dataset && this.dataset.length > 0) {
      this.headers = Object.keys(this.dataset[0]).map((key) => this.dataset[0][key].name);
      this.dataEntries = this.dataset.map((item) => {
        return Object.keys(item).map((key) => ({
          name: item[key].name,
          value: item[key].value
        }));
      });
    }
  }
}
