<div class="w100 bg-white p-3 mt-2 mb-4">
  <div class="mb-2">
    <div class="mt-2 pl-2">
      <div class="p pr-4 pb-1">
        <span class="font-weight-bold">FILTERS</span>
      </div>
      <div class="pb-2">
        <button class="btn btn-outline-secondary reset-btn" (click)="resetClicked()">RESET FILTERS</button>
      </div>
      <div class="pb-2">
        <div class="font-weight-bold filter-header">THERAPEUTICS SHOWN</div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            checked
            name="show-drugs"
            id="show-default"
            value="default"
            [(ngModel)]="drugGroup"
            (click)="showDrugs($event.target)"
            [disabled]="disableRecentDataChecbox"
          />
          <label class="form-check-label" for="show-default">Featured Set</label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="show-drugs"
            id="hide-default"
            value="all"
            [(ngModel)]="drugGroup"
            (click)="showDrugs($event.target)"
            [disabled]="disableRecentDataChecbox"
          />
          <label class="form-check-label" for="hide-default">All</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            checked
            name="show-drugs"
            id="show-data"
            value="show-data"
            [(ngModel)]="drugGroup"
            (click)="showDrugs($event.target)"
            [disabled]="disableRecentDataChecbox"
          />
          <label class="form-check-label" for="show-data">Show only therapeutics with data</label>
        </div>
      </div>
      <div class="pb-2">
        <div class="font-weight-bold filter-header">REFERENCE STRAIN</div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="reference-variant"
            id="any-strain"
            [(ngModel)]="referenceMode"
            value="any"
            (click)="setReferenceMode($event.target)"
          />
          <label class="form-check-label" for="hide-filtered">Any</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="reference-variant"
            id="ancestral-strain"
            [(ngModel)]="referenceMode"
            value="ancestral"
            (click)="setReferenceMode($event.target)"
          />
          <label class="form-check-label" for="hide-filtered">Ancestral</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            checked
            name="reference-variant"
            id="other-strain"
            [(ngModel)]="referenceMode"
            value="other"
            (click)="setReferenceMode($event.target)"
          />
          <label class="form-check-label" for="gray-circle">Other</label>
        </div>
      </div>
      <div class="font-weight-bold pt-2 filter-header">NEWLY ADDED</div>
      <div class="pb-2">
        <div>Within the last number of days</div>
        <select #selectChannel="ngModel" [(ngModel)]="newlyAdded" (ngModelChange)="setNewlyAddedDays($event)">
          <option [ngValue]="null">Show All</option>
          <option [ngValue]="7">7</option>
          <option [ngValue]="14">14</option>
          <option [ngValue]="28">28</option>
          <option [ngValue]="56">56</option>
        </select>
      </div>
      <div class="mb-3">
        <div class="font-weight-bold filter-header">SELECTED SPIKE MUTATION</div>
        <input
          class="p-1"
          type="text"
          placeholder="Search for a mutation"
          [formControl]="control"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setMutation($event.option.value)">
          <mat-option *ngFor="let mutation of filteredMutations | async" [value]="mutation">
            {{ mutation }}
          </mat-option>
        </mat-autocomplete>

        <div class="mt-1 d-flex flex-wrap align-items-baseline">
          <button class="mr-2 btn btn-outline-secondary reset-btn" (click)="setMutation(null)">RESET</button>
          <div class="ml-1 mt-2 mr-2 mb-2" *ngFor="let mutation of activeMutations">
            {{ mutation }} <a href="javascript:void(0)" (click)="removeMutation(mutation)">x</a>
          </div>
        </div>
        <div class="sets overflow-auto">
          <div class="pt-1">
            <a href="javascript:void(0)" (click)="showMutations = !showMutations">{{
              showMutations ? 'Hide All mutations -' : 'Show All mutations +'
            }}</a>
          </div>
          <div [hidden]="!showMutations">
            <button
              [ngClass]="{active: activeMutations.indexOf(s) > -1}"
              *ngFor="let s of mutations"
              (click)="setMutation(s)"
            >
              {{ s }}
            </button>
          </div>
        </div>
      </div>

      <div class="pb-4" *ngFor="let group of filterGroups; index as i">
        <div class="font-weight-bold text-uppercase filter-header">{{ group.title }}</div>
        <div class="form-check" *ngFor="let value of group.values; index as j">
          <input
            class="form-check-input"
            type="checkbox"
            name="inactive-circles"
            id="group-{{ i }}-{{ j }}"
            [(ngModel)]="value.selected"
            (change)="selectFilter(group.id, value)"
          />
          <label class="form-check-label" for="group-{{ i }}-{{ j }}">{{ value.display }}</label> &nbsp;<span
            >[{{ value.count }}]</span
          >
        </div>
      </div>
      <div class="pb-4">
        <div>
          <div class="font-weight-bold filter-header">FILTER BY REPORTED DATE</div>
          <input
            id="reported-date"
            type="text"
            class="p-1 pl-2 mb-2"
            ngxDaterangepickerMd
            [(ngModel)]="dateRange"
            placeholder="Choose date range"
            (datesUpdated)="changeDate($event)"
            #pickerRange
          />
        </div>
        <button class="btn btn-outline-secondary reset-btn" (click)="clearDateRange()">RESET</button>
      </div>
      <div class="pb-4">
        <div class="font-weight-bold filter-header">FILTER BEHAVIOR</div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            checked
            name="inactive-circles"
            id="gray-circle"
            [(ngModel)]="inactiveMode"
            value="gray"
            (click)="setInactivePointVisibility($event.target)"
          />
          <label class="form-check-label" for="gray-circle">Highlight results</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="inactive-circles"
            id="hide-filtered"
            [(ngModel)]="inactiveMode"
            value="remove"
            (click)="setInactivePointVisibility($event.target)"
          />
          <label class="form-check-label" for="hide-filtered">Show results only</label>
        </div>
      </div>
    </div>
  </div>
</div>
