import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {SmallAnimalModelDetails} from '../../../models/dtos/animal/small-animal';
import {AnimalModelServiceNew} from '../../../services/api/animal-api/animal-model-new.service';
// import {AnimalModelService} from '../../../services/api/animal-api/animal-model.service';
// import { SmallAnimalModelDetails } from '../../../../lib/models';
// import { AnimalModelService } from '../../../../lib/api/animal-api/animal-model.service';

@Component({
  selector: 'app-small-animal-details',
  templateUrl: './small-animal-details.component.html',
  styleUrls: ['./small-animal-details.component.scss']
})
export class SmallAnimalDetailsComponent implements OnInit {
  details: SmallAnimalModelDetails = {} as SmallAnimalModelDetails;
  modelId: string;
  studySummaryOpenStates: {[key: string]: boolean};

  constructor(
    private route: ActivatedRoute,
    private animalModelService: AnimalModelServiceNew,
    private titleService: Title
  ) {
    this.modelId = this.route.snapshot.params['id'];
    this.studySummaryOpenStates = {};
  }

  ngOnInit(): void {
    this.animalModelService.getSmallAnimaModelDetails(parseInt(this.modelId)).subscribe((data) => {
      this.details = data;
      this.titleService.setTitle(this.details.background?.modelName);
    });
  }
}
