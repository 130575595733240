import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'SafeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized?: DomSanitizer) {}
  transform(value: string) {
    if (!value) {
      return value;
    }

    return this.sanitized ? this.sanitized.bypassSecurityTrustHtml(value) : '';
  }
}
