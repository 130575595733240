import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Assay} from '../../../models/dtos/assay/assay';
import {ScreeningDataApiService} from '../../../services/api/data-browser/screening-data-api.service';
var toTitleCase = require('to-title-case');

@Component({
  selector: 'app-data-browser',
  templateUrl: './data-browser.component.html',
  styleUrls: ['./data-browser.component.scss']
})
export class DataBrowserComponent implements OnInit {
  constructor(private api: ScreeningDataApiService, private titleService: Title) {
    titleService.setTitle('Data Browser');
  }
  public sampleData: any[] = [];
  public currentPage!: number;
  public currentLibrary!: number;
  public pageSize!: number;
  public loading = true;
  public keyword = 'value';
  public searchData!: any[];
  public drugDirAsc!: boolean;
  public moaDirAsc!: boolean;
  public totalItems!: number;
  public currSortAssay!: Assay | null;
  public error = false;

  public sortDirections = {};

  public visibleProps = [];
  public assays: Assay[] = [
    {id: '14', name: 'SARS-CoV-2 cytopathic effect (CPE)', type: 'Cell viability', category: 'Live virus infectivity'},
    {
      id: '15',
      name: 'SARS-CoV-2 cytopathic effect (host tox Counter)',
      type: 'Cell viability',
      category: 'Live virus infectivity'
    },
    {id: '12', name: 'SARS-CoV-2 Pseudotyped Particle Entry', type: 'Cell-based', category: 'In vitro infectivity'},
    {
      id: '26',
      name: 'SARS-CoV-2 Pseudotyped particle entry (tox counterscreen)',
      type: 'Cell-based',
      category: 'In vitro infectivity'
    },
    {id: '22', name: 'SARS-CoV Pseudotyped particle entry', type: 'Cell-based', category: 'In vitro infectivity'},
    {
      id: '23',
      name: 'SARS-CoV Pseudotyped particle entry (VeroE6 tox Counter)',
      type: 'Cell-based',
      category: 'In vitro infectivity'
    },
    {id: '24', name: 'MERS Pseudotyped particle entry', type: 'Cell-based', category: 'In vitro infectivity'},
    {
      id: '25',
      name: 'MERS Pseudotyped particle entry (Huh7 tox Counter)',
      type: 'Cell-based',
      category: 'In vitro infectivity'
    },

    {id: '1', name: 'Spike-ACE2 protein-protein interaction (AlphaLISA)', type: 'Proximity', category: 'Viral Entry'},
    {
      id: '2',
      name: 'Spike-ACE2 protein-protein interaction (TruHit Counter)',
      type: 'Proximity',
      category: 'Viral Entry'
    },
    {id: '3', name: 'Spike-ACE2 protein-protein interaction (QD)', type: 'Proximity', category: 'Viral Entry'},
    {id: '6', name: 'ACE2 enzymatic activity', type: 'Biophysical', category: 'Viral Entry'},
    {id: '8', name: 'TMPRSS2 enzymatic activity', type: 'Biochemical', category: 'Viral Entry'},
    {id: '9', name: '3CL enzymatic activity', type: 'Biochemical', category: 'Viral replication'},
    {id: '10', name: 'RdRp enzymatic activity', type: 'Biochemical', category: 'Viral replication'},

    {id: '20', name: 'HEK293 cell line toxicity', type: 'Proximity', category: 'Human cell toxicity'},
    {id: '21', name: 'Human fibroblast toxicity', type: 'Proximity', category: 'Human cell toxicity'}
  ];

  public libraries = [
    {id: 1, name: 'Approved Drugs Collection (NPC)'},
    {id: 2, name: 'Anti-infectives'},
    {id: 3, name: 'Annotated/Bioactive Collection'},
    {id: 0, name: 'All Collections'}
  ];

  ngOnInit(): void {
    this.pageSize = 25;
    this.currentLibrary = 1;
    this.currentPage = 0;
    this.drugDirAsc = false;
    this.moaDirAsc = false;
    this.getData(1);
  }

  public toTitleCase(text: string) {
    if (text) return toTitleCase(text);
  }

  public getData(pageNum: number) {
    const getDataObservable = !this.currSortAssay
      ? this.api.getHeatmapData(this.currentLibrary, pageNum, this.pageSize)
      : this.api.sortSelection(
          (this.sortDirections as any)[this.currSortAssay.id],
          this.currSortAssay.id,
          this.currentLibrary,
          pageNum,
          this.pageSize
        );
    getDataObservable.subscribe({
      next: (data: any) => {
        this.sampleData = data;
        this.totalItems = data[0].count;
        this.loading = false;
      },
      error: () => (this.error = true)
    });
  }

  public changePageSize(target: any) {
    const pageSize = target.value;
    this.pageSize = parseInt(pageSize, 0);
    this.getData(1);
  }

  public changeLibrary(target: any) {
    const libraryId = target.value;
    this.currentLibrary = libraryId;
    this.currSortAssay = null;
    this.getData(1);
  }

  public selectEvent(item: any) {
    this.api.search(item.data).subscribe((val: any[]) => {
      this.sampleData = val;
    });
  }

  public onChangeSearch(val: string) {
    this.api.getSuggestions(val).subscribe((suggestions: any[]) => {
      this.searchData = suggestions;
    });
  }

  public sortAssay(assay: Assay) {
    const sortDir = (this.sortDirections as any)[assay.id];
    if (!sortDir || sortDir === 'desc') {
      (this.sortDirections as any)[assay.id] = 'asc';
    } else {
      (this.sortDirections as any)[assay.id] = 'desc';
    }
    this.api
      .sortSelection((this.sortDirections as any)[assay.id], assay.id, this.currentLibrary, 1, this.pageSize)
      .subscribe({
        next: (data: any[]) => {
          this.sampleData = data;
          this.currentPage = 1;
          this.currSortAssay = assay;
        },
        error: () => (this.error = true)
      });
  }

  public sortDrugDir() {
    const drug = {id: '9800'} as unknown as Assay;
    this.sortAssay(drug);
    this.drugDirAsc = !this.drugDirAsc;
  }

  public sortMoaDir() {
    const moa = {id: '6701'} as unknown as Assay;
    this.sortAssay(moa);
    this.moaDirAsc = !this.moaDirAsc;
  }

  public openSample(sampleId: string) {
    window.open('./covid19/databrowser/sample/' + sampleId);
  }
}
