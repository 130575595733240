<div class="data-container mt-3">
  <div class="table-header">In Vitro Therapeutic Activity Data of SARS-CoV-2 Variants</div>
  <div>
    The values below indicate the fold-reduction in activity for each tested variant, calculated as the ratio of IC50s
    (variant / wild-type reference strain). Fold-changes greater than one indicate decreased therapeutic activity
    against the variant strain (relative to wild-type), while fold-changes less than or equal to one indicate increased
    or equivalent activity against the variant strain.
  </div>
  <button class="btn btn-secondary" (click)="downloadCsv()">Download CSV File</button>
  <div class="col-">
    <ag-grid-angular
      style="width: 1550px; height: 700px"
      class="ag-theme-alpine"
      [rowData]="gridData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [headerHeight]="58"
      [tooltipShowDelay]="800"
      [components]="frameworkComponents"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
