import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AssayOverview, AssayApiService} from '@odp/covid19';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-assay',
  templateUrl: './assay.component.html',
  styleUrls: ['./assay.component.scss']
})
export class AssayComponent implements OnInit {
  constructor(private assayApi: AssayApiService, private titleService: Title) {}
  assayOverviews!: AssayOverview[];

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic Assay Overview');
    //this.getData();

    const assayPoints$ = this.assayApi.getAllAssayOverviews(); // get all

    forkJoin([assayPoints$]).subscribe(([assayDataPoints]) => {
      this.assayOverviews = assayDataPoints;
    });
  }
}
