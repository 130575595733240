import {Component, Inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import * as _ from 'lodash';
import {forkJoin} from 'rxjs';
import {KeyValue} from '@angular/common';
import {SummaryMapperService} from './summary-mapper/summary-mapper.service';
import {SummaryDto} from '../../models/view-models/summary-dto';
import * as Vals from '../../constants/ui-constants';
import {VariantSummary} from '../../models/dtos/variant/summary';
import {Lineage} from '../../models/dtos/lineage/lineage';
import * as d3 from 'd3';
import {DataSummaryApiService} from '../../services/api/data-summary-api/data-summary-api.service';
import {LineageApiService} from '../../services/api/lineage-api/lineage-api.service';
import {DatasetMetadataApiService} from '../../services/api/dataset-metadata-api/dataset-metadata-api.service';
import {TherapeuticClass} from '../../models/dtos/therapeutics/therapeutic-class';
import * as CONST from '../../constants/api-constants';
import {API_URLS, Endpoints} from '@odp/shared';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  constructor(
    @Inject(API_URLS) private configuration: Endpoints,
    private summaryApi: DataSummaryApiService,
    private titleService: Title,
    private summaryMapper: SummaryMapperService,
    private lineageApi: LineageApiService,
    private metaApi: DatasetMetadataApiService
  ) {
    this.variantBaseUrl = this.configuration.variantApiUrl;
  }
  summaryGroupedByLineage!: d3.InternMap<string, VariantSummary[]>;
  hasData: boolean = false;
  lineageMap: Map<string, VariantSummary[]> = new Map<string, VariantSummary[]>();
  lineageSummaries: LineageSummary[] = [];
  therapeuticsByLineage = new Map<string, TherapeuticClass[]>();
  lastUpdatedTxt: string | null = null;
  orderedMobileData: Map<string, Map<string, Map<string, SummaryDto[]>>> = new Map<
    string,
    Map<string, Map<string, SummaryDto[]>>
  >();
  dataLoading = false;
  maxItemToShow = 5;
  showMap: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  public recordCount: string | null = null;
  public dataSourceCount: number | null = null;
  showHeatmap = true;
  variantBaseUrl!: string;
  public showVariantMap = new Map<string, boolean>();
  public showClassMap = new Map<string, Map<string, boolean>>();
  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic in vitro Data Summary');
    this.lineageMap = new Map<string, VariantSummary[]>();
    this.getData();
  }
  getData(): void {
    this.dataLoading = true;
    const lineages$ = this.lineageApi.getLineages();
    const summaries$ = this.summaryApi.getVariantSummaries();
    const metadata$ = this.metaApi.getVariantDatasetMeta();
    const recordCount$ = this.metaApi.getRecordCount();
    forkJoin([lineages$, summaries$, metadata$, recordCount$]).subscribe(
      ([lineages, summaries, metadata, recordCount]) => {
        const classes = ['Vaccine', 'Antiviral', 'Neutralizing antibody', 'Convalescent plasma'];
        this.showMap = new Map<string, Map<string, boolean>>();
        lineages.forEach((l) => {
          const classMap = new Map<string, boolean>();
          classes.forEach((c) => {
            classMap.set(c, false);
          });
          if (l.viralLineage) {
            this.showMap.set(l.viralLineage, classMap);
          }
        });
        if (summaries) {
          let orderedLineages = lineages
            .filter((l) => {
              if (!l.viralClassification) {
                return false;
              }
              return (
                l.viralClassification.toLocaleLowerCase() === Vals.variantOfConcern ||
                l.viralClassification.toLocaleLowerCase() === Vals.variantOfInterest ||
                l.viralClassification.toLocaleLowerCase() === Vals.otherVariant ||
                l.viralClassification.toLocaleLowerCase() === Vals.variantBeingMonitored
              );
            })
            .sort((a, b) => (a.viralRank === null || b.viralRank == null ? Infinity : +a.viralRank - +b.viralRank))
            .map((l) => l.viralLineage ?? '');
          this.orderedMobileData = this.summaryMapper.mapToMobileFormat(summaries, orderedLineages);

          const showVariantMap = new Map<string, boolean>();
          const showClassMap = new Map<string, Map<string, boolean>>();
          Array.from(this.orderedMobileData.entries()).forEach(([key, value]) => {
            showVariantMap.set(key, true);
            const showClass = new Map<string, boolean>();
            showClassMap.set(key, showClass);
            Array.from(value.keys()).forEach(([key2]) => {
              showClass.set(key2, false);
            });
          });
          this.showVariantMap = showVariantMap;
          this.showClassMap = showClassMap;

          // map summaries to a filtered, sorted lineage list
          this.summaryGroupedByLineage = d3.group(summaries, (s) => s.viralLineage);
          this.hasData = summaries.length > 0;
          this.lineageMap = new Map<string, VariantSummary[]>(
            orderedLineages.map((ol) => [ol, this.summaryGroupedByLineage.get(ol) ?? []])
          );

          // Build lineage summaries for UI
          const classMap = new Map<string, Lineage>(lineages.map((l) => [l.viralLineage ?? '', l]));
          this.lineageSummaries = Array.from(this.lineageMap.entries(), ([key, value]) => {
            return {
              name: key,
              summaries: value,
              classification: classMap.get(key)?.viralClassification ?? '',
              who: classMap.get(key)?.WHOName ?? ''
            };
          });

          // Build a lineage to unique therapeutics map
          const therapeuticsByLineageAndName = new Map<string, Map<string, TherapeuticClass>>();
          for (const entry of this.summaryGroupedByLineage.entries()) {
            const variantName = entry[0];
            if (!this.therapeuticsByLineage.get(variantName)) {
              therapeuticsByLineageAndName.set(variantName, new Map<string, TherapeuticClass>());
            }
            const variantSummaries = entry[1];
            variantSummaries.forEach((s) => {
              s.theraputics.forEach((x) => {
                therapeuticsByLineageAndName.get(variantName)?.set(x.drugName, {
                  drugClass: x.drugClass,
                  drugName: x.drugName
                });
              });
            });
            this.therapeuticsByLineage = new Map<string, TherapeuticClass[]>();
            Array.from(therapeuticsByLineageAndName.entries()).forEach((e) =>
              this.therapeuticsByLineage.set(e[0], Array.from(e[1].values()))
            );
          }

          this.dataSourceCount = metadata.data.count;
          this.lastUpdatedTxt = this.getUpdateText(metadata.data.updatedDate);
          this.recordCount = recordCount.data.count;
        } else {
          this.dataLoading = false;
        }
      }
    );
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  private getUpdateText(dateStr: string) {
    const today = new Date().getTime();
    const updatedTime = new Date(dateStr).getTime();
    const days = Math.ceil((today - updatedTime) / (1000 * 60 * 60 * 24));
    if (days === 0) return 'today';
    if (days === 1) return 'yesterday';
    return days.toString() + ' days ago';
  }
}

export interface LineageSummary {
  name: string;
  summaries: VariantSummary[];
  classification: string;
  who: string;
}
