import {Inject, Injectable} from '@angular/core';
import {API_URLS, Endpoints} from '@odp/shared';
import {Apollo} from 'apollo-angular';
import {InVitroZipQL} from '../../../queries/invitro-zip-query';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {InVitroZipNode, InVitroZipQuery} from '../../../models/dtos/in-vitro/invito-zip-ql';
import {map, Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InvitroZipApiService {
  configService: any;
  constructor(
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private invitroZipQL: InVitroZipQL
  ) {
    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getInvitroZipFile(): Observable<InVitroZipNode[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<InVitroZipQuery>({query: this.invitroZipQL.document})
      .pipe(
        map((result: {data: any}) => {
          return result.data.files.edges.map((x: any) => x.node);
        })
      );
  }
}
