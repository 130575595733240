import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {HorizontalChartDataModel, HorizontalChartTopTenService} from './d3-horizontal-chart-topten.service';

@Component({
  selector: 'app-d3-horizontal-chart-topten',
  templateUrl: './d3-horizontal-chart-topten.component.html',
  styleUrls: ['./d3-horizontal-chart-topten.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3HorizontalChartToptenComponent implements OnInit {
  @Input() horizontalChartData: HorizontalChartDataModel[] = [];

  constructor(private horizontalChartTopTenService: HorizontalChartTopTenService) {}

  ngOnInit(): void {
    this.horizontalChartTopTenService.onInit(this.horizontalChartData);
  }
}
