import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DrugClassDensity} from '../../../models/dtos/variant/data-density';
import {API_URLS, Endpoints} from '@odp/shared';

@Injectable({
  providedIn: 'root'
})
export class HeatmapDensityApiService {
  private variantBaseUrl!: string;
  constructor(private httpClient: HttpClient, @Inject(API_URLS) configuration: Endpoints) {
    this.variantBaseUrl = configuration.covid19ApiUrl; //variantApiUrl;
  }

  public getDataDensities(): Observable<{data: DrugClassDensity[]}> {
    return this.httpClient.get<{data: DrugClassDensity[]}>(`${this.variantBaseUrl}/route/variant/datapoint/density`);
  }
}
