import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TraceDataset} from '../../../models/dtos/trace';
import {API_URLS, Endpoints} from '@odp/shared';
@Injectable({
  providedIn: 'root'
})
export class TraceApiService {
  private variantBaseUrl!: string;
  constructor(@Inject(API_URLS) configuration: Endpoints, private httpClient: HttpClient) {
    this.variantBaseUrl = configuration.variantApiUrl;
  }

  public getTraceDataset(pageSize: number | null = null, skip: number | null = null): Observable<{data: TraceDataset}> {
    let pagingParams = '';
    if (pageSize) pagingParams = `/${pageSize}/${skip}`;
    return this.httpClient.get<{data: TraceDataset}>(`${this.variantBaseUrl}/route/trace/variant/data${pagingParams}`);
  }

  public getTraceLatestDate(): Observable<{data: string}> {
    return this.httpClient.get<{data: string}>(`${this.variantBaseUrl}/route/trace/variant/latest/date`);
  }
}
