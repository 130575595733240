<app-fixed-header></app-fixed-header>
<app-fixed-menu></app-fixed-menu>
<div class="w100 content-area">
  <app-trace-header></app-trace-header>
  <div class="mt-4 mv-2 container trace-container nav-container">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        #rla1="routerLinkActive"
        routerLinkActive
        [active]="rla1.isActive"
        [routerLink]="['./variant-data']"
      >
        Explore TRACE Variant Data
      </a>
      <a mat-tab-link #rla2="routerLinkActive" routerLinkActive [active]="rla2.isActive" [routerLink]="['./assay']">
        Assay Overviews
      </a>
      <a mat-tab-link #rla3="routerLinkActive" routerLinkActive [active]="rla3.isActive" [routerLink]="['./reagent']">
        Reagent Details
      </a>
      <a mat-tab-link #rla4="routerLinkActive" routerLinkActive [active]="rla4.isActive" [routerLink]="['./mutation']">
        Variants & Mutations
      </a>
      <a mat-tab-link #rla5="routerLinkActive" routerLinkActive [active]="rla5.isActive" [routerLink]="['./about']">
        About this Program
      </a>
    </nav>
    <div class="trace-page">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
