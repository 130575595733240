<app-fixed-header></app-fixed-header>
<app-fixed-menu></app-fixed-menu>
<div class="w100 content-area">
  <app-rwe-header></app-rwe-header>
  <div class="container rwe-grid-container">
    <app-rwe-data></app-rwe-data>
  </div>
</div>

<app-footer-view [showCC]="true"></app-footer-view>
