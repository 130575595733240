import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {RWEQuery} from '../models/dtos/rwe/rwe-ql';

@Injectable({
  providedIn: 'root'
})
export class RWEQL extends Query<RWEQuery, {}> {
  override document = gql`
    {
      realworldevidences {
        edges {
          node {
            id
            reportNumber
            dataTitle
            dataSource
            dataDate
            treatmentsCompiledEdited
            drugClassCompiledEdited
            studyStart
            studyEnd
            drug1
            drug2
            drug3
            abbreviatedSummary
            lineage
            hospitalizationEndpoint
            mortalityEndpoint
            otherEndpoint
            hospitalizationMetricUsed
            hospitalizationEndptMetricPvalue
            mortalityMetricUsed
            mortalityEndptMetricPvalue
            otherDefined
            otherMetricUsed
            otherEndpointMetricPvalue
            cohortUniqueness
          }
        }
      }
    }
  `;
}
