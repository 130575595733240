import {Injectable} from '@angular/core';
import * as d3 from 'd3';
import * as Vals from '../../../constants/ui-constants';
import {Heatmap, HeatmapBin} from '../../../models/view-models/heatmap';
export interface HeatmapElement {
  group: string;
  variable: string;
  value: number;
}
@Injectable({
  providedIn: 'root'
})
export class HeatmapService {
  constructor() {}
  private margin = {top: 180, right: 130, bottom: 30, left: 150};
  private width = 390 - this.margin.left - this.margin.right;
  private height = 465 - this.margin.top - this.margin.bottom;
  private hightlitedRow: string | null = null;
  private hightlitedCol: string | null = null;
  private lastSvgClicked: any;
  private allHeatmaps: Heatmap[] = [];

  public drawCharts(heatmaps: Heatmap[], root: any, showColors: boolean) {
    this.hightlitedRow = null;
    this.hightlitedCol = null;
    this.allHeatmaps = heatmaps;
    this.drawAllHeatmaps(heatmaps, root, showColors);
  }

  private drawAllHeatmaps(heatmaps: Heatmap[], root: any, showColors: boolean) {
    d3.select(root).selectAll('*').remove();

    this.drawLegend(root, showColors);
    for (const heatmap of heatmaps) {
      const svg = d3.select(root).append('svg');

      this.drawHeatmap(heatmap, root, showColors, svg);
    }
  }

  public drawLegend(root: any, showColors: boolean) {
    if (showColors) {
      const heatmapGradient = d3
        .scaleThreshold<number, string, number>()
        .domain([1, 6, 11, 16, 21])
        .range(['white', '#FCC5C0', '#F768A1', '#C51B8A', '#7A0177', '#49006A']);

      const svg = d3.select(root).append('svg').attr('width', 320).attr('height', 100).classed('legend', true);
      const svgG = svg.append('g').attr('transform', (d) => `translate(15, 50)`);
      svgG
        .append('text')
        .text('# data points')
        .attr('transform', (d) => `translate(0, -20)`);

      const colors = [0, 1, 6, 11, 16, 21];
      const labels = ['0', '1-5', '6-10', '11-15', '16-20', '20+'];

      const boxG = svgG
        .selectAll('.color-scale')
        .data(colors)
        .enter()
        .append('g')
        .classed('tuple', true)
        .attr('transform', (d) => `translate(${colors.indexOf(d) * 50}, 0)`);
      boxG
        .append('rect')
        .classed('color-scale', true)
        .classed('no-data', (d) => d === 0)
        .attr('width', 24)
        .attr('height', 24)
        .attr('fill', (d) => heatmapGradient(d))

        .attr('x', 0)
        .attr('y', 10);

      const _this = this;
      boxG.selectAll('.no-data').each(function (d) {
        _this.drawScaleX(d3.select(this), d);
      });

      boxG
        .append('text')
        .text((d) => labels[colors.indexOf(d)])
        .style('font-size', '.9rem')
        .attr('transform', (d) => `translate(${d === 0 ? 7 : labels[colors.indexOf(d)].length * -1}, 0)`);
    }
  }

  private drawScaleX(gEl: any, d: any) {
    if (!d.density) {
      const parent = gEl.node().parentNode;
      gEl.style('fill', 'white').style('stroke', '#ddd').style('stroke-width', 2);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 6)
        .style('stroke', 'white')
        .attr('stroke', 'white')
        .attr('x1', 3)
        .attr('x2', 24 - 3)
        .attr('y1', 13)
        .attr('y2', 24 - 3);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 1)
        .style('stroke', '#bbb')
        .attr('x1', 3)
        .attr('x2', 24 - 3)
        .attr('y1', 13)
        .attr('y2', 34 - 3);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 6)
        .style('stroke', 'white')
        .attr('x1', 3)
        .attr('x2', 24 - 3)
        .attr('y1', 34 - 3)
        .attr('y2', 13);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 1)
        .style('stroke', '#bbb')
        .attr('x1', 3)
        .attr('x2', 24 - 3)
        .attr('y1', 34 - 5)
        .attr('y2', 13);
    }
  }
  public combineAncestral(data: Heatmap): Heatmap {
    const mainAncestral = data.lineages.find((x) => x.viralLineage === Vals.summaryAncestralName);
    const nonAncestral = data.lineages.filter((x) => x.viralClassification !== Vals.ancestralClassification);
    const lineages = !!mainAncestral ? [...nonAncestral, mainAncestral] : [...nonAncestral];
    const ancestralEntries = data.entries.filter((x) => x.viralClassification === Vals.ancestralClassification);
    const nonAncestralEntries = data.entries.filter((x) => x.viralClassification !== Vals.ancestralClassification);
    const drugEntryMap = new Map<string, HeatmapBin>();
    ancestralEntries.forEach((x) => {
      const bin = drugEntryMap.get(x.drugName);
      if (!bin) {
        x.lineageName = Vals.summaryAncestralName;
        drugEntryMap.set(x.drugName, x);
      } else {
        bin.density += x.density;
        bin.pointCount += x.pointCount;
        bin.datasetCount += x.datasetCount;
      }
    });
    const entries = [...nonAncestralEntries, ...Array.from(drugEntryMap.values())];
    lineages.sort((a, b) => (!a.viralRank || !b.viralRank ? Infinity : -a.viralRank + +b.viralRank));
    return {...data, lineages, entries};
  }
  public filterOutNonFeatureVariants(data: Heatmap): Heatmap {
    const lineages = [...data.lineages].filter((x) => {
      const vClass = x.viralClassification ?? ''.toLocaleLowerCase();
      return (
        vClass === Vals.variantOfConcern ||
        vClass === Vals.variantOfInterest ||
        vClass === Vals.otherVariant ||
        vClass === Vals.variantBeingMonitored
      );
    });
    const visible = lineages.map((x) => x.viralLineage);
    const entries = data.entries.filter((s) => visible.indexOf(s.lineageName) > -1);
    return {...data, lineages, entries};
  }

  public drawHeatmap(fullData: Heatmap, root: any, showColors: boolean, svg: any) {
    const data = this.combineAncestral(fullData);
    svg.selectAll('*').remove();
    this.width = 24 * data.drugs.length;
    this.height = 24 * data.lineages.length;
    const svgG = svg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    const title = svgG.append('g').classed('class-title', true);
    title.append('text').text(data.drugClass).attr('transform', 'translate(-120, -150)');
    const uniquenames = [
      Vals.variantOfConcern,
      Vals.variantOfInterest,
      Vals.otherVariant,
      Vals.variantSingleTrunc,
      Vals.variantBeingMonitored
    ];

    const textColors = d3.scaleOrdinal(['#eb5589', '#eba669', '#87a034', '#297b8b', '#BD9812']).domain(uniquenames);

    const heatmapGradient = d3
      .scaleThreshold<number, string, unknown>()
      .domain([6, 11, 16, 21])
      .range(['#FCC5C0', '#F768A1', '#C51B8A', '#7A0177', '#49006A']);

    const drugs = [...new Set(data.drugs.map((d) => d.drugName))];
    const lineages = [...new Set(data.lineages.map((d) => d.viralLineage ?? ''))];

    // Build X scales and axis:
    const x = d3.scaleBand().range([0, this.width]).domain(drugs).padding(0.05);
    const xAxis = svgG.append('g').classed('x-axis', true).style('font-size', 15).call(d3.axisTop(x).tickSize(0));
    xAxis
      .selectAll('.tick text')

      .call(this.truncateLabelX, this.hightlitedCol);
    xAxis.select('.domain').remove();
    xAxis
      .selectAll('text')
      .attr('y', 0)
      .attr('x', 9)
      .attr('dy', '.35em')
      .attr('transform', 'rotate(-45) translate(0 -20)')
      .style('text-anchor', 'start')
      .on('mouseover', (e: any) => d3.select(e.currentTarget).attr('stroke', '#999'))
      .on('mouseout', (e: any) => d3.select(e.currentTarget).attr('stroke', null))
      .on('click', (e: any, d: string) => this.xTextClicked(e, d, root, showColors));

    xAxis.selectAll('.tick line').attr('y1', -3).attr('y2', -15);

    const nameToClass = new Map<string, string>(
      data.lineages.map((l) => {
        return [l.viralLineage ?? '', l.viralClassification ?? ''];
      })
    );

    const y = d3.scaleBand().range([this.height, 0]).domain(lineages).padding(0.05);
    const yaxis = svgG.append('g').classed('y-axis', true).style('font-size', 15).call(d3.axisLeft(y).tickSize(0));
    yaxis.select('.domain').remove();
    yaxis
      .selectAll('.tick text')
      .on('mouseover', (e: any) => d3.select(e.currentTarget).attr('stroke-width', 2))
      .on('mouseout', (e: any) => d3.select(e.currentTarget).attr('stroke-width', null))
      .on('click', (e: any, d: HeatmapBin) => this.yTextClicked(e, d, root, showColors))
      .call(this.truncateLabelY, textColors, nameToClass, this.hightlitedRow);

    let previousClicked: any;

    const rects = svgG
      .selectAll()
      .data(data.entries, (d: HeatmapBin) => d.drugName + ':' + d.lineageName)
      .enter()
      .append('g')
      .attr('transform', (d: HeatmapBin) => `translate(${x(d.drugName)}, ${y(d.lineageName)})`)
      .classed('cell', true)
      .append('rect')
      .attr('x', 2)
      .attr('y', 2)
      .attr('rx', 1)
      .attr('ry', 1)
      .attr('width', x.bandwidth() - 2)
      .attr('height', y.bandwidth() - 2)
      .style('fill', (d: HeatmapBin) => this.getBinFill(d, showColors, heatmapGradient))
      .on('click', (e: any, d: HeatmapBin) => (previousClicked = this.binClicked(d, e, previousClicked, textColors)))
      .on('mouseover', (e: any, d: any) => this.mouseoverBin(e, d, previousClicked))
      .on('mouseleave', this.mouseleaveBin);

    const _this = this;
    rects.each(function (d: any) {
      // @ts-ignore
      _this.drawX(d3.select(this), d, x, y);
    });
  }

  private yTextClicked(e: any, d: any, root: any, showColors: boolean) {
    const parent = d3.select(e.currentTarget).node().parentNode.parentNode.parentNode;
    const thisSvg = d3.select(parent.parentNode);
    this.lastSvgClicked = thisSvg;
    if (this.hightlitedRow === d && this.lastSvgClicked === thisSvg) {
      this.hightlitedRow = null;
    } else {
      this.hightlitedRow = d as string;
    }
    this.drawAllHeatmaps(this.allHeatmaps, root, showColors);
  }

  private xTextClicked(e: any, d: string, root: any, showColors: boolean) {
    const parent = d3.select(e.currentTarget).node().parentNode.parentNode.parentNode;
    const thisSvg = d3.select(parent.parentNode);
    this.lastSvgClicked = thisSvg;
    if (this.hightlitedCol === d && this.lastSvgClicked === thisSvg) {
      this.hightlitedCol = null;
    } else {
      this.hightlitedCol = d as string;
    }
    this.drawAllHeatmaps(this.allHeatmaps, root, showColors);
  }

  private mouseoverBin(event: any, d: any, previousClicked: any) {
    if (d.density === 0 || previousClicked === d) return;
    d3.select(event.target).classed('hover-square', true);
  }

  private mouseleaveBin(event: any, d: HeatmapBin) {
    if (d.density === 0) return;
    d3.select(event.target).classed('hover-square', false);
  }

  private drawX(gEl: any, d: any, x: any, y: any) {
    if (!d.density) {
      const parent = gEl.node().parentNode;
      gEl.style('fill', 'white').style('stroke', '#ddd').style('stroke-width', 2);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 6)
        .style('stroke', 'white')
        .attr('stroke', 'white')
        .attr('x1', 5)
        .attr('x2', x.bandwidth() - 3)
        .attr('y1', 5)
        .attr('y2', y.bandwidth() - 3);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 1)
        .style('stroke', '#bbb')
        .attr('x1', 5)
        .attr('x2', x.bandwidth() - 3)
        .attr('y1', 5)
        .attr('y2', y.bandwidth() - 3);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 6)
        .style('stroke', 'white')
        .attr('x1', 5)
        .attr('x2', x.bandwidth() - 3)
        .attr('y1', y.bandwidth() - 3)
        .attr('y2', 5);

      d3.select(parent)
        .append('line')
        .style('stroke-width', 1)
        .style('stroke', '#bbb')
        .attr('x1', 5)
        .attr('x2', x.bandwidth() - 3)
        .attr('y1', y.bandwidth() - 3)
        .attr('y2', 5);
    }
  }

  private getBinFill(d: any, showColors: boolean, heatmapGradient: any) {
    if (
      (!this.hightlitedRow && !this.hightlitedCol) ||
      d.lineageName === this.hightlitedRow ||
      d.drugName === this.hightlitedCol
    ) {
      if (showColors) {
        return heatmapGradient(d.pointCount);
      }
    } else {
      return '#aaa';
    }
  }

  private binClicked(d: any, e: any, previousClicked: any, textColors: any) {
    const parent = d3.select(e.currentTarget).node().parentNode.parentNode.parentNode;
    d3.select(e.target).classed('hover-square', false);
    const popup = d3.select(parent).selectAll('.popup');
    const width = d.drugName.length > 20 ? 400 : 300;
    popup.remove();
    if (!popup.empty() && previousClicked === d) {
    } else {
      const point = d3.pointer(e, parent);
      d3.select(parent)
        .append('g')
        .classed('popup', true)
        .html(this.getSelectedPointTemplate(d, width, textColors, point[0], point[1]));
      d3.select(parent)
        .selectAll('.close-popup')
        .on('click', () => {
          const popupEls = d3.select(parent).selectAll('.popup');
          d3.select(parent).selectAll('.selected').classed('selected', false);
          if (!popupEls.empty() && previousClicked === d) {
            popupEls.remove();
          }
          previousClicked = null;
        });
    }
    d3.select(parent).selectAll('.selected').classed('selected', false);
    if (previousClicked !== d) {
      const group = d3.select(e.currentTarget).node().parentNode;
      d3.select(group).classed('selected', true);
      previousClicked = d;
    } else {
      previousClicked = null;
    }
    return previousClicked;
  }

  private getSelectedPointTemplate(d: any, width: number, textColors: any, x: number, y: number) {
    const classification =
      d.lineageName.toLowerCase() === Vals.variantSingleTrunc
        ? Vals.variantSingleTrunc
        : d.viralClassification.toLowerCase();
    const datasetPlural = d.datasetCount === 1 ? '' : 's';
    const pointsPlural = d.pointCount === 1 ? '' : 's';
    const activityLink = d.activityDataLink
      ? `
      <div>
        <a class='btn btn-secondary' href='${d.activityDataLink}'>Visualize therapeutic activity data</button>
      <div>`
      : '';
    const datasetLink =
      classification !== Vals.ancestralClassification.toLocaleLowerCase()
        ? ` <div><a style="margin-bottom: 6px" class='browse btn btn-outline-secondary' href='${d.datasetLink}'>Browse Datasets</a><div>`
        : '<div style="height: 93px; width: 100%;"><div style="background-color:#ddd; width: 100%; height: 100%;"></div><div>';
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    const border = !isChrome ? 'border: 1px solid #333;' : '';
    return `<foreignobject  class="node" x="${x - 150}" y="${y - 160}" width="${width + 20}" height="160">
    <div class="popup-container" style="${border} background-color: white; padding: 10px;">
      <div class="close-popup" style="float:right">X</div>
      <div class="popup-title"
        style="color:${textColors(classification)}">
          ${d.lineageName} | ${d.drugName}
      </div>
      <div class="counts"><span>${d.datasetCount}</span> dataset${datasetPlural}, <span>${
      d.pointCount
    }</span> activity datapoint${pointsPlural}</div>

      ${datasetLink}
      ${activityLink}
      <div>
    </foreignobject>
  `;
  }
  private truncateLabelX(labels: any, selectedCol: string) {
    labels.each(function () {
      // @ts-ignore
      const therapeuticName = d3.select(this).text();
      let renderName = therapeuticName;

      if (therapeuticName.length > 29) {
        renderName = renderName.slice(0, 26) + '...';
      }
      // @ts-ignore
      d3.select(this).text(renderName);

      // @ts-ignore
      if (!!selectedCol && selectedCol !== renderName) d3.select(this).style('color', '#d0d0d0');

      if (therapeuticName.length > 29) {
        // @ts-ignore
        d3.select(this).append('title').append('text').text(therapeuticName);
      }
    });
  }

  private truncateLabelY(
    labels: any,
    colors: d3.ScaleOrdinal<string, string, never>,
    nameToClass: Map<string, string>,
    selectedRow: string
  ) {
    labels.each(function () {
      // @ts-ignore
      const lineageName = d3.select(this).text();
      let renderName = lineageName;
      const classifaction = nameToClass.get(lineageName) ?? '';
      const color = lineageName === 'Single mutation' ? colors('single mutation') : colors(classifaction.toLowerCase());
      if (lineageName.length > 29) {
        renderName = renderName.slice(0, 26) + '...';
      }
      // @ts-ignore
      const origTxt = d3.select(this).text(renderName);
      if (color && (!selectedRow || selectedRow === renderName)) {
        origTxt.style('stroke', color).style('fill', color);
      } else {
        origTxt.style('stroke', '#bebebe');
      }

      if (lineageName.length > 29) {
        const txt = d3
          // @ts-ignore
          .select(this)
          .append('title')
          .append('text')
          //
          .text(lineageName);
        if (color !== null) {
          txt.style('stroke', color).style('fill', color);
        }
      }
    });
  }
}
