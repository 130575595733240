<div class="data-container">
  <div class="filter-left">
    <app-vivo-filters (filtersChanged)="filterTable($event)"></app-vivo-filters>
  </div>
  <div class="col-9" [hidden]="showError">
    <div class="total">{{ rowCount }} {{ rowCount === 1 ? ' entry found' : 'entries found' }}</div>
    <ag-grid-angular
      style="width: 1200px; height: 700px"
      class="ag-theme-alpine"
      [rowData]="selectedInVivoPoints"
      [columnDefs]="columnDefs"
      [components]="frameworkComponents"
      [defaultColDef]="defaultColDef"
      [headerHeight]="48"
      (filterChanged)="filterChanged($event)"
      [tooltipShowDelay]="800"
    >
    </ag-grid-angular>
  </div>
</div>

<ng-container *ngIf="showError">
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div class="error-msg">An error has occurred. Please try again later.</div>
  </div>
</ng-container>
