import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VariantAboutComponent} from './about.component';
import {SharedModule} from '@odp/shared';

@NgModule({
  declarations: [VariantAboutComponent],
  imports: [CommonModule, SharedModule]
})
export class AboutModule {}
