import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {IHeaderParams} from 'ag-grid-community';

@Component({
  selector: 'app-trace-lineage-header',
  templateUrl: './trace-lineage-header.component.html',
  styleUrls: ['./trace-lineage-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TraceLineageHeaderComponent implements IHeaderAngularComp {
  public params: any;

  constructor() {}

  public agInit(params: IHeaderParams<any, any>): void {
    this.params = params;
  }
  public refresh(params: IHeaderParams<any, any>): boolean {
    return true;
  }
}
