import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NonHumanPrimate} from '../../../models/dtos/animal/non-human-primate';
import {SmallAnimal} from '../../../models/dtos/animal/small-animal';
// import {AnimalModelService} from '../../../services/api/animal-api/animal-model.service';
import {AnimalModelServiceNew} from '../../../services/api/animal-api/animal-model-new.service';

@Component({
  selector: 'app-animal-models-summary',
  templateUrl: './animal-models-summary.component.html',
  styleUrls: ['./animal-models-summary.component.scss']
})
export class AnimalModelsSummaryComponent implements OnInit {
  smallAnimals: SmallAnimal[] = [];
  nonHumanPrimates: NonHumanPrimate[] = [];

  constructor(private animalModelServiceNew: AnimalModelServiceNew, private titleService: Title) {
    titleService.setTitle('Animal Models');
  }

  ngOnInit(): void {
    this.animalModelServiceNew.getAnimalModel().subscribe((data) => {
      this.smallAnimals = data.smallAnimals;
      this.nonHumanPrimates = data.nonHumanPrimates;
    });
  }
}
