<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container" style="padding-left: 0; padding-right: 0" [hidden]="dataLoading">
  <div class="row py-3 px-3">
    <div class="col-12 p-0 py-3">
      <p class="m-0">
        <a [routerLink]="['/covid19']">Home </a> > <a [routerLink]="['/covid19/databrowser']">DataBrowser</a> >
        <span *ngIf="Sample.sampleName; else NA"> {{ Sample.sampleName }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <table id="sample_info_table">
        <tbody>
          <tr>
            <td style="vertical-align: top">Sample Name:</td>
            <td>
              <h5>
                <a [href]="'https://pubchem.ncbi.nlm.nih.gov/substance/' + Sample.pubchemSid" target="_blank"
                  ><span *ngIf="Sample.sampleName; else NA">{{ Sample.sampleName }}</span></a
                >
              </h5>
            </td>
          </tr>
          <tr>
            <td>Area under the curve (AUC) :</td>
            <td>
              <span *ngIf="Sample.auc; else NA">{{ Sample.auc }}</span>
            </td>
          </tr>
          <tr>
            <td>AC50 (µM) :</td>
            <td>
              <span *ngIf="Sample.ac50; else NA">{{ Sample.ac50 }}</span>
            </td>
          </tr>
          <tr>
            <td>Efficacy (%) :</td>
            <td>
              <span *ngIf="Sample.efficacy; else NA">{{ Sample.efficacy }}</span>
            </td>
          </tr>

          <tr>
            <td>Curve Class:</td>
            <td>
              <span *ngIf="Sample.curveClass2; else NA">{{ Sample.curveClass2 }}</span>
            </td>
          </tr>
          <tr>
            <td>Assay Name :</td>
            <td>
              <span *ngIf="Sample.assayName; else NA">{{ Sample.assayName }}</span>
            </td>
          </tr>
          <tr>
            <td>Library :</td>
            <td>
              <span *ngIf="Sample.library; else NA">{{ Sample.library }}</span>
            </td>
          </tr>
          <tr>
            <td>Primary Mechanism of Action (MOA):</td>
            <td>
              <span *ngIf="Sample.primaryMoa; else NA"> {{ Sample.primaryMoa }}</span>
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top">Curve:</td>
            <td>
              <div id="curve_holder" [innerHTML]="curve | SafeHtml"></div>
            </td>
          </tr>
        </tbody>
        <ng-template #NA>
          <span> N/A</span>
        </ng-template>
      </table>
    </div>

    <div class="col-md-4" id="sample_img">
      <img class="pull-left" [src]="smileImage" />
    </div>
  </div>
</div>

<app-footer-view [showCC]="true"></app-footer-view>
