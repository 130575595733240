<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Therapeutic Activity Explorer
          <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span>
        </h3>
        <div class="d-flex pb-4">
          <div class="w-60">
            <div class="scores">
              <div>
                <span class="score">{{ dataSourceCount }}</span> data sources
              </div>
              <div class="ml-2">
                <span class="score">{{ recordCount }}</span> activity data points
              </div>
            </div>
          </div>
        </div>
        <p>
          OpenData Portal, in collaboration with ACTIV and industry partners, has compiled a database of in vitro
          therapeutic activity against SARS-CoV-2 variants from a prioritized set of publications (both preprints and
          peer-reviewed articles). Read more about this dataset and how to interpret/use it
          <a class="interpret" [routerLink]="['/covid19/variant/about']">here.</a>
        </p>
        <a href="{{ zipUrl }}" class="btn btn-primary bg-white download-all" *ngIf="!dataLoading && zipUrl">
          Download the in vitro datasets here
        </a>
      </div>
    </div>
  </div>
</div>
