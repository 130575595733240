<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container" style="padding-left: 0; padding-right: 0" [hidden]="loading">
  <div class="navigation mt-4">
    <div class="row search col-md-4 col-sm-10 ng-autocomplete">
      <ng-autocomplete
        [data]="searchData"
        [searchKeyword]="keyword"
        placeholder="MOA, SID, gene, sample/drug name or gene"
        (selected)="selectEvent($event)"
        (inputChanged)="onChangeSearch($event)"
        (inputCleared)="getData(1)"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
      >
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a class="option" [innerHTML]="item.value"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    <div class="row col-md-4 col-sm-12 libraries">
      <select
        class="browser-default custom-select"
        [(ngModel)]="currentLibrary"
        (change)="changeLibrary($event.target)"
      >
        <option *ngFor="let library of libraries" [value]="library.id">{{ library.name }}</option>
      </select>
    </div>

    <div class="col-md-4 col-sm-12" style="padding-right: 0">
      <div style="text-align: right">
        <div
          class="legend activity-extra"
          ngbPopover="Best quality actives"
          triggers="mouseenter:mouseleave"
          popoverClass="my-custom-class"
          placement="bottom"
        ></div>
        <div
          class="legend activity-high"
          ngbPopover="High quality actives"
          triggers="mouseenter:mouseleave"
          placement="bottom"
        ></div>
        <div
          class="legend activity-low"
          ngbPopover="Low quality actives"
          triggers="mouseenter:mouseleave"
          placement="bottom"
        ></div>
        <div
          class="legend counter-extra"
          ngbPopover="Best quality actives in counter assay"
          triggers="mouseenter:mouseleave"
          placement="bottom"
        ></div>
        <div
          class="legend counter-high"
          ngbPopover="High quality actives in counter assay"
          triggers="mouseenter:mouseleave"
          placement="bottom"
        ></div>
        <div
          class="legend counter-low"
          ngbPopover="Low quality actives in counter assay"
          triggers="mouseenter:mouseleave"
          placement="bottom"
        ></div>
        <div class="legend inactive" ngbPopover="Inactives" triggers="mouseenter:mouseleave" placement="bottom"></div>
        <div class="legend untested" ngbPopover="Untested" triggers="mouseenter:mouseleave" placement="bottom"></div>
      </div>
    </div>
  </div>
  <ng-container>
    <table class="main-table table table-sm table-bordered mt-4">
      <thead>
        <tr>
          <th colspan="2">Drug Information</th>
          <th colspan="2">Live Virus Infectivity</th>
          <th colspan="6">In Vitro<br />Infectivity</th>
          <th colspan="6">Viral Entry</th>
          <th colspan="2">Viral Replication</th>
          <th colspan="2">Human <br />Cell Toxicity</th>
        </tr>
        <tr class="name-row">
          <th class="show-sort sort-icon w200">
            Drug Name
            <i [hidden]="drugDirAsc" (click)="sortDrugDir()" class="fa fa-chevron-down"></i>
            <i [hidden]="!drugDirAsc" (click)="sortDrugDir()" class="fa fa-chevron-up"></i>
          </th>
          <th class="show-sort sort-icon w200">
            Primary MOA
            <i [hidden]="moaDirAsc" (click)="sortMoaDir()" class="fa fa-chevron-down"></i>
            <i [hidden]="!moaDirAsc" (click)="sortMoaDir()" class="fa fa-chevron-up"></i>
          </th>
          <th class="rotate" (click)="sortAssay(assay)" *ngFor="let assay of assays">
            <div [ngbPopover]="assay!.type!" triggers="mouseenter:mouseleave" placement="bottom">
              <svg>
                <text>{{ toTitleCase(assay.name) }}</text>
              </svg>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let row of sampleData
              | paginate
                : {
                    itemsPerPage: this.pageSize,
                    currentPage: currentPage,
                    totalItems: totalItems
                  }
          "
        >
          <td class="title">
            <a target="_blank" [routerLink]="['./summary', row.sampleId.split('-')[0], 'background']">{{
              toTitleCase(row.sampleName)
            }}</a>
          </td>
          <td
            class="title"
            [ngbPopover]="row.primaryMoa"
            triggers="mouseenter:mouseleave"
            placement="bottom"
            container="body"
          >
            {{ toTitleCase(row.primaryMoa) }}
          </td>
          <td
            *ngFor="let assay of assays"
            [ngClass]="row.activity['aid' + assay.id]"
            [ngbPopover]="row.activity['aid' + assay.id] ? popContent : ''"
            (click)="openSample(row.activity['aid' + assay.id].substring(2))"
            [ngStyle]="{cursor: row.activity['aid' + assay.id] ? 'pointer' : 'default'}"
            container="body"
            triggers="mouseenter:mouseleave"
            popoverClass="my-custom-class"
          >
            <ng-template #popContent>
              <div>Efficacy: {{ row.activity['aid' + assay.id + '_efficacy'] }}%</div>
              <div>
                Potency:
                {{
                  row.activity['aid' + assay.id + '_ac50']
                    ? row.activity['aid' + assay.id + '_ac50'] + ' &#181;M'
                    : 'N/A'
                }}
              </div>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <div class="row">
    <div class="col-md-2 col-sm-6">
      <div>
        <select class="browser-default custom-select" (change)="changePageSize($event.target)">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="500">500</option>
        </select>
      </div>
    </div>
    <div class="col-md-10 ng-autocomplete" style="text-align: right">
      <pagination-controls (pageChange)="getData((currentPage = $event))"></pagination-controls>
    </div>
  </div>
</div>

<ng-container *ngIf="loading">
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
</ng-container>
<app-footer-view [showCC]="true"></app-footer-view>
