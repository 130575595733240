<div class="tab-content single-agent w-100 active" id="single_agent">
  <div class="h5">
    Total number of assays screened <span id="num_assay" class="badge badge-success">{{ totalNumberOfAssay }}</span>
  </div>
  <div *ngFor="let agent of agents">
    <div class="row assay-block mt-4 mb-3">
      <div class="col-md-12 assay-name h5">
        <a [routerLink]="['/covid19/assays/assay', agent.assayId, 'single-agent']" target="_blank">
          {{ agent.assayName }}</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 curve-div" id="curve_1">
        <div id="curve_holder" [innerHTML]="agent.SVG | SafeHtml"></div>
      </div>
      <div class="col-md-6 data-div" id="data_1">
        <div class="data-block">
          <table class="specs">
            <tbody>
              <tr>
                <td style="background-color: #1f77b4">AC50 <span class="small">(µM)</span></td>
                <td>{{ agent.specs.ac50 | number : '1.2-2' }}</td>
              </tr>
              <tr>
                <td style="background-color: #1f77b4">Efficacy <span class="small">(%)</span></td>
                <td>{{ agent.specs.efficacy | number : '1.2-2' }}</td>
              </tr>
              <tr>
                <td style="background-color: #1f77b4">Hill Slope</td>
                <td>{{ agent.specs.hillCoef | number : '1.2-2' }}</td>
              </tr>
              <tr>
                <td style="background-color: #1f77b4">Curve Class</td>
                <td>{{ agent.specs.cruveClass2 | number : '1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
