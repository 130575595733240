import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-nhp-field-guide',
  templateUrl: './nhp-field-guide.component.html',
  styleUrls: ['./nhp-field-guide.component.scss']
})
export class NhpFieldGuideComponent {
  constructor() {}
}
