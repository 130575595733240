import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {forkJoin} from 'rxjs';
import {ChartMapperService} from '../../activity/chart-mapper/chart-mapper.service';
import {Heatmap} from '../../../models/view-models/heatmap';
import {HeatmapMapperService} from '../heatmap-mapper/heatmap-mapper.service';
import {HeatmapService} from './heatmap.service';
import {LineageApiService} from '../../../services/api/lineage-api/lineage-api.service';
import {HeatmapDensityApiService} from '../../../services/api/heatmap-density-api/heatmap-density-api.service';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeatmapComponent implements OnInit {
  @ViewChild('chart', {static: true}) chart!: ElementRef;
  public showColors = false;
  private heatmaps: Heatmap[] = [];
  public hideNonFeatures = true;
  public dataLoading = true;
  constructor(
    private heatmapService: HeatmapService,
    private lineageApi: LineageApiService,
    private chartMapper: ChartMapperService,
    private heatmapMapper: HeatmapMapperService,
    private densityApi: HeatmapDensityApiService
  ) {}

  ngOnInit(): void {
    const lineages$ = this.lineageApi.getLineages();
    const densities$ = this.densityApi.getDataDensities();
    forkJoin([lineages$, densities$]).subscribe(([lineages, densities]) => {
      const allLineages = this.chartMapper.getHeatmapLineages(lineages).reverse();
      this.heatmaps = this.heatmapMapper
        .getCharts(allLineages, densities.data, lineages)
        .sort((a, b) => b.drugs.length - a.drugs.length);
      this.heatmapService.drawCharts(this.heatmaps, this.chart.nativeElement, this.showColors);
      this.dataLoading = false;
    });
  }

  public toggleColors() {
    this.showColors = !this.showColors;
    this.heatmapService.drawCharts(this.heatmaps, this.chart.nativeElement, this.showColors);
  }
}
