import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimePointsChartComponent} from './time-points-chart/time-points-chart.component';
import {ActivityModule} from '../activity/activity.module';
import {SharedModule} from '@odp/shared';
import {CirculatingLayoutComponent} from './circulating-layout/circulating-layout.component';
import {CirculatingHeaderComponent} from './circulating-header/circulating-header.component';

@NgModule({
  declarations: [CirculatingLayoutComponent, TimePointsChartComponent, CirculatingHeaderComponent],
  imports: [CommonModule, ActivityModule, SharedModule]
})
export class CircultatingVariantsModule {}
