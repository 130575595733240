import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {ActivityChartQuery} from '../models/dtos/activity/activity-chart-ql';
import {InVivoQuery} from '../models/dtos/in-vivo/invivo-ql';

@Injectable({
  providedIn: 'root'
})
export class InVitroZipQL extends Query<InVivoQuery, {}> {
  override document = gql`
    {
      files(name: "TRACE-INVITRO-DATA.zip") {
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  `;
}
