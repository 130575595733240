import {Component, OnInit} from '@angular/core';
import {AboutTrace} from '../../../models/dtos/trace';
import {TraceAssayApiService} from '../../../services/api/trace-api/trace-api-new.service';
@Component({
  selector: 'app-trace-about',
  templateUrl: './trace-about.component.html',
  styleUrls: ['./trace-about.component.scss']
})
export class TraceAboutComponent implements OnInit {
  aboutData: AboutTrace[] | null = null;

  constructor(private traceApi: TraceAssayApiService) {}

  ngOnInit(): void {
    this.traceApi.getAboutTrace().subscribe((data) => {
      this.aboutData = data;
    });
  }
}
