import {Component, OnInit} from '@angular/core';
import * as d3 from 'd3';
import {ReagentDetails} from '../../../models/dtos/trace';
import {TraceAssayApiService} from '../../../services/api/trace-api/trace-api-new.service';
@Component({
  selector: 'app-trace-reagent',
  templateUrl: './trace-reagent.component.html',
  styleUrls: ['./trace-reagent.component.scss']
})
export class TraceReagentComponent implements OnInit {
  public reagentDetails: Map<string, ReagentDetails[]> = new Map<string, ReagentDetails[]>();
  constructor(private traceApi: TraceAssayApiService) {}

  ngOnInit(): void {
    this.traceApi.getReagentDetails().subscribe((reagents: ReagentDetails[]) => {
      this.reagentDetails = d3.group(reagents, (d) => d.reagentClass);
    });
  }
}
