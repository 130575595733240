import {Injectable} from '@angular/core';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class PieChartService {
  private pieData: PieDataModel[] = [];

  // set the dimensions and margins of the graph
  private width = 800;
  height = 450;
  margin = 40;

  private svg!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

  // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
  private radius = Math.min(this.width, this.height) / 2 - this.margin;

  onInit(pieData: PieDataModel[]) {
    this.pieData = pieData;

    this.createSvg();
    this.drawChart();
  }

  private createSvg(): void {
    this.svg = d3
      .select('figure#pie')
      .append('svg')
      .attr('viewBox', `${this.width / 4} 0 ${this.width} ${this.height}`)
      .append('g')
      .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 2 + ')');
  }

  private drawChart(data = this.pieData): void {
    // Compute the position of each group on the pie
    const pie = d3.pie<any>().value((d: any) => Number(d.value));

    // Sort Data by Largest Value
    data.sort((x, y) => {
      return d3.ascending(y.value, x.value);
    });

    const dataReady = pie(data);

    const colors = d3
      .scaleOrdinal()
      .domain(this.pieData.map((d) => d.name))
      .range(['#523863', '#286276', '#D2826E', '#FADC54']);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    this.svg
      .selectAll('rect')
      .data(dataReady)
      .enter()
      .append('path')
      .attr(
        'd',
        // @ts-ignore
        d3
          .arc()
          .innerRadius(100) // This is the size of the donut hole
          .outerRadius(this.radius)
      )
      .attr('fill', (d): any => {
        return colors(d.data.name);
      })
      .style('opacity', 1);

    const legendRectSize = 25;
    const legendSpacing = 4;

    const legend = this.svg
      .selectAll('.legend')
      .data(dataReady)
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => {
        const height = legendRectSize + legendSpacing;
        const offset = (height * colors.domain().length) / 2;
        const horz = 10 * legendRectSize - 50;
        const vert = i * height - offset;
        return 'translate(' + horz + ',' + vert + ')';
      });

    legend
      .append('rect')
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', (d): any => {
        return colors(d.data.name);
      })
      .style('stroke', (d): any => {
        return colors(d.data.name);
      });

    legend
      .append('text')
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize - legendSpacing)
      .attr('class', 'legendText')
      .text((d) => {
        return `${d.data.name} (${d.data.value}%)`;
      });
  }
}

export interface PieDataModel {
  name: string;
  value: string;
  color?: string;
}
