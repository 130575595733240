<div class="row mt-4">
  <div class="col-md-6">
    <div class="h4 font-weight-bold">Identifiers</div>
    <div class="row mt-4 identifier">
      <div class="col-md-4"><strong>Sample Name</strong></div>
      <div class="col-md-8 compound-name">
        <span *ngIf="drug?.SAMPLE_NAME; else NA"> {{ drug!.SAMPLE_NAME }}</span>
      </div>
    </div>
    <div class="row mt-2 identifier">
      <div class="col-md-4"><strong>NCATS ID</strong></div>
      <div class="col-md-8" id="ncgc_id">
        <span *ngIf="drug?.NCGC_ID; else NA"> {{ drug!.NCGC_ID }}</span>
      </div>
    </div>
    <div class="row mt-2 identifier">
      <div class="col-md-4"><strong>CAS</strong></div>
      <div class="col-md-8" id="cas">
        <span *ngIf="drug?.CAS; else NA"> {{ drug!.CAS }}</span>
      </div>
    </div>
    <div class="row mt-2 identifier">
      <div class="col-md-4"><strong>Pubchem SID</strong></div>
      <div class="col-md-8" id="pubchem_sid">
        <div *ngIf="drug?.PUBCHEM_SID; else NA">
          <a href="https://pubchem.ncbi.nlm.nih.gov/substance/{{ drug!.PUBCHEM_SID }}" target="_blank">{{
            drug!.PUBCHEM_SID
          }}</a>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-5 identifier">
      <div class="col-md-4"><strong>SMILES</strong></div>
      <div
        class="col-md-8"
        id="smiles_str"
        title=""
        data-toggle="popover"
        [attr.data-content]="drug?.SMILES"
        data-original-title="Copied to clipboard"
      >
        <span *ngIf="drug?.SMILES; else NA"> {{ drug!.SMILES }}</span>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div id="sample_structure" data-toggle="popover" data-html="true" data-original-title="" title="">
      <img class="pull-left" [src]="smileImage" />
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 h5">Activity Summary</div>
</div>
<div class="row mt-3 activity_chart_header">
  <div class="col-md-4">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label
        class="btn btn-sm btn-custom shadow-none active"
        [ngClass]="{active: isPotencyData}"
        (click)="loadPotencyChart()"
      >
        <input type="radio" name="options" id="potency_btn" autocomplete="off" checked /> Potency
      </label>
      <label
        class="btn btn-sm btn-custom shadow-none"
        [ngClass]="{active: isEfficacyData}"
        (click)="loadEfficacyChart()"
      >
        <input type="radio" name="options" id="efficacy_btn" autocomplete="off" /> Efficacy
      </label>
    </div>
  </div>
  <div class="col-md-8">
    <div class="pull-left">
      <div
        class="legend activity-high"
        triggers="mouseenter:mouseleave"
        placement="top"
        ngbPopover="Compound showed potential activity against a SARS-CoV-2-related target"
        popoverTitle="Active in primary assay"
      ></div>
      <div
        class="legend counter-high"
        triggers="mouseenter:mouseleave"
        placement="top"
        ngbPopover="Compound may be an assay artifact or interfere with related primary assay"
        popoverTitle="Active in counterscreen"
      ></div>
      <div
        class="legend inactive"
        triggers="mouseenter:mouseleave"
        placement="top"
        ngbPopover="Compound showed weak or insignificant activity in assay"
        popoverTitle="Inactive"
      ></div>
    </div>
  </div>
</div>
<div class="row mt-4" *ngIf="updateFlag">
  <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" style="width: 100%; display: block">
  </highcharts-chart>
</div>

<ng-template #NA>
  <span> N/A</span>
</ng-template>
