import {Injectable} from '@angular/core';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root'
})
export class HorizontalChartService {
  private horizontalChartData: HorizontalChartDataModel[] = [];
  private margin = {top: 20, right: 30, bottom: 40, left: 90};
  private width = 300 - this.margin.left - this.margin.right;
  private height = 200 - this.margin.top - this.margin.bottom;
  private svgEl!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private maxX!: d3.NumberValue;

  onInit(horizontalChartData: HorizontalChartDataModel[]) {
    this.horizontalChartData = horizontalChartData;
    const orderControl = [
      'FDA approved',
      'FDA/EUA approved',
      'EUA approved',
      'EUA application submitted',
      'EUA revoked',
      'Investigational',
      'Discontinued',
      'Research reagent'
    ];

    this.horizontalChartData.sort(function (a, b) {
      return orderControl.indexOf(a.name) - orderControl.indexOf(b.name);
    });

    this.createSvg();
    this.drawChart();
  }

  private createSvg(): void {
    this.svgEl = d3
      .select('#hr-chart-approved-status')
      .append('svg')
      .attr('class', 'w-100')
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private drawChart(): void {
    this.maxX = Math.max(100, ...this.horizontalChartData.map((o) => o.value));

    // Add X axis
    const x = d3.scaleLinear().domain([0, this.maxX]).range([0, this.width]);

    // Add Y axis
    const y = d3
      .scaleBand()
      .range([0, this.height])
      .domain(this.horizontalChartData.map((d) => d.name))
      .padding(0.1);

    this.svgEl
      .append('g')
      .attr('transform', 'translate(25,' + this.height + ')')
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('text-anchor', 'middle');

    this.svgEl
      .append('g')
      .call(d3.axisLeft(y))
      .style('transform', 'translate(25px, 0px)')
      .selectAll('.tick text')
      .style('text-anchor', 'start')
      .attr('transform', 'translate(-100,0)rotate(0)')
      .on('mouseover', (event: any, d: any) => {
        this.mouseOverTherapeutic(event, d);
      })
      .on('mouseout', this.mouseOutTherapeutic)
      .call(this.truncateLabel);

    // Bars
    this.svgEl
      .selectAll('rect')
      .data(this.horizontalChartData)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .style('transform', 'translate(25px, 0px)')
      .attr('y', (d) => (y(d.name) ?? 0) + 0)
      .attr('width', (d) => x(d.value))
      .attr('height', y.bandwidth())
      .attr('fill', (d) => d.color);
  }

  private truncateLabel(labels: any) {
    labels.each(function () {
      // @ts-ignore
      if (this) {
        // @ts-ignore
        const therapeuticName = d3.select(this).text();
        let renderName = therapeuticName;

        if (therapeuticName.length > 20) {
          renderName = renderName.slice(0, 23) + '...';
        }
        // @ts-ignore
        d3.select(this).text(renderName);
        if (therapeuticName.length > 15) {
          // @ts-ignore
          d3.select(this).append('title').append('text').style('text-anchor', 'start').text(renderName);
        }
      }
    });
  }

  public mouseOutTherapeutic(event: any, d: any) {
    const parent = d3.select(event.currentTarget).node().parentNode.parentNode.parentNode.parentNode
      .parentNode.parentNode;
    const popup = d3.select(parent).selectAll('.popup');
    popup.remove();
  }

  public mouseOverTherapeutic(event: any, d: any) {
    const parent = d3.select(event.currentTarget).node().parentNode.parentNode.parentNode.parentNode
      .parentNode.parentNode;
    d3.select(event.target).classed('hover-square', false);
    const popup = d3.select(parent).selectAll('.popup');
    const width = d.length > 20 ? 400 : 300;
    popup.remove();
    const point = d3.pointer(event, parent);
    d3.select(parent)
      .append('g')
      .classed('popup', true)
      .attr('style', `top: ${point[1] + 20}px; left: ${point[0] + 30}px`)
      .html(this.getSelectedPointTemplate(d));
  }

  private getSelectedPointTemplate(d: any) {
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    const border = !isChrome ? 'border: 1px solid #333;' : '';
    return `
    <foreignobject  class="node mouseover">
    <div class="popup-container" style="${border} background-color: white; padding: 5px 10px; border-radius: 4px">
        <div class="t-name">${d} </div>
        </div>
    </foreignobject>`;
  }
}

export interface HorizontalChartDataModel {
  name: string;
  value: number;
  color: string;
}
