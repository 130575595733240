<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container">
  <div class="row py-3 dataset my-5 px-3">
    <div class="d-block">
      <h3>How to Read Variant Therapeutic Data on OpenData Portal</h3>
      <p>
        The SARS-CoV-2 variant therapeutic data on the OpenData Portal have been curated in collaboration with the
        National Institutes of Health (NIH)
        <a href="https://www.nih.gov/research-training/medical-research-initiatives/activ/governance" target="_blank"
          >Accelerating COVID-19 Therapeutic Interventions and Vaccines (ACTIV) Preclinical Working Group</a
        >
        with support from the Foundation for the National Institutes of Health (FNIH). New and updated information will
        be added on a weekly basis as more studies are shared. Please continue to check back as our curated database
        grows.
      </p>
      <h3>What Variant Therapeutic Data is Included?</h3>
      <p>
        The underlying data in our provided visualizations has been curated, in collaboration with ACTIV, from a
        prioritized set of publications (both preprints and peer-reviewed articles) or data directly submitted from
        ACTIV members. To improve data accuracy, publications are limited to prominent therapeutic agents (both approved
        and in clinical trial), with an emphasis on studies conducted 1) by the sponsoring pharmaceutical company or 2)
        with a government partner.
        <b
          >The OpenData Portal does not intend to serve as a comprehensive data dashboard for all variant therapeutic
          data published in the literature.</b
        >
      </p>
      <h3>How to Interpret the Visualizations</h3>
      <p>
        The visualization graphics are meant to provide a quick-glance summary of how individual SARS-CoV-2 variants may
        respond to known therapeutics, compared to reference strains. The displayed fold-change values represent data
        collected from published in vitro viral neutralization assays comparing variants to a reference strain. Data
        without reported or calculatable fold-change data is not included in the visualizations.
      </p>
    </div>
    <div class="d-block">
      <p><b>Of important note,</b> the data displayed were generated:</p>
      <ul class="ml-3">
        <li>From different assay types and conditions</li>
        <li>By different research laboratories</li>
        <li>Using different reference strains</li>
        <li>
          With test material from different sources/of potentially different grades, tested at different dose ranges
        </li>
      </ul>
    </div>
    <div class="d-block">
      <p>
        As a result, the visualizations <b>should not be used to conduct side-by-side comparisons</b> of therapeutics.
        Reported minimum fold reduction values (e.g. >1000-fold) may have greater actual fold change values than those
        displayed. Furthermore, the data shown are collected from in vitro assays, and it is not known how in vitro
        neutralization assay data correlate with clinical outcomes. It is worth noting that the experimental therapeutic
        concentrations are not necessarily correlated to clinical concentrations; thus therapeutics with large reported
        fold reductions in activity <b>may still be active against the variants in clinical settings</b>, as standard
        dosing/exposure in patients could exceed the required therapeutic window.
      </p>
      <p>
        The data may be from preliminary reports that <b>have not been peer reviewed</b> and thus should not be regarded
        as conclusive, guide clinical practice or health decisions, or be reported in news media as established
        information.
      </p>
      <p>
        Please contact us at <a href="mailto:NCATSOpenDataPortal@nih.gov">NCATSOpenDataPortal&#64;nih.gov</a> with any
        feedback, comments, or questions to help us improve this resource.
      </p>
    </div>
    <div class="d-block text-right text-secondary w-100">
      <small> Last updated April 26th, 2021 </small>
    </div>
  </div>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
