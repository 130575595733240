<div class="" [hidden]="dataLoading" class="time-points-container">
  <h2 class="proportion-header">Variant Proportions</h2>
  <div class="row circle-chart">
    <div class="circulating-container">
      <div class="circulating">
        <div #oChart class="pb-3"></div>
        <div #tree></div>
      </div>
      <div class="circulating-legend" *ngIf="!!selectedDate">
        <label class="week-label"
          >Week of: <span class="week-of">{{ selecteDateObj | date : 'MM/dd/yyyy' }}</span></label
        >

        <div
          [ngClass]="{
            'selected-week-var': (selectedVariant | async) === point.variant,
            selected: legendVariant === point.variant
          }"
          (click)="selectVariant(point.variant)"
          class="slice-variant"
          *ngFor="let point of sliceVariants"
        >
          <div class="l-color" *ngIf="subvaraintColors.get(point.variant)">
            <svg height="25" width="25">
              <circle r="10" cy="10" cx="10" [ngStyle]="{fill: subvaraintColors.get(point.variant)}" />
            </svg>
          </div>
          <div class="l-color" *ngIf="!subvaraintColors.get(point.variant)">
            <svg height="25" width="25"><circle r="10" cy="10" cx="10" style="fill: black" /></svg>
          </div>

          <div>{{ point.variant }}</div>
          <div [hidden]="point.share === 0">{{ point.share * 100 | number : '1.1-1' }}%</div>
          <div [hidden]="point.share !== 0">--</div>
        </div>
      </div>
    </div>
    <div class="chart-content">
      <div [hidden]="lineageLoading" class="activity-points" [hidden]="activityPointsLoading">
        <div #chart></div>
        <app-selected-point-panel
          [selectedDataPoint]="selectedDataPoint | async"
          [neighbors]="neighbors | async"
          [colorByVariant]="colorByLineage"
        ></app-selected-point-panel>
      </div>

      <div *ngIf="lineageLoading" class="lineage-loader-container">
        <div class="backdrop"></div>
        <app-loader class="lineage-loader w100 position-absolute"></app-loader>
      </div>
    </div>

    <div class="activity-points activity-loader" [hidden]="!activityPointsLoading">
      <div class="loader-container"><app-loader></app-loader></div>
    </div>
  </div>
</div>
<ng-container *ngIf="dataLoading">
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
</ng-container>
