import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {AppConfig, APP_CONF} from '@odp/shared';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('./config/config.json')
  .then((response) => response.json())
  .then((config: AppConfig) => {
    platformBrowserDynamic([{provide: APP_CONF, useValue: config}]).bootstrapModule(AppModule);
  });
