<div class="container">
  <div class="col-12 p-0">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [rowData]="selectedAssayPoints"
      [columnDefs]="columnDefs"
      [components]="frameworkComponents"
      [defaultColDef]="defaultColDef"
      domLayout="autoHeight"
      [headerHeight]="48"
      [tooltipShowDelay]="800"
    >
    </ag-grid-angular>
  </div>
</div>
