<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Heterologous Booster Activity
          <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span>
        </h3>
        <div class="pb-4">
          <div>
            Browse a database of curated heterologous COVID-19 booster activity against SARS-CoV-2 variants, along with
            homologous booster comparisons.
          </div>
        </div>

        <a [href]="downloadDatasetPath" class="btn btn-primary bg-white download-all" *ngIf="!dataLoading" download>
          Download the Heterologous Booster dataset here
        </a>
      </div>
    </div>
  </div>
</div>
