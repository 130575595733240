import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-gs441524-studies',
  templateUrl: './gs441524-studies.component.html',
  styleUrls: ['./gs441524-studies.component.scss']
})
export class Gs441524StudiesComponent implements OnInit {
  @ViewChild('mouse_chart') public mouseChartEl!: ElementRef;
  @ViewChild('rat_chart') public ratChartEl!: ElementRef;
  @ViewChild('dog_chart') public dogChartEl!: ElementRef;
  @ViewChild('cyno_chart') public cynoChartEl!: ElementRef;

  studyDisplayStatuses: {[key: string]: boolean} = {};
  chartOptions: {[key: string]: Highcharts.Options} = {};

  constructor(private httpClient: HttpClient, private titleService: Title) {
    this.studyDisplayStatuses['mouse'] = true;
  }

  ngOnInit(): void {
    this.titleService.setTitle('GS-441524 Studies');
    this.httpClient.get('./assets/data/GS-442524-graph-data.json').subscribe((data) => {
      this.chartOptions['mouse'] = this.getChartOptions(this.getDataByType(data, 'Mouse', 5, 10));
      this.chartOptions['rat'] = this.getChartOptions(this.getDataByType(data, 'Rat', 5, 10));
      this.chartOptions['dog'] = this.getChartOptions(this.getDataByType(data, 'Dog', 2, 5));
      this.chartOptions['cyno'] = this.getChartOptions(this.getDataByType(data, 'Cyno', 2, 5));
      Highcharts.chart(this.mouseChartEl.nativeElement, this.chartOptions['mouse']);
    });
  }

  toggleSection(animal: any) {
    this.studyDisplayStatuses[animal] = !this.studyDisplayStatuses[animal];
    if (this.studyDisplayStatuses[animal]) {
      switch (animal) {
        case 'mouse':
          Highcharts.chart(this.mouseChartEl.nativeElement, this.chartOptions[animal]);
          break;
        case 'rat':
          Highcharts.chart(this.ratChartEl.nativeElement, this.chartOptions[animal]);
          break;
        case 'dog':
          Highcharts.chart(this.dogChartEl.nativeElement, this.chartOptions[animal]);
          break;
        case 'cyno':
          Highcharts.chart(this.cynoChartEl.nativeElement, this.chartOptions[animal]);
          break;
      }
    }
  }

  getChartOptions(data: any): Highcharts.Options {
    return {
      // @ts-ignore
      chart: {zoomType: 'xy', width: 547},
      title: {text: ''},
      plotOptions: {line: {marker: {symbol: 'circle', enabled: true}}},
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        floating: true,
        x: -10,
        y: 20
      },
      xAxis: [
        {
          tickInterval: 5,
          title: {
            text: 'Time (hr)',
            // @ts-ignore
            style: {color: Highcharts.getOptions().colors?.[1]}
          }
        }
      ],
      // @ts-ignore
      yAxis: {
        type: 'logarithmic',
        labels: {
          format: '{value}',
          style: {color: Highcharts.getOptions().colors?.[1]}
        },
        title: {
          text: 'Concentration (ng/mL)',
          style: {color: Highcharts.getOptions().colors?.[1]}
        }
      },

      tooltip: {
        shared: true,
        useHTML: true,
        shadow: false,
        borderColor: '#777',
        formatter() {
          const addColor = (val: any, color: any) => {
            return '<span style="color:' + color + '"> ' + val + '</span>';
          };
          const points = this.points;
          let html = 'Time: &nbsp ' + points?.[0].point.x + ' hr<br/>';
          points?.forEach((d: any, i) => {
            const color = d.color;
            if (i % 2 === 0) html += addColor(d.series.name + ': &nbsp ' + d.y, color);
            else {
              // @ts-ignore
              const negativeX = d.series.userOptions.negativeX;
              let diff = d.point.options.high - d.point.options.low;
              if (!(negativeX && negativeX.includes(d.point.x))) {
                diff = diff / 2;
              }
              html += addColor('&plusmn;' + diff.toPrecision(3), color) + '<br/>';
            }
          });
          return html;
        }
      },

      series: [
        {
          type: 'line',
          name: data.series[0].name,
          data: data.series[0].data,
          color: data.series[0].color,
          tooltip: {}
        },
        {
          name: 'SD',
          type: 'errorbar',
          data: data.series[0].sd,
          color: data.series[0].color,
          // @ts-ignore
          negativeX: data.series[0].negativeX,
          tooltip: {
            pointFormatter() {
              const point = this.series.data[this.index];
              return ' ±' + ((point.options.high! - point.options.low!) / 2).toPrecision(3) + '<br/>';
            }
          }
        },
        {
          type: 'line',
          name: data.series[1].name,
          data: data.series[1].data,
          color: data.series[1].color
        },
        {
          name: 'SD',
          type: 'errorbar',
          data: data.series[1].sd,
          color: data.series[1].color,
          // @ts-ignore
          negativeX: data.series[0].negativeX,
          tooltip: {
            pointFormatter() {
              const point = this.series.data[this.index];
              return ' ±' + ((point.options.high! - point.options.low!) / 2).toPrecision(3) + '<br/>';
            }
          }
        }
      ]
    };
  }

  getDataByType(data: any, type: any, ivpo1: any, ivpo2: any) {
    const arr = data
      .filter((d: any) => {
        return d.Type === type;
      })
      .map((d: any) => {
        const low = d.Mean - d.SD;
        d.error = [d.Time, low > 0 ? low : d.Mean /*avoid negative value for logarithmatic*/, d.Mean + d.SD];
        if (low <= 0) d.negative = d.Time; // negative value
        return d;
      });

    const data1 = this.getXYData(arr, ivpo1, 'Mean');
    const data2 = this.getXYData(arr, ivpo2, 'Mean');
    const error1 = this.getFieldData(arr, ivpo1, 'error');
    const error2 = this.getFieldData(arr, ivpo2, 'error');
    const negativeX1 = this.getFieldData(arr, ivpo1, 'negative').filter((d: any) => {
      return d;
    });
    const negativeX2 = this.getFieldData(arr, ivpo2, 'negative').filter((d: any) => {
      return d;
    });

    return {
      type,
      series: [
        {data: data1, sd: error1, negativeX: negativeX1, name: ivpo1 + ' mg/kg IV', color: '#951883'},
        {data: data2, sd: error2, negativeX: negativeX2, name: ivpo2 + ' mg/kg PO', color: '#264597'}
      ]
    };
  }

  getXYData(arr: any, iv: any, field: any) {
    return arr
      .filter((d: any) => {
        return d.IV === iv;
      })
      .map((d: any) => {
        return [d.Time, d[field]];
      });
  }
  getFieldData(arr: any, iv: any, field: any) {
    return arr
      .filter((d: any) => {
        return d.IV === iv;
      })
      .map((d: any) => {
        return d[field];
      });
  }
}
