import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, forkJoin, Subscription} from 'rxjs';
import {ChartMapperService, VariantDisplay} from '../../activity/chart-mapper/chart-mapper.service';
import {ActivatedRoute, Params} from '@angular/router';
import {filter, first} from 'rxjs/operators';
import {Subvariant} from '../../activity/legend/legend.component';
import {HttpClient} from '@angular/common/http';
import * as d3 from 'd3';
import {InternMap} from 'd3';
import {StackedVariantsService} from './stacked-variants.service';
// import {ActivityPointsService} from './activity-points.service';
import {ActivityChartService} from '../../activity/activity-chart/activity-chart.service';
import {DisplayLineage} from '../../../models/view-models/display-lineage';
import {DisplayChartPoint} from '../../../models/view-models/display-chart-point';
import {FilterGroup} from '../../../models/view-models/filter-group';
import {ActivityFilters} from '../../../models/view-models/activity-filters';
import {Header} from '../../../models/view-models/header';
import * as Vals from '../../../constants/ui-constants';
import {VariantFilterService} from '../../activity/activity-chart/variant-filter.service';
import {TherapeuticGroup, TherapeuticItem} from '../../../models/dtos/therapeutics/therapeutic-group';
import {ActivityPointApiService} from '../../../services/api/activity-point-api/activity-point-api.service';
import {AssayApiService} from '../../../services/api/assay-api/assay-api.service';
import {LineageApiService} from '../../../services/api/lineage-api/lineage-api.service';
import {TherapeuticApiService} from '../../../services/api/therapeutic-api/therapeutic-api.service';

@Component({
  selector: 'app-time-points-chart',
  templateUrl: './time-points-chart.component.html',
  styleUrls: ['./time-points-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePointsChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', {static: true}) chart!: ElementRef;
  @ViewChild('tChart', {static: true}) tChart!: ElementRef;
  @ViewChild('oChart', {static: true}) oChart!: ElementRef;
  @ViewChild('tree', {static: true}) tree!: ElementRef;
  public therapeuticMap!: Map<string, TherapeuticItem>;
  public displayLineages!: DisplayLineage[];
  public activeLineage!: DisplayLineage;
  public displayPoints!: DisplayChartPoint[];
  public selectedDataPoint!: BehaviorSubject<DisplayChartPoint | null>;
  public selectedVariant!: BehaviorSubject<string | null>;
  public neighbors!: BehaviorSubject<DisplayChartPoint[]>;
  public mutations!: string[];
  public activeMutation!: string;
  public inactiveMode = 'gray';
  public error = false;
  public dataLoading = false;
  public lineageLoading = false;
  public assayDefinitions!: Map<string, string>;
  public filterGroups: FilterGroup[] = Vals.defaultFilterGroups;
  public filterState!: ActivityFilters;
  public classification!: VariantDisplay;
  public today!: Date;
  public legendVariant!: string;
  public colorByLineage!: boolean;
  public lastTherapeuticFilterGroup: string = Vals.defaultDrugs;
  public isSubLegend = false;
  public selectedSubvariant: Subvariant | null = null;
  public subvariants!: Subvariant[];
  private therapeuticGroups!: Header[];

  private dateMap!: InternMap;
  private orderedDates!: [string, VariantShare[]][];
  public selectedDate$: BehaviorSubject<string>;
  public selectedDate!: string;
  public selecteDateObj!: Date;
  public sliceVariants!: VariantShare[];
  public subvaraintColors = new Map<string, string>();
  private dateSub: Subscription;
  public activityPointsLoading = true;
  public stackedPageFromLast = 0;
  public weekOffset = 0;
  public stackedService = new StackedVariantsService();
  public overviewStacked = new StackedVariantsService();
  public pointCount = 0;
  constructor(
    private activityPointApi: ActivityPointApiService,
    private assayApi: AssayApiService,
    private lineageApi: LineageApiService,
    private therapeuticApi: TherapeuticApiService,
    private activityChartUI: ActivityChartService, // ActivityPointsService,
    private filters: VariantFilterService,
    private chartMapper: ChartMapperService,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) {
    this.selectedDataPoint = new BehaviorSubject<DisplayChartPoint | null>(null);
    this.selectedVariant = new BehaviorSubject<string | null>(null);
    this.neighbors = new BehaviorSubject<DisplayChartPoint[]>([]);
    this.selectedDate$ = new BehaviorSubject<string>('');
    this.dateSub = this.selectedDate$.pipe(filter((x) => x !== undefined)).subscribe((x) => {
      if (x !== '') {
        this.selectedDate = x;
        this.selecteDateObj = new Date(x);
        const variants = this.dateMap.get(x);
        this.sliceVariants = variants.sort((a: any, b: any) => b.share - a.share);
        this.legendVariant = '';
        this.refreshChart();
      }
    });
    this.overviewStacked.init(this.selectedDataPoint, this.selectedVariant, this.neighbors);
  }

  public ngOnInit(): void {
    this.filterState = {
      filterGroups: [],
      filterBehavior: 'gray',
      dateReportedRange: null,
      selectedMutations: [],
      lastUpdatedFrame: null,
      referenceMode: Vals.ancestralMode
    };
    const assayDefs$ = this.assayApi.getAssays();
    const lineages$ = this.lineageApi.getLineages();
    const drugGroups$ = this.therapeuticApi.getTherapeuticGroupMetadata();
    const queryParams$ = this.route.queryParams;

    const circulatingVariants$ = this.httpClient.get<any[]>('./assets/data/jr58-6ysp.json');
    this.error = false;
    this.dataLoading = true;
    forkJoin([circulatingVariants$, lineages$, assayDefs$, drugGroups$, queryParams$.pipe(first())]).subscribe(
      ([circulatingVariantsTxt, lineages, assayDefs, drugGroups, queryParams]) => {
        this.processSocrata(circulatingVariantsTxt);
        this.overviewStacked.renderChart(
          this.orderedDates,
          this.oChart.nativeElement,
          this.selectedDate$,
          this.subvariants,
          this.stackedPageFromLast,
          0,
          true,
          true
        );
        this.dataLoading = false;
        this.assayDefinitions = new Map(assayDefs.map((x) => [x.category, x.id]));
        const flatDrugs = drugGroups.data.reduce(
          (acc: TherapeuticItem[], b: TherapeuticGroup) => [...acc, ...b.drugs],
          []
        ) as TherapeuticItem[];
        this.therapeuticMap = new Map<string, TherapeuticItem>(flatDrugs.map((d) => [d.drugName, d]));
        this.therapeuticGroups = this.chartMapper.mapToTherapeuticGroups(drugGroups.data);
        const defaultTherapeutics = this.filters.filterTherapeutics(Vals.defaultDrugs, this.therapeuticGroups, null);
        this.displayLineages = this.chartMapper.getDisplayLineages(lineages);
        this.activityChartUI.initSubscriptions(this.selectedDataPoint, this.neighbors);
        this.activityChartUI.initChart(this.chart, defaultTherapeutics!);
        const isInitialLoad = true;
        this.loadLineage(this.displayLineages[0], isInitialLoad);
      },
      (error) => (this.error = true)
    );
  }
  private processSocrata(items: any[]): VariantShare[] {
    const shares: VariantShare[] = [];
    items.forEach((item) => {
      const share: VariantShare = {
        variant: item.variant,
        weekEnding: item.week_ending.split('T')[0],
        share: item.share,
        publishedDate: item.creation_date.split('T')[0]
      };
      shares.push(share);
    });

    this.dateMap = d3.group(shares, (d) => d.weekEnding);
    this.orderedDates = Array.from(this.dateMap).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());

    // for each weekEnding, get only the variants with the latest publishedDate
    const weeksByLatest = this.orderedDates.map(([key, weekVariants]) => {
      weekVariants.sort((a, b) => new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime());
      const latestPublished = weekVariants[0].publishedDate;
      return [key, weekVariants.filter((x) => x.publishedDate === latestPublished)];
    });
    // Add in the combined variants to the week's variants for the legend
    weeksByLatest.forEach(([key, weekVariants]) => {
      const variantsShown = (weekVariants as VariantShare[]).map((x) => x.variant);
      if (variantsShown.includes('BA.4') || variantsShown.includes('BA.5')) {
        const varaintShare: VariantShare = {
          variant: 'BA.4/5',
          weekEnding: (weekVariants[0] as VariantShare).weekEnding,
          share: 0,
          publishedDate: (weekVariants[0] as VariantShare).publishedDate
        };
        (weekVariants as VariantShare[]).push(varaintShare);
      }
      if (variantsShown.includes('B.1.427') || variantsShown.includes('B.1.429')) {
        const varaintShare: VariantShare = {
          variant: 'B.1.427/429',
          weekEnding: (weekVariants[0] as VariantShare).weekEnding,
          share: 0,
          publishedDate: (weekVariants[0] as VariantShare).publishedDate
        };
        (weekVariants as VariantShare[]).push(varaintShare);
      }

      this.dateMap.set(key, weekVariants);
    });
    this.orderedDates = Array.from(this.dateMap).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());

    this.selectedDate$.next(this.orderedDates[this.orderedDates.length - 1][0]);

    // @ts-ignore
    const flatVariants = Array.from(this.orderedDates).reduce((acc, b) => [...acc, ...b[1]], []) as VariantShare[];
    const subVariantNames = [...new Set(flatVariants.map((x) => x.variant))];
    this.subvariants = this.getSubvariantsByName(subVariantNames);
    this.subvaraintColors = new Map<string, string>(this.subvariants.map((x) => [x.sublineage, x.color]));

    return shares;
  }

  public getSubvariantsByName(names: string[]): Subvariant[] {
    return names.map((l, i) => {
      i = i % Vals.variantColors.length;
      const color = Vals.variantColors[i];
      return {sublineage: l, color, lineage: ''};
    });
  }

  public ngOnDestroy() {
    this.dateSub?.unsubscribe();
    this.activityChartUI.destroy();
  }
  private resetPageState(lineage: DisplayLineage) {
    this.activeLineage = lineage;
    this.selectedDataPoint.next(null);
    this.neighbors.next([]);
  }

  public loadLineage(lineage: DisplayLineage, isInitialLoad: boolean = false): void {
    this.resetPageState(lineage);
    this.lineageLoading = true;

    this.activityPointsLoading = false;
    this.lineageLoading = false;

    this.lastTherapeuticFilterGroup = Vals.withData;
    this.isSubLegend = true;
    this.refreshChart();
  }

  private filterByLengendSubvariant(points: DisplayChartPoint[], variant: string) {
    const sliceVariants = new Map(this.sliceVariants.map((x) => [x.variant, true]));
    if (points && points.length > 0) {
      points.forEach((x) => {
        if (!variant) {
          x.toggleHidden = false;
          x.colorOverride = null;
        } else if (x.viralSublineage !== variant && x.viralName !== variant) {
          x.colorOverride = Vals.fadeColor;
        }
      });
    }
  }

  private refreshChart() {
    // We are always going to be coloring by lineage
    this.colorByLineage = true;

    if (this.sliceVariants) {
      const variantsShown = [...new Set(this.sliceVariants.map((x) => x.variant))];

      //Need to get activity points type and map from graphql so I can pass to functions that process
      if (variantsShown.includes('BA.4') || variantsShown.includes('BA.5')) {
        variantsShown.push('BA.4/5');
      }
      if (variantsShown.includes('B.1.427') || variantsShown.includes('B.1.429')) {
        variantsShown.push('B.1.427/429');
      }
      this.lineageLoading = true;
      this.activityPointApi.getActivityChartPointsBySubLineage(variantsShown, null, null, null).subscribe((points) => {
        this.pointCount = points.length;

        const filteredPoints = this.chartMapper.getDisplayPoints(points);

        this.filterByLengendSubvariant(filteredPoints, this.legendVariant);

        const therapeuticGroups = this.filters.filterTherapeutics(
          Vals.defaultDrugs,
          this.therapeuticGroups,
          filteredPoints
        );
        this.activityChartUI.initChart(this.chart, therapeuticGroups!);
        this.colorPointsBySubvariant(filteredPoints, this.subvariants);

        this.activityChartUI.drawChart(
          filteredPoints,
          this.activeLineage.viralLineage!,
          this.filterState.filterBehavior,
          this.colorByLineage
        );
        this.lineageLoading = false;
      });
    }
  }

  // check lineage and sublineage
  // other is a percentage from the socrata and isn't actually looked up from the backend
  // so clicking on other will not show any points
  public colorPointsBySubvariant(points: DisplayChartPoint[], subVariants: Subvariant[]) {
    const colorMap = new Map<string, string>(subVariants.map((x) => [x.sublineage, x.color]));
    points.forEach((p, i) => {
      // color by viralName: sublineage then by viralSublineage: sublineage group
      p.variantColor =
        colorMap.get(p.viralName) ?? colorMap.get(p.viralSublineage) ?? colorMap.get(p.viralLineage) ?? '';
    });
  }
  public legendVariantClicked(variant: string) {
    this.legendVariant = variant;

    this.refreshChart();
  }
  public setSubvariant(subvariant: Subvariant) {
    this.selectedSubvariant = subvariant;
    this.refreshChart();
  }
  public selectVariant(variant: string) {
    if (this.legendVariant === variant) {
      this.legendVariant = '';
    } else {
      this.legendVariant = variant;
    }

    this.refreshChart();
  }
}

export interface VariantShare {
  variant: string;
  weekEnding: string;
  share: number;
  publishedDate: string;
}
