import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-heterologous-booster-grid',
  templateUrl: './heterologous-booster-grid.component.html',
  styleUrls: ['./heterologous-booster-grid.component.scss']
})
export class HeterologousBoosterGridComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic Heterologous Variant Studies');
  }
}
