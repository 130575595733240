<div *ngIf="!dataLoading; else nodata">
  <button class="btn btn-outline-primary show-colors-btn density" (click)="toggleColors()">
    {{ showColors ? 'Hide Data Density' : 'Show Data Density' }}
  </button>
</div>
<div class="chart" [hidden]="dataLoading" #chart></div>
<div class="col-12 my-4 d-flex justify-content-center">
  <ng-template #nodata>
    <div *ngIf="dataLoading">
      <app-loader></app-loader>
    </div>
    <div *ngIf="!dataLoading">
      <ngb-alert>No Variant Found.</ngb-alert>
    </div>
  </ng-template>
</div>
