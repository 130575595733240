import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {SelectedRWEFilters} from '../rwe-filters/rwe-filters.component';
import {RWEReportTooltipComponent} from '../rwe-report-tooltip/rwe-report-tooltip.component';
import {RWEFilterService} from '../rwe-filter/rwe-filter.service';
import {RWEPoint} from '../../../models/dtos/rwe/rwe';
import {RWEApiService} from '../../../services/api/rwe-api/rwe-api.service';

@Component({
  selector: 'app-rwe-data',
  templateUrl: './rwe-data.component.html',
  styleUrls: ['./rwe-data.component.scss']
})
export class RWEDataComponent implements OnInit {
  public allRWEPoints!: RWEPoint[];
  public selectedRWEPoints!: RWEPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;

  constructor(private variantApi: RWEApiService, private RWEFilters: RWEFilterService) {
    this.frameworkComponents = {
      customTooltip: RWEReportTooltipComponent,
      datasourceRenderer: DatasourceRendererComponent
    };
    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };

    this.columnDefs = [
      {
        field: 'dataTitle',
        headerName: 'Title',
        width: 300,
        sortable: true,
        resizable: true,
        tooltipField: 'dataTitle',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataDate',
        headerName: 'Publication Date',
        width: 150,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'treatmentsCompiledEdited',
        headerName: 'Treatment (n)',
        cellStyle: {'text-align': 'left'},
        tooltipField: 'treatmentsCompiledEdited',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'studyStart',
        headerName: 'Study Start',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'studyEnd',
        headerName: 'Study End',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'abbreviatedSummary',
        headerName: 'Summary',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'abbreviatedSummary',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'lineage',
        headerName: 'Viral Lineage',
        tooltipField: 'lineage',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'hospitalizationEndpoint',
        headerName: 'Hospitalization Endpoint',
        width: 150,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'mortalityEndpoint',
        headerName: 'Mortality Endpoint',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'otherEndpoint',
        headerName: 'Other Endpoint',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'hospitalizationMetricUsed',
        headerName: 'Hospitalization Metric',
        width: 150,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'hospitalizationMetricUsed',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'hospitalizationEndptMetricPvalue',
        headerName: 'Hospitalization Outcome (value (95% CI); p-value)',
        width: 220,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'hospitalizationEndptMetricPvalue',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'mortalityMetricUsed',
        headerName: 'Mortality Metric',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'mortalityMetricUsed',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'mortalityEndptMetricPvalue',
        headerName: 'Mortality Outcome (value (95% CI); p-value)',
        width: 220,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'mortalityEndptMetricPvalue',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'otherDefined',
        headerName: 'Other Defined',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'otherDefined',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'otherMetricUsed',
        headerName: 'Other Metric',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'otherMetricUsed',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'otherEndpointMetricPvalue',
        headerName: 'Other Outcome (value (95% CI); p-value)',
        width: 220,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'otherEndpointMetricPvalue',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'cohortUniqueness',
        headerName: 'Cohort Type',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'cohortUniqueness',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'DOI',
        width: 100,
        cellRenderer: 'datasourceRenderer'
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public markChecked = (params: any) => {
    return params.value === 'checked' || params.value === 'yes' ? '<i class="fas fa-check"></i>' : params.value;
  };

  public getTestStrainValue = (params: any) => {
    return params.data.animalModelsLinkedPage
      ? `<a target="_blank" href="${params.data.animalModelsLinkedPage}">${params.value}</a>`
      : params.value;
  };

  public ngOnInit(): void {
    const RWEDataPoints$ = this.variantApi.getRWE();

    forkJoin([RWEDataPoints$]).subscribe(([RWEDataPoints]) => {
      this.allRWEPoints = RWEDataPoints;
      this.selectedRWEPoints = RWEDataPoints;
      this.rowCount = this.selectedRWEPoints.length;
      this.totalRows = this.allRWEPoints.length;
      RWEDataPoints.sort((a, b) => Date.parse(a.studyStart) - Date.parse(b.studyStart));
    });
  }

  public filterTable(filters: SelectedRWEFilters) {
    this.selectedRWEPoints = this.RWEFilters.filterPoints(this.allRWEPoints, filters);
    this.rowCount = this.selectedRWEPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}
