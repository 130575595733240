import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SummaryComponent} from './summary.component';
import {HeatmapComponent} from './heatmap/heatmap.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@odp/shared';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../pipes/core.module';

@NgModule({
  declarations: [SummaryComponent, HeatmapComponent],
  imports: [CommonModule, RouterModule, CoreModule, SharedModule, NgbModule],
  exports: [SummaryComponent]
})
export class SummaryModule {}
