import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DatasetMetadata} from '../../../models/dtos/variant/dataset-metadata';
import {API_URLS, Endpoints} from '@odp/shared';
@Injectable({
  providedIn: 'root'
})
export class DatasetMetadataApiService {
  private variantBaseUrl!: string;
  constructor(private httpClient: HttpClient, @Inject(API_URLS) configuration: Endpoints) {
    // pointing to the NEW covid19ApiUrl REST instead of variantApiUrl
    this.variantBaseUrl = configuration.covid19ApiUrl;
  }

  public getVariantDatasetMeta(): Observable<{data: DatasetMetadata}> {
    return this.httpClient.get<{data: DatasetMetadata}>(`${this.variantBaseUrl}/route/variant/dataset/meta`);
  }

  public getRecordCount(): Observable<{data: {count: string}}> {
    return this.httpClient.get<{data: {count: string}}>(`${this.variantBaseUrl}/route/variant/datapoint/meta`);
  }
}
