import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@odp/shared';
import {DatasetsComponent} from './datasets.component';
import {FormsModule} from '@angular/forms';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {RouterModule} from '@angular/router';
import {DataviewerComponent} from './dataviewer/dataviewer.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [DatasetsComponent, DataviewerComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ClipboardModule,
    RouterModule,
    MatPaginatorModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [],
  bootstrap: [DatasetsComponent]
})
export class DatasetsModule {}
