<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container" id="about_container">
  <div class="col-12 p-0 pt-3">
    <p><a [routerLink]="['/covid19/']">Home </a> > Resources > About</p>
  </div>
  <div class="row">
    <div class="col-md-12"><h1>About NCATS OpenData COVID-19</h1></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      The NCATS OpenData COVID-19 portal is a resource designed to rapidly and openly share SARS-CoV-2 screening data
      and assay information with the global health community. NCATS scientists have been working to develop a number of
      SARS-CoV-2-related assays to measure over 10,000 compounds - including the
      <a href="https://ncats.nih.gov/expertise/preclinical/npc" target="_blank">NCATS Pharmaceutical Collection</a> of
      nearly 3,000 approved drugs - for their activity against the virus.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <br />These data include all positive and negative results from a diverse panel of repurposing screens, and can be
      viewed, sorted, searched and exported directly from the portal website. Screening data are uploaded to the website
      as soon as they become available.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <br />This resource aims to reimagine the early translation process by sharing screening data immediately and
      without restrictions. We hope to enable a variety of drug repurposing activities and allow researchers to
      formulate and test hypotheses, prioritize research opportunities and speed the search for effective therapies
      against the virus and the disease it causes.
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12"><h2>Acknowledgements</h2></div>
  </div>

  <div class="row">
    <div class="col-md-12">
      This research was supported by the National Center for Advancing Translational Sciences (NCATS) Intramural
      Research Program of the NIH.
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12"><h2>Citing this work</h2></div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div>
        Brimacombe KR, Zhao T, Eastman RT, Hu X, Wang K, Backus M, Baljinnyam B, Chen CZ, Chen L, Eicher T, Ferrer M, Fu
        Y, Gorshkov K, Guo H, Hanson QM, Itkin Z, Kales SC, Klumpp-Thomas C, Lee EM, Michael S, Mierzwa T, Patt A,
        Pradhan M, Renn A, Shinn P, Shrimp JH, Viraktamath A, Wilson KM, Xu M, Zakharov AV, Zhu W, Zheng W, Simeonov A,
        Mathé EA, Lo DC, Hall MD, Shen M.
      </div>
      <br />
      <div>
        <strong>An OpenData portal to share COVID-19 drug repurposing data in real time</strong>
      </div>
      <div>
        <!-- <app-external-link
          [url]="'https://www.biorxiv.org/content/10.1101/2020.06.04.135046v1'"
          [text]="'https://www.biorxiv.org/content/10.1101/2020.06.04.135046v1'"
        ></app-external-link> -->
      </div>
    </div>
  </div>
</div>

<!-- <app-footer-view [showCC]="true"></app-footer-view> -->
