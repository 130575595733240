import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TherapeuticGroup} from '../../../models/dtos/therapeutics/therapeutic-group';
import {API_URLS, Endpoints} from '@odp/shared';

@Injectable({
  providedIn: 'root'
})
export class TherapeuticApiService {
  private variantBaseUrl!: string;
  constructor(private httpClient: HttpClient, @Inject(API_URLS) configuration: Endpoints) {
    this.variantBaseUrl = configuration.covid19ApiUrl;
  }

  public getTherapeuticGroupMetadata(): Observable<{
    data: TherapeuticGroup[];
  }> {
    return this.httpClient.get<{data: TherapeuticGroup[]}>(`${this.variantBaseUrl}/route/variant/drug/displaymetadata`);
  }
}
