import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-trace-layout',
  templateUrl: './trace-layout.component.html',
  styleUrls: ['./trace-layout.component.scss']
})
export class TraceLayoutComponent {
  constructor() {}
}
