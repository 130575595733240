import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {SelectedVivoFilters} from '../vivo-filters/vivo-filters.component';
import {VivoReportTooltipComponent} from '../vivo-report-tooltip/vivo-report-tooltip.component';
import {VivoReportTooltipValueComponent} from '../vivo-report-tooltip/vivo-report-tooltip-value.component';
import {VivoFilterService} from '../vivo-filter/vivo-filter.service';
import {InVivoApiFilters} from '../../../models/dtos/in-vivo/invivo-filters';
import {InVivoPoint} from '../../../models/dtos/in-vivo/invivo';
import {InVivoApiService} from '../../../services/api/in-vivo-api/in-vivo-api.service';
@Component({
  selector: 'app-vivo-data',
  templateUrl: './vivo-data.component.html',
  styleUrls: ['./vivo-data.component.scss']
})
export class VivoDataComponent implements OnInit {
  public filters!: InVivoApiFilters;
  public allInVivoPoints!: InVivoPoint[];
  public selectedInVivoPoints!: InVivoPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;
  public showError = false;

  constructor(private variantApi: InVivoApiService, private vivoFilters: VivoFilterService) {
    this.frameworkComponents = {
      customTooltip: VivoReportTooltipComponent,
      customValueTooltip: VivoReportTooltipValueComponent,
      datasourceRenderer: DatasourceRendererComponent
    };
    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };
    this.columnDefs = [
      {
        field: 'reportNumber',
        headerName: 'Report Number',
        width: 105,
        sortable: true,
        resizable: true,
        tooltipField: 'model',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'Data Source',
        cellRenderer: 'datasourceRenderer'
      },
      {
        field: 'model',
        headerName: 'Test Species',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'right'}
      },
      {
        field: 'modelStrain',
        headerName: 'Test Strain',
        cellRenderer: this.getTestStrainValue,
        tooltipField: 'modelStrain',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'variants',
        headerName: 'Viral Lineage',
        tooltipField: 'whoDesignation'
      },
      {
        field: 'therapeuticAgents',
        headerName: 'Therapeutic Agents',
        tooltipField: 'therapeuticAgents',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'therapeuticClasses',
        headerName: 'Therapeutic Class',
        tooltipField: 'therapeuticClasses',
        tooltipComponent: 'customValueTooltip'
      },
      {field: 'studyType', headerName: 'Study Type'},
      {
        field: 'clinicalManifestastions',
        headerName: 'Clinical Manifestations',
        width: 135,
        cellRenderer: this.markChecked
      },
      {
        field: 'viralLoad',
        headerName: 'Viral Load',
        width: 100,
        cellRenderer: this.markChecked
      },
      {
        field: 'histopathology',
        headerName: 'Histopathology',
        width: 150,
        cellRenderer: this.markChecked
      },
      {
        field: 'neutralization',
        headerName: 'Neutralization',
        width: 140,
        cellRenderer: this.markChecked
      },
      {
        field: 'antibodyResponse',
        headerName: 'Antibody Responses',
        width: 125,
        cellRenderer: this.markChecked
      },
      {
        field: 'transmission',
        headerName: 'Transmission',
        width: 135,
        cellRenderer: this.markChecked
      },
      {
        field: 'rechallenge',
        headerName: 'Rechallenge',
        width: 125,
        cellRenderer: this.markChecked
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public markChecked = (params: any) => {
    return params.value === 'checked' || params.value === 'yes' ? '<i class="fas fa-check"></i>' : params.value;
  };

  public getExternalLinks(
    route: string,

    pageLink: string
  ): string {
    const smallAnimalId = pageLink.split('=')[1];
    if (!smallAnimalId) {
      return '';
    }
    const currentUrl = window.location.href as string;
    const parts = currentUrl.split('/');
    const datasetParts = [...parts];
    if (pageLink.indexOf('nhp') === -1) {
      datasetParts.splice(
        datasetParts.indexOf(route),
        datasetParts.length - datasetParts.indexOf(route),
        `animal-models/small-animals/${smallAnimalId}`
      );
    } else {
      datasetParts.splice(
        datasetParts.indexOf(route),
        datasetParts.length - datasetParts.indexOf(route),
        `animal-models/nhp/${smallAnimalId}`
      );
    }
    const smallAnimals = datasetParts.join('/');
    return smallAnimals;
  }

  public getTestStrainValue = (params: any) => {
    let link = null;
    if (params.data.animalModelsLinkedPage) {
      link = this.getExternalLinks('variant', params.data.animalModelsLinkedPage);
    }
    return link ? `<a target="_blank" href="${link}">${params.value}</a>` : params.value;
  };

  public ngOnInit(): void {
    const inVivoDataPoints$ = this.variantApi.getInVivoPoints();

    forkJoin([inVivoDataPoints$]).subscribe(
      ([inVivoDataPoints]) => {
        this.allInVivoPoints = inVivoDataPoints;
        this.selectedInVivoPoints = inVivoDataPoints;
        this.rowCount = this.selectedInVivoPoints.length;
        this.totalRows = this.allInVivoPoints.length;
      },
      (error) => {
        this.showError = true;
      }
    );
  }

  public filterTable(filters: SelectedVivoFilters) {
    this.selectedInVivoPoints = this.vivoFilters.filterPoints(this.allInVivoPoints, filters);
    this.rowCount = this.selectedInVivoPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}
