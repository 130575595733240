import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@odp/shared';
import {RouterModule} from '@angular/router';
import {TherapeuticGlossaryComponent} from './therapeutic-glossary.component';
import {SingleTherapeuticGlossaryComponent} from '../therapeutic-glossary/single-theraputic-glossary/single-therapeutic-glossary.component';
import {D3HorizontalChartComponent} from './approved-status/d3-horizontal-chart.component';
import {D3PieComponent} from './class-pie-chart/d3-pie.component';
import {D3HorizontalChartToptenComponent} from './topten-chart/d3-horizontal-chart-topten.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataviewerComponent} from './single-theraputic-glossary/dataviewer/dataviewer.component';
@NgModule({
  declarations: [
    TherapeuticGlossaryComponent,
    SingleTherapeuticGlossaryComponent,
    D3HorizontalChartComponent,
    D3HorizontalChartToptenComponent,
    D3PieComponent,
    DataviewerComponent
  ],
  imports: [CommonModule, SharedModule, FormsModule, RouterModule, ReactiveFormsModule]
})
export class ThrapeuticGlosseryModule {}
