import {Injectable} from '@angular/core';
import {InVivoPoint} from '../../../models/dtos/in-vivo/invivo';
import {SelectedVivoFilters} from '../vivo-filters/vivo-filters.component';

@Injectable({
  providedIn: 'root'
})
export class VivoFilterService {
  constructor() {}

  public filterPoints(allInVivoPoints: InVivoPoint[], filters: SelectedVivoFilters): InVivoPoint[] {
    const result = [];
    const colfilters = filters.cols;
    for (const record of allInVivoPoints) {
      if (
        this.searchInFields(colfilters.get('Test Species') ?? [], record.model) &&
        this.searchInFields(colfilters.get('Viral Lineage') ?? [], record.variants) &&
        this.searchInFields(colfilters.get('Therapeutic Name') ?? [], record.therapeuticAgents) &&
        this.searchInFields(colfilters.get('Therapeutic Class') ?? [], record.therapeuticClasses) &&
        (this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Antibody Response', record.antibodyResponse) ||
          this.searchForChecked(
            colfilters.get('Study Measures') ?? [],
            'Clinical Manifestations',
            record.clinicalManifestastions
          ) ||
          this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Histopathology', record.histopathology) ||
          this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Neutralization', record.neutralization) ||
          this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Viral Load', record.viralLoad) ||
          this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Transmission', record.transmission) ||
          this.searchForChecked(colfilters.get('Study Measures') ?? [], 'Rechallenge', record.rechallenge)) &&
        this.searchInFields(colfilters.get('Study Type') ?? [], record.studyType) &&
        this.searchDateRange(filters.dateRange, record.reportedDate)
      ) {
        result.push(record);
      }
    }
    return result;
  }

  private searchForChecked(data: string[], colName: string, entryValue: string): boolean {
    if (data.length === 0) {
      return true;
    }
    if (data.indexOf(colName) > -1) {
      return !!entryValue;
    }
    return false;
  }

  private searchDateRange(dateRange: any, reportDate: string) {
    if (dateRange) {
      if (!dateRange.startDate) {
        return true;
      }
      if (
        Date.parse(reportDate) >= Date.parse(dateRange.startDate) &&
        Date.parse(reportDate) <= Date.parse(dateRange.endDate)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  private searchInFields(data: string[], record: string) {
    if (!data.length) {
      return true;
    }
    let found = false;
    for (const d of data) {
      if (d.trim() === record.trim()) {
        found = true;
      }
      if (record.indexOf(';') > -1) {
        const entries = record.split(';');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
      if (record.indexOf(',') > -1) {
        const entries = record.split(',');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
    }
    return found;
  }
}
