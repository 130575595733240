import {Component, OnInit} from '@angular/core';
import {ITooltipAngularComp} from 'ag-grid-angular';
import {ITooltipParams} from 'ag-grid-community';

@Component({
  selector: 'app-vivo-tooltip-component',
  template: `
    <div class="custom-tooltip" [style.background-color]="color">
      <p>
        <b>Title:</b> <span> {{ data.title }} </span>
      </p>
      <p>
        <span>Reported Date: {{ data.reportedDate }}</span>
      </p>
    </div>
  `,
  styles: [
    `
      :host {
        position: absolute;
        width: 150px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }
      .custom-tooltip {
        width: fit-content;
        max-width: fit-content;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `
  ]
})
export class VivoReportTooltipComponent implements ITooltipAngularComp {
  private params!: {color: string} & ITooltipParams;
  public data: any;
  public color!: string;

  agInit(params: {color: string} & ITooltipParams): void {
    this.params = params;
    // @ts-ignore
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.color = this.params.color || 'white';
  }
}
