import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@odp/shared';
import {RelatedResourcesComponent} from './related-resources.component';

@NgModule({
  declarations: [RelatedResourcesComponent],
  imports: [CommonModule, SharedModule]
})
export class ResourcesModule {}
