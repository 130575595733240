<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container" style="padding-left: 0; padding-right: 0" [hidden]="dataLoading">
  <div class="row py-3 px-3">
    <div class="col-12 p-0 py-3">
      <p class="m-0">
        <a [routerLink]="['/covid19']">Home </a> > <a [routerLink]="['/covid19/databrowser']">DataBrowser</a> >
        <span *ngIf="drug?.SAMPLE_NAME; else NA"> {{ drug!.SAMPLE_NAME }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="h3 inline-block compound-name">
        <span *ngIf="drug?.SAMPLE_NAME; else NA"> {{ drug!.cleaSAMPLE_NAME }}</span>
      </div>
      <div id="compound_name_sub">
        Primary MOA <span *ngIf="drug?.PRIMARY_MOA; else NA"> {{ drug!.PRIMARY_MOA }}</span>
      </div>
    </div>
  </div>
  <hr class="mb-0" />
  <div class="container nav-container">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        #rla1="routerLinkActive"
        routerLinkActive
        [active]="rla1.isActive"
        [routerLink]="['./background']"
      >
        Background
      </a>
      <a
        mat-tab-link
        #rla2="routerLinkActive"
        routerLinkActive
        [active]="rla2.isActive"
        [routerLink]="['./single-agent']"
      >
        Single Agent Screening Data
      </a>
    </nav>
    <hr class="mt-0" />
    <div class="summary-page">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #NA>
  <span> N/A</span>
</ng-template>

<ng-container *ngIf="dataLoading">
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
</ng-container>
<app-footer-view [showCC]="true"></app-footer-view>
