import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ActivityChartPoint} from '../../../models/dtos/activity/activity-chart';
import {ActivityChartPointQL} from '../../../queries/activity-chart-query';
import {VariantMapperService} from '../../mappers/variant-mapper/variant-mapper.service';
import {Apollo} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {InMemoryCache} from '@apollo/client/core';
import {API_URLS, Endpoints} from '@odp/shared';
import {ActivityCirculatingVaraintsQL} from '../../../queries/activity-circulating-variant';
import {ActivityCirculatingVarQuery} from '../../../models/dtos/cirulating-variants/activity-circulating-point';

@Injectable({
  providedIn: 'root'
})
export class ActivityPointApiService {
  constructor(
    private activityChartPointQL: ActivityChartPointQL,
    private activityCirculatingVaraintsQL: ActivityCirculatingVaraintsQL,
    private variantMapper: VariantMapperService,
    private apollo: Apollo,
    @Inject(API_URLS) private configuration: Endpoints,
    httpLink: HttpLink
  ) {
    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }
  public getActivityChartPoints(viralLineage: string, onlyLatest: boolean): Observable<ActivityChartPoint[]> {
    let query = 'drugActivity1NumericFold=isnull=false';
    if (viralLineage) {
      query = `viralLineage=='${viralLineage}' and ${query}`;
    }
    if (onlyLatest) {
      const today = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
      query = `${query} and dataUpdatedDate>=${today}`;
    }
    return this.activityChartPointQL
      .fetch({keyFilter: query})
      .pipe(map((result) => this.variantMapper.mapToActivityChartPoints(result.data.variant3.edges)));
  }
  public getActivityChartPointsBySubLineage(
    cdcVariant: string[] | null,
    subLineages: string[] | null,
    viralLineage: string[] | null,
    dataUpdatedDate: Date | null
  ): Observable<ActivityChartPoint[]> {
    const dataUpdated = dataUpdatedDate?.toLocaleDateString('en-US');
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<ActivityCirculatingVarQuery>({
        query: this.activityCirculatingVaraintsQL.document,
        variables: {
          cdcVariantFilter: cdcVariant,
          subLineageFilter: subLineages,
          lineageFilter: viralLineage,
          dateFilter: dataUpdated
        }
      })

      .pipe(
        map((result) => {
          return result.data.variant3Sublineage.map((point) => {
            const nPoint: ActivityChartPoint = {
              viralLineage: point.viralLineage,
              viralType: point.viralType,
              drugName: point.drugName,
              dataSourceUrls: point.dataSourceUrls,
              drugActivity1NumericFold: point.drugActivity1NumericFold,
              viralProteinFullPartial: point.viralProteinFullPartial,
              dataTitle: point.dataTitle,
              viralAaMutation: point.viralAaMutation,
              drugClass: point.drugClass,
              dataSourceType: point.dataSourceType,
              assayType: point.assayType,
              dataUpdatedDate: point.dataUpdatedDate,
              drugSponsored: point.drugSponsored,
              dataDate: point.dataDate,
              viralLineageFullName: point.viralLineageFullName,
              reportNumber: point.reportNumber,
              drugRef: point.drugRef,
              viralSublineage: point.viralSublineage,
              viralName: point.viralName
            };
            return nPoint;
          });
        })
      );
  }
}
