<div class="data-container">
  <div class="filter-left">
    <app-rwe-filters (filtersChanged)="filterTable($event)"></app-rwe-filters>
  </div>
  <div class="col-9">
    <div class="total">{{ rowCount }} {{ rowCount === 1 ? ' entry found' : 'entries found' }}</div>
    <ag-grid-angular
      style="width: 1200px; height: 700px"
      class="ag-theme-alpine"
      [rowData]="selectedRWEPoints"
      [columnDefs]="columnDefs"
      [components]="frameworkComponents"
      [defaultColDef]="defaultColDef"
      [headerHeight]="48"
      (filterChanged)="filterChanged($event)"
      [tooltipShowDelay]="800"
    >
    </ag-grid-angular>
  </div>
</div>
