<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          <span>ACTIV TRACE</span> Prioritized Variant Testing
          <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span>
        </h3>
        <div class="pb-4">
          <div>
            NCATS and TRACE are generating therapeutic activity data on prioritized SARS-CoV-2 variants using
            standardized protocols and reference reagents. Browse and download these datasets below.
          </div>
        </div>

        <a
          href="{{ variantBaseUrl }}/route/trace/qhts/data/download"
          class="btn btn-primary bg-white download-all"
          *ngIf="!dataLoading"
        >
          Download <span>TRACE</span> variant data
        </a>
      </div>
    </div>
  </div>
</div>
