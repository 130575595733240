<app-fixed-header></app-fixed-header>
<app-fixed-menu></app-fixed-menu>
<div class="w100 content-area">
  <app-circulating-header></app-circulating-header>
  <div class="container activity-chart-container">
    <!-- <app-disclaimer></app-disclaimer> -->
    <app-time-points-chart></app-time-points-chart>
  </div>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
