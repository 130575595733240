import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {ChartMapperService, VariantDisplay} from '../chart-mapper/chart-mapper.service';
import {ActivatedRoute, Params} from '@angular/router';
import {catchError, filter, first, map, startWith, switchMap, tap} from 'rxjs/operators';
import {Subvariant} from '../legend/legend.component';
import {TherapeuticGroup, TherapeuticItem} from '../../../models/dtos/therapeutics/therapeutic-group';
import {DisplayLineage} from '../../../models/view-models/display-lineage';
import {DisplayChartPoint} from '../../../models/view-models/display-chart-point';
import {FilterGroup} from '../../../models/view-models/filter-group';
import * as Vals from '../../../constants/ui-constants';
import {ActivityFilters} from '../../../models/view-models/activity-filters';
import {Header} from '../../../models/view-models/header';
import {ActivityChartService} from './activity-chart.service';
import {BeeswarmChartService} from './beeswarm-chart.service';
import {VariantFilterService} from './variant-filter.service';
import {AssayApiService} from '../../../services/api/assay-api/assay-api.service';
import {LineageApiService} from '../../../services/api/lineage-api/lineage-api.service';
import {TherapeuticApiService} from '../../../services/api/therapeutic-api/therapeutic-api.service';
import {ActivityPointApiService} from '../../../services/api/activity-point-api/activity-point-api.service';
import {EventService, EVENT_SERVICE} from '@odp/shared';
import {InvitroZipApiService} from '../../../services/api/invitro-zip-api/invitro-zip-api.service';
import * as _ from 'lodash';
import {UntypedFormControl} from '@angular/forms';
import {Sublineage} from '../../../models/dtos/in-vitro/sublineage';

@Component({
  selector: 'app-activity-chart',
  templateUrl: './activity-chart.component.html',
  styleUrls: ['./activity-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart', {static: true}) chart!: ElementRef;
  @ViewChild('bChart', {static: true}) bChart!: ElementRef;
  public therapeuticMap!: Map<string, TherapeuticItem>;
  public displayLineages: DisplayLineage[] = [];
  public activeLineage!: DisplayLineage;
  public displayPoints!: DisplayChartPoint[];
  public selectedDataPoint: BehaviorSubject<DisplayChartPoint | null>;
  public neighbors: BehaviorSubject<DisplayChartPoint[]>;
  public mutations!: string[];
  public activeMutation!: string;
  public inactiveMode = 'gray';
  public error = false;
  public dataLoading = false;
  public lineageLoading = false;
  public assayDefinitions!: Map<string, string>;
  public filterGroups: FilterGroup[] = Vals.defaultFilterGroups;
  public filterState!: ActivityFilters;
  public initialFilterState!: ActivityFilters;
  public classification!: VariantDisplay;
  public today: Date;
  public legendVariant!: DisplayLineage | null;
  public colorByLineage!: boolean;
  public lastTherapeuticFilterGroup: string = Vals.defaultDrugs;
  public isSubLegend = false;
  public selectedSubvariant: Subvariant | null = null;
  public subvariants!: Subvariant[];
  private selectedTherapeuticSub!: Subscription;
  private therapeuticGroups!: Header[];
  private referenceLineages!: Map<string, string>;
  public selectedLineagesControl = new UntypedFormControl();
  public selectedSubLineagesControl = new UntypedFormControl();
  public selectedLineages: string[] = [];
  public selectedSubLineages: string[] = [];
  public filterByUpdatedDate: boolean = false;
  public subLineages: Sublineage[] = [];
  constructor(
    private assayApi: AssayApiService,
    private lineageApi: LineageApiService,
    private therapeuticApi: TherapeuticApiService,
    private activityPointApi: ActivityPointApiService,
    private activityChartUI: ActivityChartService,
    private beeswarmUI: BeeswarmChartService,
    private filters: VariantFilterService,
    private chartMapper: ChartMapperService,
    private route: ActivatedRoute,
    @Inject(EVENT_SERVICE) private eventService: EventService
  ) {
    this.selectedDataPoint = new BehaviorSubject<DisplayChartPoint | null>(null);
    this.neighbors = new BehaviorSubject<DisplayChartPoint[]>([]);
    this.today = new Date();
  }

  public ngOnInit(): void {
    this.initialFilterState = {
      filterGroups: [],
      filterBehavior: 'gray',
      dateReportedRange: null,
      selectedMutations: [],
      lastUpdatedFrame: null,
      referenceMode: Vals.ancestralMode
    };

    // from auto completes for the multi select
    this.filteredLineages = this.selectedLineagesControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterLin(value))
    );
    this.filteredSubLineages = this.selectedSubLineagesControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSub(value)),
      catchError((error) => {
        console.error('Error in filteredSubLineages observable', error);
        return of([]); // Return an empty array in case of error
      })
    );

    const assayDefs$ = this.assayApi.getAssays();
    const lineages$ = this.lineageApi.getLineages();
    const drugGroups$ = this.therapeuticApi.getTherapeuticGroupMetadata();
    const queryParams$ = this.route.queryParams;
    const sublineages$ = this.lineageApi.getSublineages();
    this.error = false;
    this.dataLoading = true;
    forkJoin([sublineages$, lineages$, assayDefs$, drugGroups$, queryParams$.pipe(first())]).subscribe(
      ([sublineages, lineages, assayDefs, drugGroups, queryParams]) => {
        this.subLineages = sublineages.filter((x) => !!x.name);
        this.dataLoading = false;
        this.assayDefinitions = new Map(assayDefs.map((x) => [x.category, x.id]));
        const flatDrugs = drugGroups.data.reduce(
          (acc: TherapeuticItem[], b: TherapeuticGroup) => [...acc, ...b.drugs],
          []
        ) as TherapeuticItem[];
        this.therapeuticMap = new Map<string, TherapeuticItem>(flatDrugs.map((d) => [d.drugName, d]));
        this.therapeuticGroups = this.chartMapper.mapToTherapeuticGroups(drugGroups.data);
        const defaultTherapeutics = this.filters.filterTherapeutics(Vals.defaultDrugs, this.therapeuticGroups, null);
        this.displayLineages = this.chartMapper.getDisplayLineages(lineages);
        const lineagesFiltered = lineages.filter((x) => !!x.viralLineage && !!x.viralClassification);
        this.referenceLineages = new Map<string, string>(
          lineagesFiltered.map((x) => [x.viralLineage ?? '', x.viralClassification ?? ''])
        );
        this.activityChartUI.initSubscriptions(this.selectedDataPoint, this.neighbors);
        this.activityChartUI.initChart(this.chart, defaultTherapeutics ?? []);
        this.beeswarmUI.initChart(this.bChart, this.selectedDataPoint, this.neighbors);
        const variantParam: string = this.route.snapshot.params['variant'];
        const variant = this.getRouteVariant(variantParam, this.displayLineages);
        this.initMutations(queryParams);
        const isInitialLoad = true;
        const omicron = this.displayLineages.find((l) => l.viralLineage === 'B.1.1.529');
        this.loadLineage(variant || omicron || this.displayLineages[0], isInitialLoad);
        this.selectedTherapeuticSub = this.eventService
          .get<string>(Vals.selectedTherapeuticKey)
          .pipe(filter((x) => x !== undefined))
          .subscribe((therapeutic) => {
            if (this.displayPoints !== undefined) {
              this.filters.filterPoints(
                this.displayPoints,
                this.filterState,
                this.legendVariant,
                this.activeLineage,
                this.selectedSubvariant,
                this.isSubLegend,
                this.referenceLineages
              );
              const metadata = this.therapeuticMap.get(therapeutic) ?? null;
              this.beeswarmUI.drawChart(
                this.displayPoints,
                this.filterState.filterBehavior,
                this.colorByLineage,
                metadata
              );
            }
          });
      },
      (error) => (this.error = true)
    );
  }
  public ngOnDestroy() {
    this.activityChartUI.destroy();
    this.beeswarmUI.destroy();
    this.eventService.get(Vals.selectedTherapeuticKey).next(null);
    this.selectedTherapeuticSub?.unsubscribe();
  }
  private initMutations(queryParams: Params) {
    const mutations = queryParams?.['mutation'];
    if (mutations?.length) {
      this.eventService.get(Vals.setMutationsKey).next(mutations);
    }
  }

  private getRouteVariant(variantParam: string, lineages: DisplayLineage[]): DisplayLineage | undefined {
    return lineages.find((l) => {
      const noSpecial = l.viralLineage
        ?.replace(/\./g, '')
        .replace(/\?/g, '')
        .replace(/\'/g, '')
        .replace(/\//g, '')
        .replace(/ /g, '');
      const normalized = noSpecial?.toLowerCase();
      return normalized === variantParam;
    });
  }
  private resetPageState(lineage: DisplayLineage) {
    this.activeLineage = lineage;
    this.selectedDataPoint.next(null);
    this.neighbors.next([]);
  }
  public changeLineage(lineage: DisplayLineage): void {
    this.loadLineage(lineage);
    this.eventService.get(Vals.selectedTherapeuticKey).next(null);
    this.activityChartUI.clearSelectedTherapeutic();
  }
  public loadLineage(lineage: DisplayLineage, isInitialLoad: boolean = false): void {
    this.resetPageState(lineage);
    const lineageName =
      lineage.viralLineage === Vals.allVariants.viralLineage || lineage.viralLineage === Vals.whatsNew.viralLineage
        ? ''
        : lineage.viralLineage;
    this.lineageLoading = true;
    this.classification = this.chartMapper.getClassification(lineage);
    if (lineageName === null) {
      return;
    }

    // If viewing all, clear out sublineages so none are filtered
    const subLineages = lineage.viralLineage === Vals.customView.viralLineage ? this.selectedSubLineages : [];

    // Multi-filter parameters. On select of lineage freom the top buttons, pass the one filter to the get points endpoint
    const thresholdDate = this.filterByUpdatedDate ? new Date('10/1/2022') : null;
    const selectedLineages = !lineageName ? [] : [lineageName];
    const updatedDate = this.filterByUpdatedDate ? thresholdDate : null;
    const selectedSubLineages = subLineages;
    const getPointsApiCall = this.activityPointApi.getActivityChartPointsBySubLineage(
      null,
      selectedSubLineages,
      selectedLineages,
      updatedDate
    );

    getPointsApiCall.subscribe((points) => {
      this.lineageLoading = false;
      if (lineage.viralLineage === Vals.allVariants.viralLineage) {
        points = points.filter((x) => x.viralProteinFullPartial.toLowerCase() !== 'single mutation variant');
      }
      points.forEach((p) => (p.assayId = this.assayDefinitions.get(p.assayType)));
      this.displayPoints = this.chartMapper.getDisplayPoints(points);
      if (lineage.viralLineage === Vals.whatsNew.viralLineage) {
        this.displayPoints = this.filters.filterMostRecent(this.displayPoints);
        this.lastTherapeuticFilterGroup = Vals.withData;
        this.legendVariant = null;
      }
      // everytime a lineage loads reset checked subvarint legend, not in resetState()
      // because resetState() happens when therapeutics shown filter changes
      if (
        lineage.viralLineage === Vals.allVariants.viralLineage ||
        lineage.viralLineage === Vals.whatsNew.viralLineage ||
        lineage.viralLineage?.toLocaleLowerCase() === Vals.singleMutation ||
        lineage.viralLineage?.toLocaleLowerCase() === 'other variants'
      ) {
        this.isSubLegend = false;
      } else {
        this.isSubLegend = true;
      }
      // gets subvariant colors for legend which are passed to legend component
      this.subvariants = this.chartMapper.getSubvariants(points);

      this.displayPoints = this.chartMapper.setLineagePointAndLegendColors(
        this.displayPoints,
        this.displayLineages,
        lineage
      );
      this.mutations = this.filters.initMutations(this.displayPoints);
      this.filters.setFilterCounts(this.displayPoints, this.filterGroups);
      this.eventService.get(Vals.resetFiltersKey).next(lineage.viralLineage);
      this.refreshChart(true);
    });
  }
  private refreshChart(isInitialLoad = false) {
    if (!this.displayPoints) {
      return;
    }
    // this is triggered from the legend, it updates the colors
    if (this.isSubLegend) {
      this.chartMapper.colorPointsBySubvariant(this.displayPoints, this.subvariants);
    }
    this.filters.filterPoints(
      this.displayPoints,
      this.filterState,
      this.legendVariant,
      this.activeLineage,
      this.selectedSubvariant,
      this.isSubLegend,
      this.referenceLineages
    );

    if (this.activeLineage.viralLineage !== Vals.whatsNew.viralLineage || isInitialLoad) {
      const therapeuticGroups = this.filters.filterTherapeutics(
        this.lastTherapeuticFilterGroup,
        this.therapeuticGroups,
        this.displayPoints
      );
      if (therapeuticGroups) {
        this.activityChartUI.initChart(this.chart, therapeuticGroups);
      }
    }

    this.colorByLineage =
      this.activeLineage.viralLineage === Vals.whatsNew.viralLineage ||
      this.activeLineage.viralLineage === Vals.allVariants.viralLineage ||
      this.isSubLegend;

    if (this.activeLineage.viralLineage) {
      this.activityChartUI.drawChart(
        this.displayPoints,
        this.activeLineage.viralLineage,
        this.filterState.filterBehavior,
        this.colorByLineage
      );
    }
    const therapeutic = this.eventService.get<string>(Vals.selectedTherapeuticKey).getValue();
    const metadata = this.therapeuticMap.get(therapeutic) ?? null;
    this.beeswarmUI.drawChart(this.displayPoints, this.filterState.filterBehavior, this.colorByLineage, metadata);
  }
  public changeVisibleDrugs(group: string) {
    this.lastTherapeuticFilterGroup = group;
    this.resetPageState(this.activeLineage);
    this.refreshChart();
  }
  public changePointFilters(filterState: ActivityFilters): void {
    if (
      filterState.dateReportedRange === null ||
      (filterState.dateReportedRange.startDate === null && filterState.dateReportedRange.endDate === null)
    ) {
      filterState.dateReportedRange = null;
    }
    if (_.isEqual(this.filterState, filterState)) {
      return;
    }
    this.filterState = _.cloneDeep(filterState);
    this.refreshChart();
  }

  public legendVariantClicked(variant: DisplayLineage | null) {
    if (this.legendVariant === variant || (!this.legendVariant && !variant)) {
      return;
    }
    this.legendVariant = variant;

    this.refreshChart();
  }
  public setSubvariant(subvariant: Subvariant | null) {
    if (this.selectedSubvariant === subvariant) {
      return;
    }
    this.selectedSubvariant = subvariant;
    this.refreshChart();
  }
  public setSubLegend(subLegend: boolean) {
    const prevSublegend = this.isSubLegend;
    if (this.isSubLegend === subLegend) {
      return;
    }
    if (
      this.activeLineage.viralLineage === Vals.allVariants.viralLineage ||
      this.activeLineage.viralLineage === Vals.whatsNew.viralLineage
    ) {
      this.isSubLegend = false;
    } else {
      this.isSubLegend = subLegend;
    }
    if (prevSublegend === this.isSubLegend) {
      return;
    }
    this.refreshChart();
  }
  public filteredLineages!: Observable<string[]>;
  public filteredSubLineages!: Observable<Sublineage[]>;
  public setSelectedLineages(lineages: string[]) {}
  public setSelectedSubLineages(subLineage: string) {
    if (!this.selectedSubLineages.includes(subLineage)) {
      this.selectedSubLineages.push(subLineage);
    }
    this.selectedSubLineagesControl.setValue('');
  }
  public removeSubLin(subLineage: string) {
    if (subLineage && this.selectedSubLineages.includes(subLineage)) {
      this.selectedSubLineages.splice(this.selectedSubLineages.indexOf(subLineage), 1);
    }
  }
  private _filterSub(value: string): Sublineage[] {
    const filterValue = this._normalizeValue(value);
    return this.subLineages.filter((mutation) => this._normalizeValue(mutation.name).includes(filterValue));
  }
  private _filterLin(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.displayLineages
      .filter((lin) => !!lin.viralLineage && this._normalizeValue(lin.viralLineage).includes(filterValue))
      .map((x) => x.viralLineage ?? '');
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  public clearSublineages() {
    this.selectedSubLineages = [];
  }
  public selectView(viewName: string) {
    let lineage = this.displayLineages.find((i) => {
      return i.viralLineage === viewName;
    });
    if (lineage) {
      this.changeLineage(lineage);
    }
  }
}
