import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DrugResult} from 'projects/covid19/src/lib/models/view-models/screen-data-models';
import {ScreeningDataApiService} from '../../../../services/api/data-browser/screening-data-api.service';

@Component({
  selector: 'app-single-agent',
  templateUrl: './single-agent.component.html',
  styleUrls: ['./single-agent.component.scss']
})
export class SingleAgentComponent implements OnInit {
  constructor(private route: ActivatedRoute, private drugNameApi: ScreeningDataApiService) {}
  public drugId!: string;
  public drug: DrugResult | null = null;
  public totalNumberOfAssay = 0;
  public agents: any[] = [];
  public res: any = {};

  ngOnInit(): void {
    this.drugId = this.route.snapshot.pathFromRoot['4'].params['id'];
    this.drugNameApi.getDrugNameData(this.drugId).subscribe((data) => {
      this.drug = data;
      this.drugNameApi.getPerformAgents(this.drugId).subscribe((res) => {
        this.res = res;
        this.totalNumberOfAssay = this.res.data.length;
        this.res.data.map((a: {[x: string]: any; sampleDataIds: (string | number)[]; assayName: any; assayId: any}) => {
          this.drugNameApi.getMultisdid(a.sampleDataIds[0]).subscribe((agent: any) => {
            if (agent) {
              this.agents.push({
                assayName: a.assayName,
                assayId: a.assayId,
                SVG: agent.svg,
                specs: a[a.sampleDataIds[0]]
              });
            }
          });
        });
      });
    });
  }
}
