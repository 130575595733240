import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-animal-models',
  templateUrl: './animal-models.component.html',
  styleUrls: ['./animal-models.component.scss']
})
export class AnimalModelsComponent {
  constructor() {}
}
