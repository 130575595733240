import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {RWEPoint} from '../../../models/dtos/rwe/rwe';
import {API_URLS, Endpoints} from '@odp/shared';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {RWEEdge, RWEQuery} from '../../../models/dtos/rwe/rwe-ql';
import {RWEQL} from '../../../queries/rwe-query';
/* import {RWEApiFilters} from '../../../models/dtos/rwe/rwe-filters';*/

@Injectable({
  providedIn: 'root'
})
export class RWEApiService {
  private variantBaseUrl!: string;

  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private rweQL: RWEQL
  ) {
    this.variantBaseUrl = configuration.graphqlEndpoint; // hit the postgres endpoint
  }

  public getRWE(): Observable<RWEPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<RWEQuery>({query: this.rweQL.document})
      .pipe(
        map((result: {data: RWEQuery}) => {
          return this.mapRWEToEntry(result.data.realworldevidences.edges);
        })
      );
  }

  // format date from yyyy-mm-dd to mm-dd-yyyy
  // - this is the disadvantage of declaring column date type instead of string
  private formatDate(date: string): string {
    let dateParts = date.split('-');
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }

  public mapRWEToEntry(rweEntry: RWEEdge[]): RWEPoint[] {
    const entries = rweEntry.map((e) => {
      const rwe: RWEPoint = {
        reportNumber: String(Math.round(Number(e.node.reportNumber))),
        dataSource: e.node.dataSource,
        dataTitle: e.node.dataTitle,
        dataDate: this.formatDate(e.node.dataDate),
        treatmentsCompiledEdited: e.node.treatmentsCompiledEdited,
        drugClassCompiledEdited: e.node.drugClassCompiledEdited,
        studyStart: e.node.studyStart,
        studyEnd: e.node.studyEnd,
        drug1: e.node.drug1,
        drug2: e.node.drug2,
        drug3: e.node.drug3,
        abbreviatedSummary: e.node.abbreviatedSummary,
        lineage: e.node.lineage,
        hospitalizationEndpoint: e.node.hospitalizationEndpoint,
        mortalityEndpoint: e.node.mortalityEndpoint,
        otherEndpoint: e.node.otherEndpoint,
        hospitalizationMetricUsed: e.node.hospitalizationMetricUsed,
        hospitalizationEndptMetricPvalue: e.node.hospitalizationEndptMetricPvalue,
        mortalityMetricUsed: e.node.mortalityMetricUsed,
        mortalityEndptMetricPvalue: e.node.mortalityEndptMetricPvalue,
        otherDefined: e.node.otherDefined,
        otherMetricUsed: e.node.otherMetricUsed,
        otherEndpointMetricPvalue: e.node.otherEndpointMetricPvalue,
        cohortUniqueness: e.node.cohortUniqueness,
        id: ''
      };
      return rwe;
    });
    return entries;
  }
}
