import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-datadownload-renderer',
  template: `
    <a style="text-decoration: none" [href]="getProcessedUrl()" download>
      <i class="fa fa-download" aria-hidden="true"></i>
    </a>
  `,
  styles: ['app-external-link {color: #007bff; cursor: pointer;}  ::ng-deep i {font-size: .7rem;}']
})
export class DataDownloadRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params.data;
  }

  refresh(): boolean {
    return false;
  }

  getProcessedUrl(): string {
    if (this.params && this.params.assayDataFileUrl) {
      return this.params.assayDataFileUrl.replace(/ /g, '_');
    }
    return '';
  }
}
