import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {ActivityChartQuery} from '../models/dtos/activity/activity-chart-ql';
import {HeterologousBoosterQuery} from '../models/dtos/heterologous-booster/heterologousbooster-ql';

@Injectable({
  providedIn: 'root'
})
export class HeterologousBoosterQL extends Query<HeterologousBoosterQuery, {}> {
  override document = gql`
    {
      heterologousboosters {
        edges {
          node {
            id
            hbReportNumber
            dataTitle
            dataDate
            dataSource
            dataSourceType
            sampleSource
            vacPrime
            vacBoost1
            boost1Class
            assayCat
            assayType
            assayTypeMod
            viralSublineage
            vaccineActivity
            boost1TimeUnit
            boost1Time1
            boost1Effect1
            boost1Time2
            boost1Effect2
            boost1Time3
            boost1Effect3
            dataSourceType
          }
        }
      }
    }
  `;

  getHeterologousBoostersByVac1Boost1Class = gql`
    query HeterologousBoosters($vacBoost1: String!, $boost1Class: String!) {
      heterologousboosters(vacBoost1: $vacBoost1, boost1Class: $boost1Class) {
        edges {
          node {
            id
            hbReportNumber
            dataTitle
            dataDate
            dataSource
            dataSourceType
            sampleSource
            vacPrime
            vacBoost1
            boost1Class
            assayCat
            assayType
            assayTypeMod
            viralSublineage
            vaccineActivity
            boost1TimeUnit
            boost1Time1
            boost1Effect1
            boost1Time2
            boost1Effect2
            boost1Time3
            boost1Effect3
            dataSourceType
          }
        }
      }
    }
  `;
}
