import {InjectionToken} from '@angular/core';

export interface AppConfig {
  odp: Endpoints;
}
export interface Endpoints {
  variantApiUrl: string;
  graphqlEndpoint: string;
  covid19ApiUrl: string;
}
export const APP_CONF = new InjectionToken<AppConfig>('ncats.odp.app.config');
export const API_URLS = new InjectionToken<AppConfig>('ncats.odp.api.urls');
export const EVENT_SERVICE = new InjectionToken<AppConfig>('ncats.odp.event.service');
