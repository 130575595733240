import {Injectable} from '@angular/core';
import * as d3 from 'd3';
import {style} from 'd3';
import {attr} from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class HorizontalChartTopTenService {
  private horizontalChartData: HorizontalChartDataModel[] = [];
  private margin = {top: 20, right: 30, bottom: 40, left: 90};
  private width = 350 - this.margin.left - this.margin.right;
  private height = 200 - this.margin.top - this.margin.bottom;
  private svgEl!: d3.Selection<SVGGElement, unknown, HTMLElement, string>;
  private maxX!: d3.NumberValue;

  onInit(horizontalChartData: HorizontalChartDataModel[]) {
    this.horizontalChartData = horizontalChartData;
    this.createSvg();
    this.drawChart();
  }

  private createSvg(): void {
    this.svgEl = d3
      .select('#hr-chart-topten')
      .append('svg')
      .attr('class', 'w-100')
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private drawChart(): void {
    this.maxX = Math.max(...this.horizontalChartData.map((o) => o.value));

    // Add X axis
    const x = d3.scaleLinear().domain([0, this.maxX]).range([0, this.width]);

    // Add Y axis
    const y = d3
      .scaleBand()
      .range([0, this.height])
      .domain(
        this.horizontalChartData.map((d) => {
          return d.name;
        })
      )
      .padding(0.1);

    this.svgEl
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'start');

    this.svgEl
      .append('g')
      .call(d3.axisLeft(y))
      .selectAll('.tick text')
      .style('text-anchor', 'start')
      .attr('transform', 'translate(-70,0)rotate(0)')
      .on('mouseover', (event: any, d: any) => {
        this.mouseOverTherapeutic(event, d);
      })
      .on('mouseout', this.mouseOutTherapeutic)
      .call(this.truncateLabel);

    // Bars
    this.svgEl
      .selectAll('rect')
      .data(this.horizontalChartData)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .attr('y', (d) => (y(d.name) ?? 0) + 0)
      .attr('width', (d) => x(d.value))
      .attr('height', y.bandwidth())
      .attr('fill', (d): any => {
        return d.color;
      });

    // Add Value inside Bar
    this.svgEl
      .selectAll('.text')
      .data(this.horizontalChartData)
      .enter()
      .append('text')
      .attr('class', 'innerBarLabel')
      .attr('y', (d) => (y(d.name) ?? 0) + 0)
      .attr('x', 3)
      .style('fill', 'white')
      .attr('dy', '.80em')
      .text((d) => {
        return d.value;
      });
  }

  private truncateLabel(labels: any) {
    labels.each(function () {
      // @ts-ignore
      const therapeuticName = d3.select(this).text();
      let renderName = therapeuticName;

      if (therapeuticName.length > 15) {
        renderName = renderName.slice(0, 12) + '...';
      }
      // @ts-ignore
      d3.select(this).text(renderName);
      if (therapeuticName.length > 15) {
        // @ts-ignore
        d3.select(this).append('title').append('text').style('text-anchor', 'start').text(therapeuticName);
      }
    });
  }

  public mouseOutTherapeutic(event: any, d: any) {
    const parent = d3.select(event.currentTarget).node().parentNode.parentNode.parentNode.parentNode
      .parentNode.parentNode;
    const popup = d3.select(parent).selectAll('.popup');
    popup.remove();
  }

  public mouseOverTherapeutic(event: any, d: any) {
    const parent = d3.select(event.currentTarget).node().parentNode.parentNode.parentNode.parentNode
      .parentNode.parentNode;
    d3.select(event.target).classed('hover-square', false);
    const popup = d3.select(parent).selectAll('.popup');
    const width = d.length > 20 ? 400 : 300;
    popup.remove();
    const point = d3.pointer(event, parent);
    d3.select(parent)
      .append('g')
      .classed('popup', true)
      .attr('style', `top: ${point[1] + 20}px; left: ${point[0] + 30}px`)
      .html(this.getSelectedPointTemplate(d));
  }

  private getSelectedPointTemplate(d: any) {
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    const border = !isChrome ? 'border: 1px solid #333;' : '';
    return `
    <foreignobject  class="node mouseover">
    <div class="popup-container" style="${border} background-color: white; padding: 5px 10px; border-radius: 4px">
        <div class="t-name">${d} </div>
        </div>
    </foreignobject>`;
  }
}

export interface HorizontalChartDataModel {
  name: string;
  value: number;
  color?: string;
}
