import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {API_URLS, Endpoints} from '@odp/shared';
import {map, Observable} from 'rxjs';
import {ResourceEntry} from '../../../models/dtos/related-resources/resource';
import {ResourceQL} from '../../../queries/related-resource-query';
import {VariantMapperService} from '../../mappers/variant-mapper/variant-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class RelatedResourceApiService {
  private variantBaseUrl!: string;
  constructor(
    private httpClient: HttpClient,
    @Inject(API_URLS) configuration: Endpoints,
    private resourceQL: ResourceQL,
    private variantMapper: VariantMapperService
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;
  }
  public getRealtedResourceMetadata(): Observable<{
    data: {dataUploadedDate: string};
  }> {
    return this.httpClient.get<{data: {dataUploadedDate: string}}>(`${this.variantBaseUrl}/route/relatedresource/meta`);
  }
  public getRelatedResourceEntries(): Observable<ResourceEntry[]> {
    return this.resourceQL
      .fetch()
      .pipe(map((result) => this.variantMapper.mapToResourceEntries(result.data.relatedResources.edges)));
  }
}
