<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container">
  <div class="col-12 p-0 py-3">
    <p><a [routerLink]="['/covid19']">Home </a> > Animal Models</p>
  </div>
  <div class="mt-4 mv-2 container nav-container">
    <h3>Animal Models</h3>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link #rla1="routerLinkActive" routerLinkActive [active]="rla1.isActive" [routerLink]="['summary']">
        Summary
      </a>
      <a
        mat-tab-link
        #rla2="routerLinkActive"
        routerLinkActive
        [active]="rla2.isActive"
        [routerLink]="['sam-field-guide']"
      >
        Small Animal Model Field Guide
      </a>
      <a
        mat-tab-link
        #rla3="routerLinkActive"
        routerLinkActive
        [active]="rla3.isActive"
        [routerLink]="['nhp-field-guide']"
      >
        NHP Field Guide
      </a>
      <a
        mat-tab-link
        #rla4="routerLinkActive"
        routerLinkActive
        [active]="rla4.isActive"
        [routerLink]="['activ-resources']"
      >
        ACTIV Resources
      </a>
    </nav>
    <div class="sam-page mt-4">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</div>
<app-footer-view [showCC]="true"></app-footer-view>
