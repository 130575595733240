<app-header-view></app-header-view>
<app-menu></app-menu>

<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row">
      <div class="col-md-5" id="jumbotron_introduction">
        <div class="header">
          OpenData
          <div class="inline yellow">| COVID-19</div>
        </div>
        <hr />
        <div class="brief">
          NCATS is generating a collection of datasets by screening a panel of SARS-CoV-2-related assays against all
          approved drugs<br /><br />
        </div>
        <div class="brief">
          These datasets, as well as the assay protocols used to generate them, are being made immediately available to
          the scientific community on this site as these screens are completed
        </div>
      </div>
      <div class="col-md-7"></div>
    </div>
  </div>
</div>

<div class="container" id="notification_container">
  <div class="row">
    <div class="col-md-12 h4 title">Recent activity</div>
  </div>
  <div id="activities_list">
    <div class="row" *ngFor="let activity of activities">
      <div class="col-md-12 act">
        <div class="activity-img">
          <img src="./assets/images/{{ activity.icon }}.png" class="activity-icon" />
        </div>

        <div class="activity">
          <div>
            <strong>{{ activity.activityType }}</strong> &nbsp;

            <a *ngIf="activity.activityLink" href="{{ activity.activityLink }}" target="_blank">{{
              activity.activityText
            }}</a>
            <p *ngIf="!activity.activityLink">{{ activity.activityText }}</p>
          </div>
          <div class="text-muted date">{{ activity.date }}</div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</div>

<app-footer-view></app-footer-view>
