import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-footer-view',
  templateUrl: './footer-view.component.html',
  styleUrls: ['./footer-view.component.scss']
})
export class FooterViewComponent {
  @Input() showCC!: boolean;
  @Input() noMargin!: boolean;
}
