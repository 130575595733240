import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivityComponent} from './activity-layout/activity.component';
import {ActivityChartComponent} from './activity-chart/activity-chart.component';
import {SelectedPointInfoComponent} from './selected-point-info/selected-point-info.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {LegendComponent} from './legend/legend.component';
import {SelectedPointPanelComponent} from './selected-point-panel/selected-point-panel.component';
import {ActivityFiltersComponent} from './activity-filters/activity-filters.component';
import {HeroHeaderComponent} from './hero-header/hero-header.component';
import {MatAutocompleteModule as MatAutocompleteModule} from '@angular/material/autocomplete';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@odp/shared';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
@NgModule({
  declarations: [
    HeroHeaderComponent,
    ActivityFiltersComponent,
    LegendComponent,
    ActivityComponent,
    ActivityChartComponent,
    SelectedPointInfoComponent,
    SelectedPointPanelComponent,
    DisclaimerComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbPopoverModule,
    NgxDaterangepickerMd.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule
  ],
  exports: [ActivityChartComponent, SelectedPointPanelComponent]
})
export class ActivityModule {}
