import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ScreeningDataApiService} from '../../../services/api/data-browser/screening-data-api.service';
import {catchError, skip} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-data-browser-samples',
  templateUrl: './data-browser-samples.component.html',
  styleUrls: ['./data-browser-samples.component.scss']
})
export class DataBrowserSamplesComponent implements OnInit {
  constructor(private sampleApi: ScreeningDataApiService, private route: ActivatedRoute) {}
  public id!: number;
  public Sample: any = '';
  public Smiles: any;
  public curve: any;
  public smileImage!: any;
  public dataLoading = false;

  ngOnInit(): void {
    this.dataLoading = true;
    // Get the ID from the URL
    this.id = this.route.snapshot.params['id'];

    // get the sample Data with Smiles
    this.sampleApi.getSampleData(this.id).subscribe((data) => {
      this.Sample = data;
      const smilesImage$ = this.sampleApi.getSmileImage(this.Sample.smiles).pipe(
        catchError((x) => {
          return (this.smileImage = x.url);
        })
      );
      const getCurveChart$ = this.sampleApi.getCurveChart(this.id);
      forkJoin([smilesImage$, getCurveChart$]).subscribe(([smileImage, curve]) => {
        this.curve = curve;
        this.dataLoading = false;
      });
    });
  }
}
