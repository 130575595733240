import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {NonHumanPrimateModelDetails} from '../../../models/dtos/animal/non-human-primate';
import {AnimalModelServiceNew} from '../../../services/api/animal-api/animal-model-new.service';
// import {AnimalModelService} from '../../../services/api/animal-api/animal-model.service';

@Component({
  selector: 'app-nhp-details',
  templateUrl: './nhp-details.component.html',
  styleUrls: ['./nhp-details.component.scss']
})
export class NhpDetailsComponent implements OnInit {
  details: NonHumanPrimateModelDetails = {} as NonHumanPrimateModelDetails;
  modelId: string;
  studySummaryOpenStates: {[key: string]: boolean};

  constructor(
    private route: ActivatedRoute,
    private animalModelService: AnimalModelServiceNew,
    private titleService: Title
  ) {
    this.modelId = this.route.snapshot.params['id'];
    this.studySummaryOpenStates = {};
  }

  ngOnInit(): void {
    this.animalModelService.getNonHumanPrimateModelDetails(parseInt(this.modelId)).subscribe((data) => {
      this.details = data;
      this.titleService.setTitle(this.details.background?.species);
    });
  }
}
