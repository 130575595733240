import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {DatasetQuery} from '../models/dtos/variant/datasets-ql';

@Injectable({
  providedIn: 'root'
})
export class VariantDatasetQL extends Query<DatasetQuery, {}> {
  override document = gql`
    query getVariant3DatasetsAll {
      variant3Datasets {
        edges {
          node {
            reportNumber
            dataProvider
            dataSource
            dataTitle
            dataDate
            fileName
            numView
            numDownload
            dateUploaded
            dataUpdatedDate
            dataSource2
            variants {
              drugName
            }
          }
        }
      }
    }
  `;

  getSingleDataset = gql`
    query files($id: ID!) {
      variantdataset(id: $id) {
        reportNumber
        dataProvider
        dataSource
        dataTitle
        dataDate
        fileName
        numView
        numDownload
        dateUploaded
        dataUpdatedDate
        dataSource2
        variant3 {
          dataTitle
          dataDate
          dataSource
          viralType
          viralProteinFullPartial
          viralAaMutation
          drugName
          drugClass
          assayType
          assayCellLine
          viralLineage
          dataSourceType
          viralLineageFull
        }
      }
    }
  `;
}
