import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-monkeypox',
  templateUrl: './monkeypox.component.html',
  styleUrls: ['./monkeypox.component.scss']
})
export class MonkeypoxComponent {
  constructor() {}
}
