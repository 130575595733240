import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
@Component({
  selector: 'app-rwe-grid',
  templateUrl: './rwe-grid.component.html',
  styleUrls: ['./rwe-grid.component.scss']
})
export class RWEGridComponent implements OnInit {
  public frameworkComponents: any;

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Real-World Evidence');
  }
}
