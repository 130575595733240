import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-in-vivo-grid',
  templateUrl: './in-vivo-grid.component.html',
  styleUrls: ['./in-vivo-grid.component.scss']
})
export class InVivoGridComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic in vivo Variant Studies');
  }
}
