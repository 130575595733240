import {Component, OnInit} from '@angular/core';
import * as d3 from 'd3';
import {UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {KeyValue} from '@angular/common';
import {GlossaryFilterService} from './glossary-filter.service';
import {TherapeuticGlossary} from '../../models/dtos/therapeutics/therapeutic-glossary-ql';
import {TherapeuticGlossaryApiService} from '../../services/api/therapeutic-glossary-api/therapeutic-glossary-api.service';
import {Title} from '@angular/platform-browser';
@Component({
  selector: 'app-therapeutic-glossary',
  templateUrl: './therapeutic-glossary.component.html',
  styleUrls: ['./therapeutic-glossary.component.scss']
})
export class TherapeuticGlossaryComponent implements OnInit {
  pieData: any;
  approvalStatus: any;
  topTen: any;
  drugs!: TherapeuticGlossary[];
  groups!: Map<string, TherapeuticGlossary[]>;
  public count = 0;
  public dataLoading = false;
  public error = false;
  public control = new UntypedFormControl();
  public filteredByName!: Observable<string[]>;
  public selectedColFilters: Map<string, string[]> = new Map<string, string[]>();
  public filterColumn: string[] = ['Therapeutic Class', 'Approval Status'];
  public filterValues: Map<string, string[]> = new Map<string, string[]>();
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public searchTerm = '';

  constructor(
    private variantApi: TherapeuticGlossaryApiService,
    private filterService: GlossaryFilterService,
    private titleService: Title
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle('Therapeutic Glossary');
    this.dataLoading = true;
    this.variantApi.getTherapeuticGlossary().subscribe(
      (entries) => {
        if (!entries || entries.length === 0) {
          this.error = true;
          return;
        }
        let validData = true;
        entries.forEach((entry) => {
          validData = validData && !!entry.drugName;
        });

        if (!validData) {
          this.error = true;
          return;
        }

        const drugs = [...entries].sort((a, b) => a.drugName.localeCompare(b.drugName));
        this.drugs = drugs;
        this.count = drugs.length;
        this.groups = this.getGroups(drugs);
        this.pieData = this.getClassPie(drugs);
        this.topTen = this.getTopTenDataPoint(drugs);
        this.approvalStatus = this.getApprovalStatusChart(drugs);
        this.mapDistinctValueFiltersToColumns(drugs);
        this.dataLoading = false;
      },
      () => (this.error = true)
    );
    this.control.valueChanges.subscribe((val) => {
      this.filter(val);
      this.searchTerm = val;
    });
  }
  private getGroups(drugs: TherapeuticGlossary[]) {
    const groups = d3.group(drugs, (d) => d.drugName[0]);
    const mapG = new Map([...groups].map(([attribute, value]) => [attribute, value]));
    return mapG;
  }
  private getClassPie(drugs: TherapeuticGlossary[]) {
    const groups = d3.groups(drugs, (d) => d.drugClass);
    const colors = ['#084081', '#0868AC', '#2B8CBE', '#4EB3D3', '#7BCCC4', '#A8DDB5', '#CCEBC5', '#E0F3DB', '#F7FCF0'];
    const ab = groups.map((item, index) => {
      return {
        name: item[0],
        value: `${Math.round((item[1].length / drugs.length) * 100 * 10) / 10}`,
        color: colors[index]
      };
    });
    return ab;
  }
  private getTopTenDataPoint(drugs: TherapeuticGlossary[]) {
    const sortByDataPoint = drugs.sort((a, b) => b.activityPointCount - a.activityPointCount).slice(0, 10);
    const adp = sortByDataPoint.map((item) => {
      return {
        name: item.drugName,
        value: item.activityPointCount,
        color: '#8E8E8E'
      };
    });
    return adp;
  }
  private getApprovalStatusChart(drugs: TherapeuticGlossary[]) {
    const groups = d3.groups(drugs, (d) => d.glossaryFdastatus);

    const as = groups.map((item) => {
      switch (item[0]) {
        case 'FDA approved':
          return {
            name: item[0],
            value: item[1].length,
            color: '#74a722'
          };
        case 'Investigational':
          return {
            name: item[0],
            value: item[1].length,
            color: '#bfbf15'
          };
        case 'EUA revoked':
          return {
            name: item[0],
            value: item[1].length,
            color: '#e0aca5'
          };
        case 'FDA revoked':
          return {
            name: item[0],
            value: item[1].length,
            color: '#a75522'
          };
        case 'Research reagent':
          return {
            name: item[0],
            value: item[1].length,
            color: '#226fa7'
          };
        case 'Discontinued':
          return {
            name: item[0],
            value: item[1].length,
            color: '#3c3c3c'
          };
        case 'EUA approved':
          return {
            name: item[0],
            value: item[1].length,
            color: '#c9e0a5'
          };
        default:
          return {
            name: 'N/A',
            value: item[1].length,
            color: '#8E8E8E'
          };
          break;
      }
    });

    const combineNA = Array.from(
      d3
        .rollup(
          as,
          (g) => g.reduce((p, v) => ((p.value += v.value), p), {...g[0], value: 0}),
          (d) => d.name
        )
        .values()
    );
    const sortByItems = combineNA.sort((a, b) => b.value - a.value).slice(0, 100);
    return sortByItems;
  }
  private filter(value: string) {
    const filterValue = this._normalizeValue(value);
    let drugs = !value
      ? this.drugs
      : this.drugs.filter((drug) => this._normalizeValue(drug.drugName).includes(filterValue));
    drugs = this.filterService.filterPoints(drugs, this.selectedColFilters);
    this.groups = this.getGroups(drugs);
    this.count = drugs.length;
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  public scrollIntoView(anchorHash: string) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView();
      }
    });
  }

  private mapDistinctValueFiltersToColumns(drugs: TherapeuticGlossary[]) {
    this.initalizeSelectedFilters();
    this.initializeFilterEntries();
    const classes = [...new Set(drugs.map((x) => x.drugClass).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });

    const uniqueStatuses = [...new Set(drugs.map((x) => x.glossaryFdastatus).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });

    const orderControl = [
      'FDA approved',
      'FDA/EUA approved',
      'EUA approved',
      'EUA application submitted',
      'EUA revoked',
      'Investigational',
      'Discontinued',
      'Research reagent'
    ];
    uniqueStatuses.sort(function (a, b) {
      return orderControl.indexOf(a) - orderControl.indexOf(b);
    });

    this.setColumnValuesFromEntries('Therapeutic Class', classes);
    this.setColumnValuesFromEntries('Approval Status', uniqueStatuses);
  }

  private initializeFilterEntries() {
    for (const col of this.filterColumn) {
      this.filterValues.set(col, []);
      this.filterExpanded.set(col, false);
    }
  }

  private setColumnValuesFromEntries(columnName: string, recordDB: string | any[]) {
    const dataList = this.filterValues.get(columnName);
    for (const vl of recordDB) {
      if (dataList) {
        dataList.push(vl);
      }
    }
  }

  private initalizeSelectedFilters() {
    for (const col of this.filterColumn) {
      this.selectedColFilters.set(col, []);
    }
  }

  public toggleFilter(filterValue: string, column: string) {
    const dataList = this.selectedColFilters.get(column);
    if (dataList?.includes(filterValue)) {
      const i = dataList.indexOf(filterValue);
      dataList.splice(i, 1);
      this.filter(this.searchTerm);
      return;
    }
    dataList?.push(filterValue);
    this.filter(this.searchTerm);
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  public clearSearchKeyWords() {
    this.control.setValue('');
  }
}
