import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {KeyValue} from '@angular/common';
import {HeterologousBoosterApiService} from '../../../services/api/heterologous-booster-api/heterologous-booster-api.service';
import {DateRange} from '../../../models/view-models/date-range';
import {HeterologousBoosterPoint} from '../../../models/dtos';

@Component({
  selector: 'app-heterologous-filters',
  templateUrl: './heterologous-filters.component.html',
  styleUrls: ['./heterologous-filters.component.scss']
})
export class HeterologousFiltersComponent implements OnInit {
  public filterValues: Map<string, string[]> = new Map<string, string[]>();
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public selectedColFilters: Map<string, string[]> = new Map<string, string[]>();
  public dateExpanded = false;
  @Output() filtersChanged: EventEmitter<SelectedHeterologousFilters> = new EventEmitter<SelectedHeterologousFilters>();
  public dateRange!: DateRange;
  public dateRangeTxt!: string;
  public filterColumn: string[] = [
    'Vaccine (Prime)',
    'Vaccine (Booster)',
    'Viral Lineage',
    'Assay Category',
    'Data Source Type'
  ];

  constructor(private HeterologousBoosterApi: HeterologousBoosterApiService) {}

  ngOnInit(): void {
    const getHeterologousBooster$ = this.HeterologousBoosterApi.getHeterologousBooster();
    forkJoin([getHeterologousBooster$]).subscribe(([HeterologousBooster]) => {
      this.initalizeSelectedFilters();
      this.mapApiFiltersToColumns(HeterologousBooster);
    });
  }

  private mapApiFiltersToColumns(points: HeterologousBoosterPoint[]) {
    this.initializeFilterEntries();

    const assayCat = [...new Set(points.map((x) => x.assayCat).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });
    const dataSourceType = [...new Set(points.map((x) => x.dataSourceType).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });
    const viralSublineage = [...new Set(points.map((x) => x.viralSublineage).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });
    const vacPrime = [...new Set(points.map((x) => x.vacPrime).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });
    const vacBoBoost1 = [...new Set(points.map((x) => x.vacBoost1).filter((y) => !!y))].sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x === y ? 0 : x > y ? 1 : -1;
    });

    this.setColumnValuesFromApi('Assay Category', assayCat);
    this.setColumnValuesFromApi('Viral Lineage', viralSublineage);
    this.setColumnValuesFromApi('Vaccine (Prime)', vacPrime);
    this.setColumnValuesFromApi('Vaccine (Booster)', vacBoBoost1);
    this.setColumnValuesFromApi('Data Source Type', dataSourceType);
  }

  private initializeFilterEntries() {
    for (const col of this.filterColumn) {
      this.filterValues.set(col, []);
      this.filterExpanded.set(col, false);
    }
  }

  private initalizeSelectedFilters() {
    for (const col of this.filterColumn) {
      this.selectedColFilters.set(col, []);
    }
  }

  private setColumnValuesFromApi(columnName: string, recordDB: string | any[]) {
    const dataList = this.filterValues.get(columnName);
    for (const vl of recordDB) {
      if (dataList) {
        dataList.push(vl);
      }
    }
  }
  public changeDate(dateInfo: any) {
    this.dateRange = dateInfo;
    this.emitFilters();
  }

  private emitFilters() {
    this.filtersChanged.emit({
      cols: this.selectedColFilters,
      dateRange: this.dateRange
    });
  }

  public toggleFilter(filterValue: string, column: string) {
    const dataList = this.selectedColFilters.get(column);
    if (!!dataList && dataList.includes(filterValue)) {
      const i = dataList.indexOf(filterValue);
      dataList.splice(i, 1);
      this.emitFilters();
      return;
    }
    if (!!dataList) {
      dataList.push(filterValue);
    }
    this.emitFilters();
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
export interface SelectedHeterologousFilters {
  cols: Map<string, string[]>;
  dateRange: DateRange;
}
