import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutComponent} from './about/about.component';
import {HomepageComponent} from './homepage/homepage.component';
import {HighlightsComponent} from './highlights/highlights.component';
import {AssayComponent} from './assay/assay/assay.component';
import {AssayOverviewComponent} from './assay/overview/overview.component';
import {FeedbackComponent} from './homepage/feedback/feedback.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@odp/shared';
import {CoreModule} from '@odp/covid19';
import {MonkeypoxComponent} from './monkeypox/monkeypox.component';

@NgModule({
  declarations: [
    AboutComponent,
    HomepageComponent,
    FeedbackComponent,
    HighlightsComponent,
    AssayComponent,
    AssayOverviewComponent,
    MonkeypoxComponent
  ],
  imports: [CommonModule, SharedModule, CoreModule, RouterModule]
})
export class FeaturesModule {}
