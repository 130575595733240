<div class="row mt-4">
  <div class="col-md-12">
    <h5>Field Guide: Considerations for the Use of Primate Models for SARS-CoV-2 Treatments and Vaccines</h5>
    <div class="mt-2">
      View/Download at:
      <a href="./assets/pdf/Nonhuman_Primate_Field_Guide_final.pdf" target="_blank">
        Nonhuman Primate Field Guide <img src="./assets/images/pdf.png" style="height:20px"
      /></a>
    </div>
    <div class="mt-4">
      <div>
        SARS-CoV-2 burst onto the medical and scientific stage in late 2019, a novel coronavirus that has taken the
        world by storm. One of the most important activities in understanding virus infectivity, life cycle, and
        pathogenicity of a new virus involves the use of animal models. As investigators in academics and in the
        scientific industry grapple with developing effective therapies and vaccines, it is critical to understand how
        different animal models may provide valuable information to inform the science. This Nonhuman Primate Field
        Guide is designed as a companion to the Small Animal Field Guide. The purpose of the Nonhuman Primate Field
        Guide is to inform investigators of the current status of nonhuman primate (NHP) models, the availability of
        resources to perform studies, and to provide some details about experimental design and sampling to guide
        scientific planning.
      </div>
    </div>
  </div>
</div>
