import {Component, Inject, OnInit} from '@angular/core';
import {DatasetMetadataApiService} from '../../../services/api/dataset-metadata-api/dataset-metadata-api.service';
import {API_URLS, Endpoints} from '@odp/shared';
import {DownloadFilesApiService} from '../../../services/api/download-files-api/download-files-api.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-rwe-header',
  templateUrl: './rwe-header.component.html',
  styleUrls: ['./rwe-header.component.scss']
})
export class RWEHeaderComponent {
  public show = true;
  public recordCount!: string;
  public dataSourceCount!: number;
  public lastUpdatedTxt!: string;
  public dataLoading = true;
  public variantBaseUrl!: string;
  public downloadDatasetPath!: string;
  public popoverTxt!: any;

  constructor(
    private variantApi: DatasetMetadataApiService,
    private getDownloadFile: DownloadFilesApiService,
    private sanitizer: DomSanitizer,
    @Inject(API_URLS) private configuration: Endpoints
  ) {
    this.variantBaseUrl = this.configuration.covid19ApiUrl;
    this.variantApi.getVariantDatasetMeta().subscribe((result) => {
      this.lastUpdatedTxt = this.getUpdateText(result.data.updatedDate);
      this.dataSourceCount = result.data.count;
      this.dataLoading = false;
    });
    this.variantApi.getRecordCount().subscribe((result) => {
      this.recordCount = result.data.count;
    });

    // Note: removed code related to .csv file and we hardcoded it in the html

    // Sanitize and set the HTML content
    const htmlContent = `<p>
    This dataset includes a curated list of COVID-19 real-world evidence studies (preprint and peer-reviewed publications). Selected publications met the following inclusion criteria:
    <ol style="margin-left: 1em; padding-left: 1em;">
      <li>Included a COVID-19 EUA or FDA approved or revoked therapeutic</li>
      <li>Included a metric of analysis, such as hazard or odds ratio</li>
      <li>Included a comparator or control in analysis</li>
      <li>Reported a cohort size &#x2265; 10</li>
    </ol>
    </p>`;

    this.popoverTxt = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  private getUpdateText(dateStr: string) {
    const today = new Date().getTime();
    const updatedTime = new Date(dateStr).getTime();
    const days = Math.ceil((today - updatedTime) / (1000 * 60 * 60 * 24));
    if (days === 0) return 'today';
    if (days === 1) return 'yesterday';
    return days.toString() + ' days ago';
  }
}
