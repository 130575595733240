import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {VariantSummaryQL} from '../../../queries/summary-query';
import {Therapeutic, VariantSummary} from '../../../models/dtos/variant/summary';
import {API_URLS, Endpoints} from '@odp/shared';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {VariantSummaryNode, VariantSummaryQuery} from '../../../models/dtos/variant/summary-ql';
@Injectable({
  providedIn: 'root'
})
export class DataSummaryApiService {
  constructor(
    private summaryQL: VariantSummaryQL,
    private apollo: Apollo,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints
  ) {
    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }
  public getVariantSummaries(): Observable<VariantSummary[] | null> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<VariantSummaryQuery>({
        query: this.summaryQL.document
      })

      .pipe(
        map((result) => {
          return this.mapToSummaries(result.data.getVariant3All);
        })
      );
  }

  public mapToSummaries(nodes: VariantSummaryNode[]): VariantSummary[] | null {
    if (!nodes) return null;
    let variants: VariantSummary[] = [];
    const lineages = [...new Set(nodes.map((e) => e.viralLineage))];
    lineages.forEach((lineage) => {
      const variantTypes = this.mapToSummary(lineage, nodes);
      variants = [...variants, ...variantTypes];
    });
    return variants;
  }

  public mapToSummary(lineage: string, edges: VariantSummaryNode[]): VariantSummary[] {
    const viralTypes: VariantSummary[] = [];
    const sameLineage = edges.filter((e) => e.viralLineage === lineage);
    const uniqueViralTypes = [...new Set(sameLineage.map((e) => e.viralType))];
    uniqueViralTypes.forEach((type) => {
      const viralType: VariantSummary = {
        viralLineage: lineage,
        viralType: type,
        uploadedDate: sameLineage[0]?.dataUpdatedDate,
        lastUploadDate: sameLineage[0]?.dateUploaded,
        theraputics: this.mapToTherapeutic(type, sameLineage)
      };
      viralTypes.push(viralType);
    });
    return viralTypes;
  }

  public mapToTherapeutic(type: string, sameLineage: VariantSummaryNode[]): Therapeutic[] {
    const therapeutics: Therapeutic[] = [];
    const sameTypes = sameLineage.filter((l) => l.viralType === type);
    const drugNames: string[] = [];
    sameTypes.forEach((t) => {
      const therapeutic: Therapeutic = {
        source: t.dataSource,
        drugName: t.drugName,
        drugClass: t.drugClass
      };
      if (drugNames.indexOf(t.drugName) < 0) {
        therapeutics.push(therapeutic);
        drugNames.push(t.drugName);
      }
    });

    return therapeutics;
  }
}
