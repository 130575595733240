import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-curve-classes',
  templateUrl: './curve-classes.component.html',
  styleUrls: ['./curve-classes.component.scss']
})
export class CurveClassesComponent implements OnInit {
  constructor(private titleService: Title) {}
  ngOnInit(): void {
    this.titleService.setTitle('NCATS Curve Classes');
  }
}
