<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container-fluid hero">
  <div class="container py-4">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Related Resources
          <span class="btn btn-primary bg-white text-dark border-0 ml-3" *ngIf="relatedResources"
            >Last updated {{ lastUpdatedTxt }}
          </span>
        </h3>
        <p>
          SARS-CoV-2-related websites and resources that help guide and inform
          <strong>OpenData Portal Variants</strong> content.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid p-0">
  <div class="row px-3">
    <div class="col-12 p-0">
      <ng-container *ngFor="let rSection of relatedResources; odd as isOdd; even as isEven">
        <div [ngClass]="{'odd-section': isOdd, 'even-section': isEven}">
          <div class="container py-4">
            <h2 class="mb-4">{{ rSection.label }}</h2>
            <div *ngFor="let rs of rSection.value" class="py-1">
              <app-external-link [url]="rs.siteLink" [text]="rs.siteTitle" [ngClass]="{link: true}"></app-external-link>
              <p>{{ rs.siteDescription }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-footer-view [showCC]="true" *ngIf="relatedResources"></app-footer-view>
