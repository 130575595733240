import {Injectable} from '@angular/core';
import {TherapeuticGlossary} from '../../models/dtos/therapeutics/therapeutic-glossary-ql';

@Injectable({
  providedIn: 'root'
})
export class GlossaryFilterService {
  constructor() {}

  public filterPoints(drugs: TherapeuticGlossary[], colfilters: any): TherapeuticGlossary[] {
    const result = [];
    for (const record of drugs) {
      if (
        this.searchInFields(colfilters.get('Therapeutic Class'), record.drugClass) &&
        this.searchInFields(colfilters.get('Approval Status'), record.glossaryFdastatus)
      ) {
        result.push(record);
      }
    }
    return result;
  }

  private searchInFields(data: string[], record: string) {
    if (!data.length) {
      return true;
    }
    let found = false;
    for (const d of data) {
      if (d.trim() === record.trim()) {
        found = true;
      }
      if (record.indexOf(';') > -1) {
        const entries = record.split(';');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
      if (record.indexOf(',') > -1) {
        const entries = record.split(',');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
    }
    return found;
  }
}
