import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as Highcharts from 'highcharts';
import {AcitvitySummaryChartData, DrugResult} from 'projects/covid19/src/lib/models/view-models/screen-data-models';
import {ScreeningDataApiService} from '../../../../services/api/data-browser/screening-data-api.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  series: any[] = [];
  renderer: any;
  plotWidth!: number;
  plotSizeX!: number;
  plotTop!: number;
  updateFlag: boolean = false;

  constructor(private route: ActivatedRoute, private drugNameApi: ScreeningDataApiService) {}

  public drugId!: string;
  public drug?: DrugResult | null = null;
  public NCATSInxight!: any;
  public smileImage!: any;
  public barColors: any = [];
  public potencyData!: AcitvitySummaryChartData;
  public efficacyData!: AcitvitySummaryChartData;
  public isPotencyData = false;
  public isEfficacyData = false;
  public colorMap: any = {
    act0: '#f0f0f0',
    act1: '#D11D8B',
    act2: '#D11D8B',
    act3: '#D11D8B',
    act4: '#4DAD27',
    act5: '#4DAD27',
    act6: '#4DAD27'
  };
  public activityChartData = {
    id: '',
    name: '',
    categories: [],
    values: [],
    unit: '',
    precision: 0,
    mapping: {},
    colors: []
  };

  public highcharts = Highcharts;

  chartOptions: any = {
    chart: {
      type: 'bar'
    }
  };

  ngOnInit(): void {
    this.drugId = this.route.snapshot.pathFromRoot['4'].params['id'];

    this.drugNameApi.getDrugNameData(this.drugId).subscribe((data) => {
      this.buildSummaryChart(data);
      this.drug = data;
      this.drugNameApi.getSmileImage(this.drug!.SMILES).subscribe(
        (s) => {},
        (smile) => {
          this.smileImage = smile.url;
        }
      );
    });
  }

  buildSummaryChart(result: any) {
    const data = result.DATA;
    const sm = result.SMILES;

    const categories = data.map((d: {ASSAY_NAME: any}) => d.ASSAY_NAME);
    const assayNameIdMapping: any = {};
    data.forEach((d: {ASSAY_NAME: string; ASSAY_ID: any; CATEGORY: any}) => {
      assayNameIdMapping[d.ASSAY_NAME] = {id: d.ASSAY_ID, category: d.CATEGORY};
    });

    const ac50Vals = data.map((d: any) => {
      this.barColors.push(this.colorMap['act' + d.ACTIVITY_CLASS]);
      return d.AC50;
    });
    const effVals = data.map((d: {EFFICACY: number}) => {
      const v = Math.abs(d.EFFICACY);
      return v > 0 ? v : null;
    });

    this.potencyData = {
      id: 'activity_chart',
      name: 'Potency',
      categories,
      values: ac50Vals,
      unit: '&microM',
      precision: 2,
      mapping: assayNameIdMapping,
      colors: this.barColors
    };

    this.efficacyData = {
      id: 'activity_chart',
      name: 'Efficacy',
      categories,
      values: effVals,
      unit: '%',
      precision: 0,
      mapping: assayNameIdMapping,
      colors: this.barColors
    };

    this.chartOptions = this.drawActivityChart(this.potencyData);
    this.isPotencyData = true;
    this.updateFlag = true;
  }

  loadEfficacyChart() {
    this.chartOptions = this.drawActivityChart(this.efficacyData);
    this.isPotencyData = false;
    this.isEfficacyData = true;
  }

  loadPotencyChart() {
    this.chartOptions = this.drawActivityChart(this.potencyData);
    this.isPotencyData = true;
    this.isEfficacyData = false;
  }

  drawActivityChart(data: AcitvitySummaryChartData) {
    let valueArr = data.values;
    const cat = data.categories;
    const isPotency = data.name === 'Potency';
    const maxRaw = Math.max(...valueArr);
    const MAX = isPotency
      ? round(maxRaw, 5, 0)
      : (() => {
          // set max value for small efficacy values
          return !maxRaw || maxRaw < 25 ? 50 : maxRaw < 100 ? 100 : maxRaw;
        })();

    // reverse potency values
    valueArr = isPotency
      ? valueArr.map((v: number) => {
          if (v) return MAX - v;
          return null;
        })
      : valueArr;

    const len = valueArr.length;
    const height = 340 + len * (len <= 3 ? 0 : len <= 7 ? 15 : 20);

    const marginRight = 450;
    const marginVal = 50;

    Highcharts.setOptions({colors: data.colors});
    return {
      chart: {
        type: 'bar',
        height,
        margin: [marginVal + 20, marginRight, marginVal, marginVal]
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: [
        {
          opposite: true,
          categories: cat,
          title: {text: null},
          labels: {
            useHTML: true,
            overflow: 'justify',
            align: 'left',
            style: {width: marginRight},
            formatter: (self: {value: any}) => {
              const mp: any = data.mapping[self.value];
              return (
                '<div style="word-wrap:no;" triggers="mouseenter:mouseleave placement="top" popoverTitle="Assay category" ngbPopover="' +
                mp.category +
                '">' +
                '<a href="../../covid19/assays/assay/' +
                mp.id +
                '/single-agent" style="font-size: 14px; color: #000; text-decoration: none;" target="_blank">' +
                self.value +
                '</a></div>'
              );
            }
          }
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            useHTML: true,
            margin: 30,
            text: data.name + ' (' + data.unit + ')',
            style: {color: '#000', fontSize: '16px'}
          }
        }
      ],
      yAxis: {
        min: 0,
        max: MAX,
        title: '',
        opposite: true,
        reversed: true,
        labels: {
          step: 2,
          overflow: 'justify',
          align: 'right',
          useHTML: true,
          formatter: (self: {value: any}) => {
            return self.value + data.unit;
          }
        }
      },
      tooltip: {
        enabled: false,
        useHTML: true,
        valueSuffix: ' &micro;M',
        backgroundColor: '#fff',
        padding: 1,
        borderWidth: 1,
        shadow: false,

        formatter: (self: {x: string; y: string}) => {
          return (
            '<div style="background-color:#fff;padding:6px">' +
            self.x +
            '<br/>' +
            data.name +
            ' ' +
            self.y +
            data.unit +
            '</div>'
          );
        },
        positioner(point: {plotX: number; plotY: number}) {
          return {x: point.plotX + 10, y: point.plotY + 5};
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          pointWidth: 24,
          showInLegend: false,
          colorByPoint: true,
          states: {
            hover: {
              borderColor: '#888',
              brightness: 0 // -0.2
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#333',
            useHTML: true,
            formatter: function (): string {
              let y = (this as any).y;
              return Highcharts.numberFormat(isPotency ? MAX - y : y, data.precision) + data.unit;
            }
          }
        }
      },
      credits: {enabled: false},
      series: [
        {
          align: 'right',
          name: data.name,
          data: valueArr
        }
      ]
    };
  }
}

function round(num: number, inc: number, off: number) {
  return Math.ceil((num - off) / inc) * inc + off;
}
