// download.service.ts
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConcatTSVService {
  constructor(private http: HttpClient) {}

  // Function to fetch all files and return them as an array of Blobs
  downloadFiles(urls: string[]): Observable<Blob[]> {
    return forkJoin(urls.map((url) => this.http.get(url, {responseType: 'blob'})));
  }

  // Function to concatenate blobs and trigger download
  concatenateAndDownloadFiles(blobs: Blob[], fileName: string): void {
    const concatenatedBlob = new Blob(blobs, {type: 'text/tsv'});
    const concatenatedBlobUrl = URL.createObjectURL(concatenatedBlob);

    const downloadLink = document.createElement('a');
    downloadLink.href = concatenatedBlobUrl;
    downloadLink.download = fileName;
    downloadLink.click();

    URL.revokeObjectURL(concatenatedBlobUrl);
  }
}
