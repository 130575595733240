<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Pandemic History Explorer
          <!-- <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span> -->
        </h3>
        <div class="d-flex pb-4">
          <!-- <div class="w-60">
            <div class="scores">
              <div>
                <span class="score">{{ dataSourceCount }}</span> data sources
              </div>
              <div class="ml-2">
                <span class="score">{{ recordCount }}</span> activity data points
              </div>
            </div>
          </div> -->
        </div>
        <p>
          The Pandemic History Explorer provides an interactive visualization to sort OpenData Portal SARS-CoV-2 variant
          therapeutic data based on historical CDC Variant Proportion data during the COVID-19 Pandemic (May '21 - May
          '23). Variant proportion data is limited to strains that reached or exceeded a 2% circulation proportion in
          the US in a given week, based on CDC data.
        </p>
        <p>
          Users can select a variant or week of interest within the Variant Proportion visual, which will display all
          available therapeutic activity data for the SARS-CoV-2 variant(s) that were prevalent in the US during that
          time period.
        </p>
        <p>
          By selecting different points in time, users can explore how the activity of available therapeutics has
          shifted with the emergence of new SARS-CoV-2 variants.
        </p>
      </div>
    </div>
  </div>
</div>
