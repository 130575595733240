import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {TherapeuticGlossaryQuery} from '../models/dtos/therapeutics/therapeutic-glossary-ql';

@Injectable({
  providedIn: 'root'
})
export class TherapeuticGlossaryQL extends Query<TherapeuticGlossaryQuery, {}> {
  override document = gql`
    {
      getTherapeuticGlossaryAll {
        id
        glossaryPdb
        glossaryMoa
        glossarySmiles
        glossaryStatus
        glossarySummary
        drugId
        activityPointCount
        glossaryCitelineDrugId
        glossarySmallmoleucleType
        glossaryAntibodyType
        glossaryFdastatus
        variantDrug {
          drugName
          drugClass
          drugCompany
          unii
          alias1
        }
      }
    }
  `;

  getSingleTherapeuticGlossary = gql`
    query files($id: ID!) {
      therapeuticglossary(id: $id) {
        id
        activityPointCount
        drugId
        glossaryAntibodyType
        glossaryCitelineDrugId
        glossaryFdastatus
        glossaryMoa
        glossaryPdb
        glossaryReference
        glossarySmallmoleucleType
        glossarySmiles
        glossaryStatus
        glossaryStructure
        glossarySummary
        glossaryVaxtype
        glossaryOusapproval
        variantDrug {
          unii
          priority
          id
          drugName
          drugCompany
          drugClass
          dateUpdated
          cas
          alias5
          alias4
          alias3
          alias2
          alias1
          drugIconUrlJpg
          drugIconUrlPng
        }
      }
    }
  `;
}
