<app-header-view></app-header-view>

<div class="container-fluid p-0">
  <nav class="navbar navbar-expand-lg p-0" id="primary-nav">
    <div class="container ">
      <a class="title">Monkeypox</a>
    </div>
  </nav>
</div>
<nav class="navbar navbar-expand-lg p-0" id="second-nav">
  <div class="container ">
    <a class="active-link">Home</a>
  </div>
</nav>

<div id="background">
  <br />
  <div class="banner">
    <table>
      <tr>
        <th>
          <img class="image" src="./assets/images/Monkeypox.png" />
        </th>
        <th>
          <span class="title">Responding to Pandemic Threats |</span><span class="second-title"> Monkeypox</span>
          <span class="updated btn btn-primary bg-white ml-3">Updated on 07/25/2022</span>
          <div class="context">
            <p>
              Multiple cases of monkeypox have been reported in several countries that don't normally report monkeypox,
              including the United States.
            </p>
            <p>
              NCATS OpenData Portal is tracking therapeutics approved or available for use against monkeypox and/or
              smallpox, as well as active clinical trials for monkeypox indications. This site will be updated as more
              therapeutic data becomes available for monkeypox.
            </p>
          </div>
        </th>
      </tr>
    </table>
  </div>
  <br />
  <div class="treatment">
    <span class="title">Therapeutics Available for Treatment of Monkeypox</span>
    <span class="updated btn btn-primary bg-grey ml-3">Updated on 07/25/2022</span>
    <br />
    There is no treatment approved specifically for monkeypox infections, though some antivirals developed for use
    against smallpox may demonstrate activity against monkeypox.<br />
    The following therapeutics have received FDA or EUA approval for smallpox indications, and thus may represent
    options for the treatment of monkeypox:
  </div>
  <br />
  <div class="therapeutic-table">
    <table class="table therapeutic">
      <thead>
        <tr class="white-row">
          <th>Class</th>
          <th>Name</th>
          <th>Company</th>
          <th>Approval Status</th>
          <th>Description</th>
          <th>FDA Reference</th>
        </tr>
      </thead>
      <tbody>
        <tr class="white-row">
          <td><span class="vaccine btn btn-primary ml-3">Vaccine</span></td>
          <td>JYNNEOS™ (Imvanex)</td>
          <td>Bavarian Nordic A/S</td>
          <td>FDA Approved: Smallpox and Monkeypox</td>
          <td>
            JYNNEOS™ is a live, attenuated non-replicating Orthopoxvirus vaccine. It is indicated for prevention of
            smallpox and monkeypox disease in adults 18 years of age and older determined to be at high risk for
            smallpox or monkeypox infection. This is the only currently FDA-approved vaccine for the prevention of
            monkeypox disease.
          </td>
          <td><a href="https://www.fda.gov/vaccines-blood-biologics/jynneos">Link</a></td>
        </tr>
        <tr>
          <td><span class="vaccine btn btn-primary ml-3">Vaccine</span></td>
          <td>ACAM2000<sup>&reg;</sup></td>
          <td>Emergent BioSolutions</td>
          <td>FDA Approved: Smallpox</td>
          <td>
            ACAM2000<sup>&reg;</sup> is a replication-competent live vaccinia virus vaccine. It is indicated for active
            immunization against smallpox disease in persons determined to be at high risk for smallpox infection.
          </td>
          <td><a href="https://www.fda.gov/vaccines-blood-biologics/vaccines/acam2000">Link</a></td>
        </tr>
        <tr class="white-row">
          <td><span class="vaccine btn btn-primary ml-3">Vaccine</span></td>
          <td>Aventis Pasteur Smallpox Vaccine (APSV)</td>
          <td>N/A</td>
          <td>Investigational: Not yet approved for Smallpox or Monkeypox</td>
          <td>
            APSV is an investigational, replication-competent vaccinia virus vaccine. APSV is not yet approved for
            smallpox or other orthopoxvirus indications; however, in a smallpox emergency, APSV could potentially be
            made available under an IND or EUA for use in circumstances where approved vaccines are depleted or not
            readily available.
          </td>
          <td>N/A</td>
        </tr>
        <tr>
          <td><span class="drug btn btn-primary ml-3">Drug</span></td>
          <td>TPOXX (tecovirimat; ST246)</td>
          <td>Siga Technologies</td>
          <td>FDA approved: Smallpox; EMA approved: Smallpox and Monkeypox</td>
          <td>
            TPOXX is a small molecule antiviral that inhibits the function of the VP37 viral envelope protein, which is
            critical for the formation of the secondary viral envelope. Inhibition of this protein prevents the release
            of new viral particles from infected cells so they cannot spread to and infect other cells. TPOXX is
            approved for use against smallpox; however, its use for other orthopoxvirus infections, including monkeypox,
            is not currently approved by the FDA. TPOXX has been approved by the European Medicines Agency (EMA) for the
            treatment of smallpox, monkeypox, cowpox, and vaccinia complications following vaccination against smallpox
          </td>
          <td><a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2021/208627s006lbl.pdf">Link</a></td>
        </tr>
        <tr class="white-row">
          <td><span class="drug btn btn-primary ml-3">Drug</span></td>
          <td>TEMBEXA<sup>&reg;</sup> (brincidofovir)</td>
          <td>Chimerix</td>
          <td>FDA Approved: Smallpox</td>
          <td>
            TEMBEXA<sup>&reg;</sup> is an oral small molecule antiviral approved by the FDA for the treatment of
            smallpox disease in adult and pediatric patients, including neonates. TEMBEXA<sup>&reg;</sup> is a
            nucleotide analog that inhibits activity of the DNA polymerase enzyme in orthopoxviruses such as smallpox,
            preventing viral DNA synthesis and limiting further replication. TEMBEXA<sup>&reg;</sup> is not currently
            approved for use against monkeypox, though the CDC is pursuing an expanded access protocol to use the drug
            for treatment of monkeypox, allowing clinicians to use the drug as a back-up in patients who do not respond
            to other treatments.
          </td>
          <td>
            <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2021/214460s000,214461s000lbl.pdf">Link</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <div class="treatment">
    <span class="title">Active Monkeypox Clinical Trials</span>
    <span class="updated btn btn-primary bg-grey ml-3">Updated on 07/25/2022</span>
    <br />
    List of active trials registered in the United States in clinicaltrials.gov
    <div class="coming">Coming Soon</div>
  </div>
  <br />
  <div class="resources">
    <span class="title">Monkeypox Resources</span>
    <div>CDC Monkeypox Dashboard (<a href="https://www.cdc.gov/poxvirus/monkeypox/index.html">Link</a>)</div>
    <div>
      WHO Monkeypox Frequently Asked Questions (<app-external-link
        [url]="'https://www.who.int/news-room/questions-and-answers/item/monkeypox'"
        [text]="'Link'"
      ></app-external-link
      >)
    </div>
    <div>
      2022 United States Map & Case Count (<a href="https://www.cdc.gov/poxvirus/monkeypox/response/2022/us-map.html"
        >Link</a
      >)
    </div>
    <div>
      Monkeypox: What We Do and Don't Know About Recent Outbreaks (<app-external-link
        [url]="'https://asm.org/Articles/2022/May/Monkeypox-What-We-Do-and-Don-t-Know-About-Recent-O'"
        [text]="'Link'"
      ></app-external-link
      >)
    </div>
  </div>
  <br />
</div>

<app-footer-view [showCC]="true"></app-footer-view>
