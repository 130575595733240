import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-bivalent-booster-grid',
  templateUrl: './bivalent-booster-grid.component.html',
  styleUrls: ['./bivalent-booster-grid.component.scss']
})
export class BivalentBoosterGridComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic Multivalent Variant Studies');
  }
}
