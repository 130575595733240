import {Injectable} from '@angular/core';
import * as d3 from 'd3';
import {VariantShare} from './time-points-chart.component';
import {BehaviorSubject} from 'rxjs';
import {Subvariant} from '../../activity/legend/legend.component';
import {DisplayChartPoint} from '../../../models/view-models/display-chart-point';

@Injectable({
  providedIn: 'root'
})
export class StackedVariantsService {
  private svg: any;
  private isOverview: boolean = false;
  private width!: number;
  private height!: number;
  private margin = {top: 20, right: 100, bottom: 100, left: 40};
  private chartWidth!: number;
  private chartHeight!: number;
  private xAxis: any;
  private x: any;
  private chart: any;
  private xAxisZoomed: any;
  private selectedDataPoint!: BehaviorSubject<DisplayChartPoint | null>;
  private selectedVariant!: BehaviorSubject<string | null>;
  private neighbors!: BehaviorSubject<DisplayChartPoint[]>;
  public init(
    selectedDataPoint: BehaviorSubject<DisplayChartPoint | null>,
    selectedVariant: BehaviorSubject<string | null>,
    neighbors: BehaviorSubject<DisplayChartPoint[]>
  ) {
    this.selectedDataPoint = selectedDataPoint;
    this.selectedVariant = selectedVariant;
    this.neighbors = neighbors;
  }
  // brush
  // https://codepen.io/yuzu-r/pen/aboyLJg
  public renderChart(
    cVariantsBySlice: [string, VariantShare[]][],
    root: any,
    selectedDate$: BehaviorSubject<string>,
    subvariants: Subvariant[],
    pageFromLast: number = 0,
    weekOffset: number = 0,
    isOverview: boolean = true,
    initSlider: boolean = false
  ) {
    this.svg?.selectAll('*').remove();
    let mostRecent: [string, VariantShare[]][] = [];
    if (isOverview) {
      mostRecent = cVariantsBySlice.slice(0, 112);
    } else {
      mostRecent = cVariantsBySlice.slice(
        cVariantsBySlice.length - 14 * (pageFromLast + 1) - weekOffset,
        cVariantsBySlice.length - 14 * pageFromLast - weekOffset
      );
    }

    // calculate the remaining percentage for each collection of variants
    mostRecent.forEach((d) => {
      const total = d[1].reduce((acc, current) => acc + +current.share, 0);
      const existingOther = d[1].find((x) => x.variant === 'Other');
      let remaining = 1 - total;
      remaining = existingOther ? remaining + +existingOther.share : remaining;
      if (remaining > 0) {
        if (existingOther) {
          existingOther.share = remaining;
        } else {
          d[1].push({
            variant: 'Other',
            share: remaining,
            weekEnding: d[1][0].weekEnding,
            publishedDate: d[1][0].publishedDate
          });
        }
      }
    });

    // Remove this, this is just for geting a unique list to give to the clients
    // get all unique variants for the combined mostRecent
    const varGroups = mostRecent.map((d) => {
      const unique = d[1].map((x) => x.variant);
      return [...new Set(unique)];
    });
    // reduce varGroups to a single array of unique variants
    const uniqueVars = varGroups.reduce((acc, current) => [...new Set([...acc, ...current])], []);

    const nameGroups = mostRecent.map((d) => d[1].map((b) => b.variant));
    let keys = nameGroups.reduce((acc, current) => [...new Set([...acc, ...current])], []);
    keys = keys.reverse();
    const domain = mostRecent.map((d) => d[0]);
    const bars = mostRecent.map((d) => {
      const obj: {[key: string]: any} = {
        key: d[0]
      };
      d[1].forEach((b) => {
        obj[b.variant] = b.share * 100;
      });
      return obj;
    });

    this.width = isOverview ? 1000 : 500;
    this.height = 415;
    this.margin = {top: 20, right: 100, bottom: 140, left: 40};
    this.chartWidth = this.width - this.margin.left - this.margin.right;
    this.chartHeight = this.height - this.margin.top - this.margin.bottom;

    const padding = isOverview ? 0.1 : 0.2;
    this.x = d3.scaleBand().paddingInner(padding).range([0, this.chartWidth]).domain(domain); // (data.Issue);
    const y = d3.scaleLinear().range([this.chartHeight, 0]).domain([0, 100]);

    const colors = d3
      .scaleOrdinal<string, string>()
      .range(subvariants.map((d) => d.color))
      .domain(subvariants.map((d) => d.sublineage));

    const stack = d3.stack().keys(keys)(bars);
    const _this = this;
    if (!this.svg) {
      this.svg = d3.select(root).append('svg').attr('width', this.width).attr('height', this.height);

      if (initSlider) {
        this.svg.call(zoom);
      }
    }

    this.chart = this.svg
      .append('g')
      .attr('width', this.chartWidth)
      .attr('height', this.chartHeight)
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    // Add the X-axis
    this.xAxis = d3
      .axisBottom(this.x)
      .tickSize(0)
      .tickFormat((interval: any, i) => {
        if (isOverview) {
          return i % 3 !== 0 ? ' ' : interval;
        }
        return interval;
      });

    this.xAxisZoomed = d3
      .axisBottom(this.x)
      .tickSize(0)
      .tickFormat((interval: any, i) => {
        return interval;
      });

    this.chart
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${this.chartHeight})`)
      .call(this.xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dy', '-.35em')
      .attr('transform', 'translate(0, 10), rotate(-45)');
    this.chart.selectAll('.x-axis').attr('opacity', 0);
    this.createMonthLabels();
    const group = this.chart.append('g').classed('foot-note', true);
    group.append('text').text('Data Source: ').style('font-size', '12px').attr('transform', 'translate(0, 355)');
    group
      .append('text')
      .text(
        'Centers for Disease Control and Prevention. COVID Data Tracker. Atlanta, GA: U.S. Department of Health and Human Services, CDC; 2024, January 10.'
      )
      .style('font-size', '12px')
      .attr('transform', 'translate(0, 370)');
    group
      .append('text')
      .attr('transform', 'translate(0, 385)')

      .append('a')
      .attr('href', 'https://covid.cdc.gov/covid-data-tracker/#variant-proportions')
      .style('fill', 'blue')
      .style('font-size', '12px')
      .text('COVID Data Tracker');

    // Add the Y-axis
    const yAxis = d3.axisLeft(y).ticks(5).tickSize(0);

    this.chart
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', 'translate(0, 0)')
      .call(yAxis)
      .selectAll('text')
      .attr('transform', 'translate(0, -10)');

    const serieColor = (d: any) => colors(d.key);

    const serie = this.chart.selectAll('.serie').data(stack).enter().append('g');
    serie.attr('class', 'serie').attr('fill', serieColor);
    this.createAxisLabels();

    const bar = serie
      .selectAll('.bar')
      .data((data: any[], i: number) => {
        const vals = data.map((d: any) => {
          d.value = d.data[keys[i]];
          d.variant = keys[i];
          d.date = d.data.key;
          return d;
        });
        return vals;
      })
      .enter()
      .append('g')
      .attr('class', 'bar');

    bar
      .append('rect')
      .attr('x', (d: any) => {
        return this.x(d.data.key as string)!;
      }) // data.Issue))
      .attr('width', this.x.bandwidth())
      .style('cursor', 'pointer')
      .classed('selected', (d: any) => {
        return d.date === selectedDate$.getValue();
      })
      .attr('y', (d: any) => y(d[1]))
      .attr('height', (d: any) => (isNaN(d[1]) ? 0 : y(d[0]) - y(d[1])))
      .on('mousedown', (event: any, d: any) => {
        selectedDate$.next(d.date);
        this.selectedVariant.next(d.variant);
        const currentNode = d3.select(event.currentTarget).node();
        const parent = currentNode.parentNode;
        d3.select(parent.parentNode.parentNode).selectAll('.selected').classed('selected', false);
        d3.select(currentNode).classed('selected', true);
        this.selectedDataPoint.next(null);
        this.neighbors.next([]);
      });

    const txtG = bar
      .append('g')
      .classed('vertical-text', true)
      .style(
        'transform',
        (d: any) =>
          `translate(${this.x(d.data.key!)! + this.x.bandwidth() / 2}px,${y(d[1]) + (y(d[0]) - y(d[1])) / 2}px`
      );

    txtG
      .append('text')
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .classed('segment-name', true)
      .style('opacity', 0)
      .attr('fill', '#fff')
      .text((d: any) => (d.value > 13 ? d.variant : ''))
      .style('transform', 'rotate(90deg)')
      .style('cursor', 'pointer')
      .on('mousedown', (event: any, d: any) => {
        selectedDate$.next(d.date);
        this.selectedVariant.next(d.variant);
        const currentNode = d3.select(event.currentTarget).node();
        const parent = currentNode.parentNode;
        d3.select(parent.parentNode.parentNode.parentNode).selectAll('.selected').classed('selected', false);
        d3.select(parent.parentNode).select('rect').classed('selected', true);
        this.selectedDataPoint.next(null);
        this.neighbors.next([]);
      });
    if (isOverview) {
      const labels = [
        {text: 'Other', x: 10, y: 100},
        {text: 'B.1.617.2', x: 100, y: 100},
        {text: 'B.1.1.529', x: 290, y: 50},
        {text: 'BA.1.1', x: 290, y: 200},
        {text: 'BA.2', x: 420, y: 50},
        {text: 'BA.2.12.1', x: 400, y: 240},
        {text: 'BA.5', x: 540, y: 140},
        {text: 'BA.4', x: 500, y: 240},
        {text: 'BQ.1', x: 675, y: 110},
        {text: 'BQ.1.1', x: 665, y: 200},
        {text: 'XBB.1.5', x: 770, y: 160}
      ];
      const vLabels = this.chart
        .selectAll('.variant-labels')
        .data(labels)
        .enter()
        .append('g')
        .attr('class', 'variant-labels')
        .attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);
      vLabels
        .append('text')
        .style('stroke', '#000')
        .style('stroke-width', '8px')
        .style('opacity', '0.3')
        .style('font-size', '13px')
        .style('stroke-linejoin', 'round')
        .text((d: any) => d.text);
      vLabels
        .append('text')
        .style('fill', '#fff')
        .style('font-size', '13px')
        .style('font-weight', 'bold')
        .text((d: any) => d.text);
      this.createMonthLabels(true);
    }

    bar.append('title').text((d: any, a: any, b: any) => {
      const variant = keys[a] ?? '';
      return `${d.variant}: ${d.value}%`;
    });

    bar
      .on('mouseover', (event: any, d: any) => {
        d3.select(event.currentTarget).attr('fill', '#555');
      })
      .on('mouseout', (event: any, d: any) => {
        d3.select(event.currentTarget).attr('fill', null);
      });

    if (!initSlider) {
    }
    function zoom(svg: any) {
      const extent: [[number, number], [number, number]] = [
        [_this.margin.left, _this.margin.top],
        [_this.width - _this.margin.right, _this.height - _this.margin.top]
      ];
      svg.call(d3.zoom().scaleExtent([1, 8]).translateExtent(extent).extent(extent).on('zoom', zoomed));
      function zoomed(event: any) {
        let previous: any = null;
        _this.x.range(
          [_this.margin.left, _this.width - _this.margin.right].map((d) => {
            d = d - 40;
            return event.transform.applyX(d);
          })
        );
        const bw = Math.ceil(_this.x.bandwidth());
        if (bw !== 8) {
          svg.selectAll('.variant-labels').style('opacity', 0);
          svg.selectAll('.month-labels').style('opacity', 0);
        } else {
          svg.selectAll('.variant-labels').style('opacity', 1);
          svg.selectAll('.month-labels').style('opacity', 1);
        }
        if (bw > 15) {
          svg.selectAll('.segment-name').style('opacity', 1);
        } else {
          svg.selectAll('.segment-name').style('opacity', 0);
        }
        svg
          .selectAll('.serie rect')
          .attr('x', (d: any) => _this.x(d.date))
          .attr('width', _this.x.bandwidth());
        if (bw === 8) {
          svg.selectAll('.x-axis').attr('opacity', 0);
        } else {
          svg.selectAll('.x-axis').attr('opacity', 1);
        }
        if (bw > 15) {
          svg.selectAll('.x-axis').call(_this.xAxisZoomed);
        } else {
          svg.selectAll('.x-axis').call(_this.xAxis);
        }
        d3.select('.y-axis').raise();
        svg
          .selectAll('.vertical-text')
          .style(
            'transform',
            (d: any) =>
              `translate(${_this.x(d.data.key!)! + _this.x.bandwidth() / 2}px,${y(d[1]) + (y(d[0]) - y(d[1])) / 2}px`
          );
      }
    }
  }
  private createAxisLabels() {
    this.svg
      .append('text')
      .classed('.x-axis-label', true)
      .text('% Viral Lineages Among Infections')
      .attr('transform', 'rotate(270)')
      .style('font-weight', 'bold')
      .attr('x', -245)
      .attr('y', 10);
    this.svg
      .append('text')
      .classed('.y-axis-label', true)
      .text('Collection Date (Week Ending)')
      .style('font-weight', 'bold')
      .attr('x', 340)
      .attr('y', 355);
  }
  private createMonthLabels(on: boolean = true) {
    const labels = [
      {text: 'May', x: 0, y: 0},
      {text: 'Jun', x: 0, y: 0},
      {text: 'Jul', x: 0, y: 0},
      {text: 'Aug', x: 0, y: 0},
      {text: 'Sep', x: 0, y: 0},
      {text: 'Oct', x: 0, y: 0},
      {text: 'Nov', x: 0, y: 0},
      {text: 'Dec', x: 0, y: 0},
      {text: 'Jan', x: 0, y: 0},
      {text: 'Feb', x: 0, y: 0},
      {text: 'Mar', x: 0, y: 0},
      {text: 'Apr', x: 0, y: 0},
      {text: 'May', x: 0, y: 0},
      {text: 'Jun', x: 0, y: 0},
      {text: 'Jul', x: 0, y: 0},
      {text: 'Aug', x: 0, y: 0},
      {text: 'Sep', x: 0, y: 0},
      {text: 'Oct', x: 0, y: 0},
      {text: 'Nov', x: 0, y: 0},
      {text: 'Dec', x: 0, y: 0},
      {text: 'Jan', x: 0, y: 0},
      {text: 'Feb', x: 0, y: 0},
      {text: 'Mar', x: 0, y: 0},
      {text: 'Apr', x: 0, y: 0},
      {text: 'May', x: 0, y: 0}
    ];
    const length = 870;
    const step = length / labels.length;
    labels.forEach((d, i) => {
      d.x = i * step;
      d.y = 0;
    });
    const group = this.chart.append('g').classed('month-labels', true).attr('transform', 'translate(0, 275)');
    const vLabels = group
      .selectAll('.month-label')
      .data(labels)
      .enter()
      .append('g')
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', 'month-labels')
      .attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);

    vLabels
      .append('text')
      .style('font-size', '10px')
      .text((d: any) => d.text);

    group
      .append('line')
      .style('stroke', '#afabab')
      .attr('stroke-width', '4px')
      .attr('x1', 0)
      .attr('y1', 10)
      .attr('x2', 272)
      .attr('y2', 10);

    group
      .append('line')
      .style('stroke', '#767171')
      .attr('stroke-width', '4px')
      .attr('x1', 277)
      .attr('y1', 10)
      .attr('x2', 690)
      .attr('y2', 10);

    group
      .append('line')
      .style('stroke', '#3a3838')
      .attr('stroke-width', '4px')
      .attr('x1', 695)
      .attr('y1', 10)
      .attr('x2', 861)
      .attr('y2', 10);

    group.append('text').text('2021').attr('x', 0).attr('y', 30).style('font-size', '20px').attr('font-weight', 'bold');
    group
      .append('text')
      .text('2022')
      .attr('x', 277)
      .attr('y', 30)
      .style('font-size', '20px')
      .attr('font-weight', 'bold');
    group
      .append('text')
      .text('2023')
      .attr('x', 695)
      .attr('y', 30)
      .style('font-size', '20px')
      .attr('font-weight', 'bold');
  }
}
