<div>
  The animal model summaries and descriptions have been curated by the National Institutes of Health (NIH)
  <a href="https://www.nih.gov/research-training/medical-research-initiatives/activ/leadership" target="_blank">
    Accelerating COVID-19 Therapeutic Interventions and Vaccines (ACTIV) Preclinical Working Group</a
  >
  with support from the Foundation for the National Institutes of Health (FNIH). New and updated information, including
  detailed individual animal model pages, will be provided as more scientific studies are shared. Please continue to
  check back for more information.
  <div class="mt-2">
    Feedback, comments, and questions are highly encouraged to further develop these pages. Please contact
    <a href="mailto:ACTIVpreclinical@fnih.org">ACTIVpreclinical&#64;fnih.org</a>.
  </div>
</div>
