import {Component, Inject, OnInit} from '@angular/core';
import {DatasetMetadataApiService} from '../../../services/api/dataset-metadata-api/dataset-metadata-api.service';
import {TraceApiService} from '../../../services/api/trace-api/trace-api.service';
import {API_URLS, Endpoints} from '@odp/shared';
@Component({
  selector: 'app-trace-header',
  templateUrl: './trace-header.component.html',
  styleUrls: ['./trace-header.component.scss']
})
export class TraceHeaderComponent {
  public show = true;
  public recordCount!: string;
  public lastUpdatedTxt!: string;
  public dataLoading = true;
  public variantBaseUrl!: string;
  constructor(
    private variantApi: TraceApiService,
    private metadataApi: DatasetMetadataApiService,
    @Inject(API_URLS) configuration: Endpoints
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;
    this.variantApi.getTraceLatestDate().subscribe((result) => {
      this.lastUpdatedTxt = this.getUpdateText(result.data);
      this.dataLoading = false;
    });
    this.metadataApi.getRecordCount().subscribe((result) => {
      this.recordCount = result.data.count;
    });
  }
  private getUpdateText(dateStr: string) {
    const today = new Date().getTime();
    const updatedTime = new Date(dateStr).getTime();
    const days = Math.ceil((today - updatedTime) / (1000 * 60 * 60 * 24));
    if (days === 0) return 'today';
    if (days === 1) return 'yesterday';
    return days.toString() + ' days ago';
  }
}
