import {Injectable} from '@angular/core';
import {BivalentBoosterPoint} from '../../../models/dtos/bivalent-booster/bivalentbooster';
import {SelectedBivalentFilters} from '../bivalent-filters/bivalent-filters.component';

@Injectable({
  providedIn: 'root'
})
export class BivalentFilterService {
  constructor() {}

  public filterPoints(
    allBivalentBoosterPoints: BivalentBoosterPoint[],
    filters: SelectedBivalentFilters
  ): BivalentBoosterPoint[] {
    const result = [];
    const colfilters = filters.cols;
    for (const record of allBivalentBoosterPoints) {
      if (
        this.searchInFields(colfilters.get('Data Source Type') ?? [], record.dataSourceType) &&
        this.searchInFields(colfilters.get('Assay Category') ?? [], record.assayCat) &&
        this.searchInFields(colfilters.get('Viral Lineage') ?? [], record.viralLineage) &&
        this.searchInFields(colfilters.get('Vaccine (Booster)') ?? [], record.vacBoost) &&
        this.searchInFields(colfilters.get('Infection History') ?? [], record.infectionHistory)
      ) {
        result.push(record);
      }
    }
    return result;
  }

  private searchForChecked(data: string[], colName: string, entryValue: string): boolean {
    if (data.length === 0) {
      return true;
    }
    if (data.indexOf(colName) > -1) {
      return !!entryValue;
    }
    return false;
  }

  private searchDateRange(dateRange: any, reportDate: string) {
    if (dateRange) {
      if (!dateRange.startDate) {
        return true;
      }
      if (
        Date.parse(reportDate) >= Date.parse(dateRange.startDate) &&
        Date.parse(reportDate) <= Date.parse(dateRange.endDate)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  private searchInFields(data: string[], record: string) {
    if (!data.length) {
      return true;
    }
    let found = false;
    for (const d of data) {
      if (d.trim() === record.trim()) {
        found = true;
      }
      if (record.indexOf(';') > -1) {
        const entries = record.split(';');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
      if (record.indexOf(',') > -1) {
        const entries = record.split(',');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
    }
    return found;
  }
}
