<div>
  <div id="filter" class="bg-white p-3 px-1">
    <div class="d-block border-bottom">
      <h5>FILTER BY</h5>
    </div>
    <div *ngFor="let filterEntry of $any(filterValues) | keyvalue : originalOrder">
      <div class="border-top py-3">
        <div
          class="column-name"
          (click)="filterExpanded.set(filterEntry.key + '', !filterExpanded.get(filterEntry.key + ''))"
        >
          <span>{{ filterEntry.key }}:</span>
          <div>
            <span *ngIf="!filterExpanded.get(filterEntry.key + '')" class="ml-3 expand-arrow arrow-right"
              ><i class="fas fa-angle-down"></i
            ></span>
            <span *ngIf="filterExpanded.get(filterEntry.key + '')" class="ml-3 expand-arrow arrow-right"
              ><i class="fas fa-angle-up"></i
            ></span>
          </div>
        </div>
        <div *ngIf="filterExpanded.get(filterEntry.key + '')" class="filter-values py-2 px-1">
          <div *ngFor="let filterId of filterEntry.value">
            <div>
              <label class="check-label">
                <input type="checkbox" class="checkmark" (change)="toggleFilter(filterId, filterEntry.key + '')" />
                <div class="filter-window" [title]="filterId">
                  <div>{{ filterId }}</div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dateExpanded">
      <input
        id="reported-date"
        type="text"
        class="p-1 pl-2 mb-2"
        ngxDaterangepickerMd
        [(ngModel)]="dateRangeTxt"
        placeholder="Choose date range"
        (change)="changeDate($event)"
        #picker
      />
    </div>
  </div>
</div>
