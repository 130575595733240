import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-activ-resources',
  templateUrl: './activ-resources.component.html',
  styleUrls: ['./activ-resources.component.scss']
})
export class ActivResourcesComponent {
  constructor() {}
}
