import {Component, OnInit} from '@angular/core';
import {TherapeuticGlossaryApiService} from '../../../services/api/therapeutic-glossary-api/therapeutic-glossary-api.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {HeterologousBoosterApiService} from '../../../services/api/heterologous-booster-api/heterologous-booster-api.service';
import {BivalentBoosterApiService} from '../../../services/api/bivalent-booster-api/bivalent-booster-api.service';
@Component({
  selector: 'app-single-therapeutic-glossary',
  templateUrl: './single-therapeutic-glossary.component.html',
  styleUrls: ['./single-therapeutic-glossary.component.scss']
})
export class SingleTherapeuticGlossaryComponent implements OnInit {
  public dataLoading = false;
  public drugId!: any;
  public drug: any;
  public variantDrug: any;
  public error = false;
  public refrenceLinks: any;
  public onScrollTap: string = 'summary';

  public numberOfHDatapoint: number = 0;
  public snapshotDataHBooster: any;

  public numberOfMDatapoint: number = 0;
  public snapshotDataMBooster: any;

  constructor(
    private route: ActivatedRoute,
    private variantApi: TherapeuticGlossaryApiService,
    private heterologousBoosterApi: HeterologousBoosterApiService,
    private bivalentBoosterBoosterApi: BivalentBoosterApiService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.drugId = this.route.snapshot.params['id'];
    this.variantApi.getSingleTherapeuticGlossary(this.drugId).subscribe(
      (entry) => {
        if (!entry) {
          this.error = true;
          return;
        }
        this.drug = entry;
        this.variantDrug = entry.variantDrug;
        this.titleService.setTitle(`Therapeutic Glossary | ${this.drug.variantDrug.drugName}`);

        this.heterologousBoosterApi
          .getHeterologousBoostersByVac1Boost1Class(this.drug.variantDrug.drugName)
          .subscribe((hBooster) => {
            this.numberOfHDatapoint = hBooster
              .filter((item) => {
                return item.vacBoost1 === this.drug.variantDrug.drugName && item.boost1Class === 'Heterologous';
              })
              .reduce(
                (acc, item) =>
                  acc + (item.boost1Effect1 ? 1 : 0) + (item.boost1Effect2 ? 1 : 0) + (item.boost1Effect3 ? 1 : 0),
                0
              );
            this.snapshotDataHBooster = this.processHBData(hBooster);
          });

        this.bivalentBoosterBoosterApi
          .getBivalentBoosterByBoostGlossId(String(entry.drugId))
          .subscribe((bivalentBooster) => {
            this.numberOfMDatapoint = bivalentBooster
              .filter((item) => {
                return entry.drugId === Number(item.boostGlossid) && item.boostClass !== 'Monovalent';
              })
              .reduce(
                (acc, item) =>
                  acc + (item.boost1Effect1 ? 1 : 0) + (item.boost1Effect2 ? 1 : 0) + (item.boost1Effect3 ? 1 : 0),
                0
              );

            this.snapshotDataMBooster = this.processMBData(
              bivalentBooster.filter((item) => {
                return entry.drugId === Number(item.boostGlossid) && item.boostClass !== 'Monovalent';
              })
            );
          });

        const refLinks = this.drug.glossaryReference.split('\n');
        this.refrenceLinks = refLinks.map((refrence: string) => {
          return refrence.replace(' ', '');
        });
      },
      () => (this.error = true)
    );
  }

  scroll(el: HTMLElement, section: string) {
    this.onScrollTap = section;
    el.scrollIntoView({behavior: 'smooth'});
  }

  processHBData(hBoosterData: any[]): any {
    const aggregatedData: {[key: string]: any} = {};

    hBoosterData.forEach((hb) => {
      const key = `${hb.vacBoost1}_${hb.vacPrime}`;
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          booster: hb.vacBoost1,
          prime: hb.vacPrime,
          dataPoints: 0,
          variantsTested: new Set(),
          effects: []
        };
      }

      if (hb.boost1Effect1) {
        aggregatedData[key].dataPoints++;
        aggregatedData[key].effects.push(parseFloat(hb.boost1Effect1));
      }
      if (hb.boost1Effect2) {
        aggregatedData[key].dataPoints++;
        aggregatedData[key].effects.push(parseFloat(hb.boost1Effect2));
      }
      if (hb.boost1Effect3) {
        aggregatedData[key].dataPoints++;
        aggregatedData[key].effects.push(parseFloat(hb.boost1Effect3));
      }

      if (hb.viralSublineage) {
        aggregatedData[key].variantsTested.add(hb.viralSublineage);
      }
    });

    return Object.values(aggregatedData).map((entry) => {
      const {booster, prime, dataPoints, variantsTested, effects} = entry;
      const average = effects.length ? effects.reduce((sum: any, val: any) => sum + val, 0) / effects.length : 0;
      const min = effects.length ? Math.min(...effects) : 0;
      const max = effects.length ? Math.max(...effects) : 0;
      return {
        Prime: {name: 'Prime', value: prime},
        Booster: {name: 'Booster', value: booster},
        'Data points': {name: 'Data points', value: dataPoints},
        'Variants tested': {name: 'Variants tested', value: Array.from(variantsTested).join(', ')},
        'Average relative activity of heterologous booster*': {
          name: 'Average relative activity of heterologous booster*',
          value: effects.length ? `${average.toFixed(2)} (${min.toFixed(2)} - ${max.toFixed(2)})` : 'N/A'
        }
      };
    });
  }

  processMBData(mBoosterData: any[]): any {
    const aggregatedData: {[key: string]: any} = {};

    mBoosterData.forEach((mb) => {
      const key = `${mb.vacBoost1}_${mb.viralSublineage}`;
      const variant = mb.viralLineage;

      if (!aggregatedData[key]) {
        aggregatedData[key] = {};
      }

      if (!aggregatedData[key][variant]) {
        aggregatedData[key][variant] = {
          dataPoints: 0,
          effects: []
        };
      }

      if (mb.boost1Effect1) {
        aggregatedData[key][variant].dataPoints++;
        aggregatedData[key][variant].effects.push(parseFloat(mb.boost1Effect1));
      }
      if (mb.boost1Effect2) {
        aggregatedData[key][variant].dataPoints++;
        aggregatedData[key][variant].effects.push(parseFloat(mb.boost1Effect2));
      }
      if (mb.boost1Effect3) {
        aggregatedData[key][variant].dataPoints++;
        aggregatedData[key][variant].effects.push(parseFloat(mb.boost1Effect3));
      }
    });

    const result = [];

    for (const key in aggregatedData) {
      for (const variant in aggregatedData[key]) {
        const {dataPoints, effects} = aggregatedData[key][variant];
        const average = effects.length ? effects.reduce((sum: any, val: any) => sum + val, 0) / effects.length : 0;
        const min = effects.length ? Math.min(...effects) : 0;
        const max = effects.length ? Math.max(...effects) : 0;

        result.push({
          'Variant tested': {name: 'Variant tested', value: variant},
          'Data points': {name: 'Data points', value: dataPoints},
          'Average relative activity of multivalent booster*': {
            name: 'Average relative activity of multivalent booster*',
            value: effects.length ? `${average.toFixed(2)} (${min.toFixed(2)} - ${max.toFixed(2)})` : 'N/A'
          }
        });
      }
    }

    return result;
  }
}
