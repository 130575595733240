// @ts-nocheck
import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {VariantDataset, AssayOverview} from '../../../models';
import {DatasetResult} from '../../../models/dtos/variant/datasets-ql';
import {AssayResult} from '../../../models/api/assay/assay-ql';
import {Assay} from '../../../models/dtos/assay/assay';
import {TherapeuticActivityEdge} from '../../../models/therapeutics/therapeutic-activity-ql';
import {TherapeuticActivity} from '../../../models/therapeutics/therapeutic-activity';
import {ActivityChartEdge} from '../../../models/dtos/activity/activity-chart-ql';
import {ActivityChartPoint} from '../../../models/dtos/activity/activity-chart';
import {LineageEdge} from '../../../models/dtos/lineage/lineage-ql';
import {Lineage} from '../../../models/lineage/lineage';
import {DrugEdge} from '../../../models/drug-ql';
import {Drug} from '../../../models/drug';
import {InVivoEdge} from '../../../models/in-vivo/invivo-ql';
import {InVivoPoint} from '../../../models/in-vivo/invivo';
import {ResourceEdge} from '../../../models/related-resources/resource-ql';
import {ResourceEntry} from '../../../models/related-resources/resource';
import {OosDataEdge} from '../../../models/oos/oos-data-ql';
import {OosDataset} from '../../../models/oos/oos-data';

@Injectable({
  providedIn: 'root'
})
export class VariantMapperService {
  constructor() {}

  // NEW METHOD USING THE VIEW MODEL OF PURE JSON DATA - no nodes or edges
  public mapToDatasets(data: any[]): VariantDataset[] {
    if (!data || !Array.isArray(data)) return [];

    return data.map((item) => {
      const variants = item.variants.map((variant) => ({
        drugName: variant.drug_name,
        drugActivityNumericFold: variant.drug_activity1_numeric_fold,
        viralLineageFull: variant.viral_lineage_full,
        viralLineage: variant.viral_lineage,
        viralType: variant.viral_type,
        viralProteinFullPartial: variant.viral_protein_full_partial,
        dataTitle: variant.data_title,
        drugClass: variant.drug_class,
        viralAaMutation: variant.viral_aa_mutation,
        dataSourceType: variant.data_source_type,
        assayType: variant.assay_type,
        dataUpdatedDate: variant.data_updated_date,
        drugSponsored: variant.drug_sponsored,
        dataDate: variant.data_date,
        reportNumber: variant.report_number,
        drugRef: variant.drug_ref,
        viralSublineage: variant.viral_sublineage,
        cdcVariant: variant.cdc_variant,
        viralName: variant.viral_name,
        assayCellLine: variant.assay_cell_line,
        activitySummary: variant.activity_summary
      }));

      // Extracting rollup arrays
      const first = variants[0]; // First variant

      // Extracting arrays from variants
      const viralLineageCombos = variants.map((v) => v.viralLineage);
      const mutationCombos = variants.map((v) => v.viralAaMutation);
      let viralAgentType = variants.map((v) => v.viralType);
      let therapeuticAgents = variants.map((v) => `${v.drugName} (${v.drugClass})`);
      let assayTypes = variants.map((v) => v.assayType);
      let assayCellLines = variants.map((v) => v.assayCellLine);
      let dataSourceTypes = variants.map((v) => v.dataSourceType);

      // Filtering and de-duplicating arrays
      viralAgentType = [...new Set(viralAgentType.filter((x) => x !== ''))];
      therapeuticAgents = [...new Set(therapeuticAgents.filter((x) => x !== ''))];
      assayTypes = [...new Set(assayTypes.filter((x) => x !== ''))];
      assayCellLines = [...new Set(assayCellLines.filter((x) => x !== ''))];

      // Extracting unique values for certain properties
      const drugClasses = [...new Set(variants.map((v) => v.drugClass))] as string[];
      const drugNames = [...new Set(variants.map((v) => v.drugName))] as string[];
      const viralTypes = [...new Set(variants.map((v) => v.viralType))] as string[];
      const viralProteinFullPartials = [...new Set(variants.map((v) => v.viralProteinFullPartial))] as string[];
      const viralLineageFull = [...new Set(variants.map((v) => v.viralLineageFull))] as string[];

      const datePipe = new DatePipe('en-US');

      return {
        reportId: item.report_number,
        reportName: item.data_title,
        dateReported: datePipe.transform(item.data_date, 'MM/dd/yyyy'),
        dataSource: item.data_source,
        dataTitle: item.data_title,
        dataDate: item.data_date,
        dataProvider: item.data_provider,
        fileName: item.file_name,
        numView: item.num_view,
        numDownload: item.num_download,
        dateUploaded: datePipe.transform(item.date_uploaded, 'MM/dd/yyyy'),
        //dataClob: item.data_clob,  NOT USED AND TOO BIG
        dataUpdatedDate: item.data_updated_date,
        dataSource2: item.data_source_2,
        collectionStatus: item.collection_status,
        fileName2: item.file_name_2,
        dataFileDownloadUrl: item.data_file_download_url,
        dataSourceUrls: item.data_source_urls,
        variants,
        dataSourceTypes,
        viralAgentType,
        therapeuticAgents,
        assayTypes,
        assayCellLines,
        dataSourceTypes,
        drugClasses,
        drugNames,
        viralTypes,
        viralProteinFullPartials,
        viralLineageFull,
        viralLineages: this.cleanViralLineage(viralLineageCombos),
        mutations: this.cleanMutations(mutationCombos)
      };
    });
  }

  // OLD METHOD WHEN FETCHING USING GRAPHQL - could not get this to work with child data
  public mapToDatasetsGRAPHQL(dataset: DatasetResult): VariantDataset[] {
    if (!dataset || !dataset.edges) return null;

    return dataset.edges.map((datasetEdge) => {
      const first = datasetEdge.node.variants.edges[0]?.node;
      const viralLineageCombos = datasetEdge.node.variants.edges.map((e) => e.node.viralLineage);
      const mutationCombos = datasetEdge.node.variants.edges.map((e) => e.node.viralAaMutation);
      let viralAgentType = datasetEdge.node.variants.edges.map((e) => e.node.viralType);
      let therapeuticAgents = datasetEdge.node.variants.edges.map((e) => `${e.node.drugName} (${e.node.drugClass})`);
      let assayTypes = datasetEdge.node.variants.edges.map((e) => e.node.assayType);
      let assayCellLines = datasetEdge.node.variants.edges.map((e) => e.node.assayCellLine);

      const viralLineages = this.cleanViralLineage(viralLineageCombos);
      const mutations = this.cleanMutations(mutationCombos);
      viralAgentType = [...new Set(viralAgentType.filter((x) => x !== ''))];
      therapeuticAgents = [...new Set(therapeuticAgents.filter((x) => x !== ''))];
      assayTypes = [...new Set(assayTypes.filter((x) => x !== ''))];
      assayCellLines = [...new Set(assayTypes.filter((x) => x !== ''))];

      const drugClasses = [...new Set(datasetEdge.node.variants.edges.map((e) => e.node.drugClass))] as string[];
      const drugNames = [...new Set(datasetEdge.node.variants.edges.map((e) => e.node.drugName))] as string[];
      const viralTypes = [...new Set(datasetEdge.node.variants.edges.map((e) => e.node.viralType))] as string[];
      const viralProteinFullPartials = [
        ...new Set(datasetEdge.node.variants.edges.map((e) => e.node.viralProteinFullPartial))
      ] as string[];
      const viralLineageFull = [
        ...new Set(datasetEdge.node.variants.edges.map((e) => e.node.viralLineageFull))
      ] as string[];

      const report: VariantDataset = {
        reportId: datasetEdge.node.reportNumber,
        dataSource: datasetEdge.node.dataSource,
        dataSourceUrls: datasetEdge.node.dataSourceUrls,
        uploadedDate: datasetEdge.node.dataUpdatedDate,
        reportName: first?.dataTitle,
        dateReported: first?.dataDate,
        drugClasses,
        drugNames,
        viralTypes,
        viralProteinFullPartials,
        submitter: datasetEdge.node.dataProvider,
        numView: datasetEdge.node.numView,
        dataFileDownloadUrl: datasetEdge.node.dataFileDownloadUrl,
        viralLineages,
        viralAgentType,
        mutations,
        therapeuticAgents,
        assayTypes,
        assayCellLines,
        dataSourceType: first?.dataSourceType,
        viralLineageFull
      };
      return report;
    });
  }

  public cleanMutations(mutationCombos: string[]): string[] {
    mutationCombos = [...new Set(mutationCombos.filter((x) => x !== ''))];
    let mutations: string[] = [];
    mutationCombos.forEach((mc) => {
      if (mc) {
        let mcArray = mc.split(',');
        mcArray = mcArray.map((m) => m.trim());
        mutations = [...mutations, ...mcArray];
      }
    });
    return [...new Set(mutations.filter((x) => x !== ''))];
  }
  public cleanViralLineage(viralLineageCombos: string[]): string[] {
    viralLineageCombos = [...new Set(viralLineageCombos.filter((x) => x !== ''))];
    let viralLineages: string[] = [];
    viralLineageCombos.forEach((lc) => {
      let linArray = lc.split(',');
      linArray = linArray.map((l) => l.trim());
      viralLineages = [...viralLineages, ...linArray];
    });
    return [...new Set(viralLineages.filter((x) => x !== ''))];
  }

  public mapToAssays(dataset: AssayResult): Assay[] {
    if (!dataset || !dataset.edges) return null;
    return dataset.edges.map((edge) => {
      const assay: Assay = {
        id: edge.node.id,
        category: edge.node.assayType,
        name: edge.node.readout
      };
      return assay;
    });
  }

  // API PYTHON always encodes any column with name 'id' to base64
  // - we need the actual id to build the URL to the icon and figure .png files
  public decodeId(encodedId: string): string {
    const decodedString = atob(encodedId);
    return decodedString.split(':')[1];
  }

  public mapToAssayOverviews(dataset: AssayResult): AssayOverviews[] {
    if (!dataset || !dataset.edges) return null;
    return dataset.edges.map((e) => {
      const assayoverviews: AssayOverview = {
        id: e.node.id,
        assayType: e.node.assayType,
        viralAgents: e.node.viralAgents,
        experimentalMethodology: e.node.experimentalMethodology,
        assayBackground: e.node.assayBackground,
        assayHowitworks: e.node.assayHowitworks,
        assayInterpretation: e.node.assayInterpretation,
        assayLimitations: e.node.assayLimitations,
        assayFigureLegend: e.node.assayFigureLegend,
        assayIconUrl: e.node.assayIconUrl,
        assayFigureUrl: e.node.assayFigureUrl,
        dateUpdated: e.node.dateUpdated
      };
      return assayoverviews;
    });
  }

  // get one single AssayOverview based on 'id'
  public mapToAssayOverview(dataset: AssayOverviewResult): AssayOverview {
    const e = dataset;
    if (e) {
      return {
        id: this.decodeId(e.id),
        assayType: e.assayType,
        viralAgents: e.viralAgents,
        experimentalMethodology: e.experimentalMethodology,
        assayBackground: e.assayBackground,
        assayHowitworks: e.assayHowitworks,
        assayInterpretation: e.assayInterpretation,
        assayLimitations: e.assayLimitations,
        assayFigureLegend: e.assayFigureLegend,
        assayIconUrl: e.assayIconUrl,
        assayFigureUrl: e.assayFigureUrl,
        dateUpdated: e.dateUpdated
      };
    }
    return null;
  }

  public mapToTherapeuticActivity(edges: TherapeuticActivityEdge[]): TherapeuticActivity[] {
    const therapeuticActivities: TherapeuticActivity[] = [];
    edges.forEach((e) => {
      const viralType: TherapeuticActivity = {
        viralLineage: e.node.viralLineage,
        viralType: e.node.viralProteinFullPartial,
        assayType: e.node.assayType,
        activitySummary: e.node.activitySummary,
        drugName: e.node.drugName
      };

      therapeuticActivities.push(viralType);
    });
    return therapeuticActivities;
  }

  public mapToActivityChartPoints(edges: ActivityChartEdge[]): ActivityChartPoint[] {
    const points: ActivityChartPoint[] = [];
    edges.forEach((e) => {
      const point: ActivityChartPoint = {
        viralLineage: e.node.viralLineage,
        viralType: e.node.viralType,
        drugName: e.node.drugName,
        dataSourceUrls: e.node.dataSourceUrls,
        drugActivity1NumericFold: e.node.drugActivity1NumericFold,
        viralProteinFullPartial: e.node.viralProteinFullPartial,
        dataTitle: e.node.dataTitle,
        viralAaMutation: e.node.viralAaMutation,
        drugClass: e.node.drugClass,
        dataSourceType: e.node.dataSourceType,
        assayType: e.node.assayType,
        dataUpdatedDate: e.node.dataUpdatedDate,
        drugSponsored: e.node.drugSponsored,
        dataDate: e.node.dataDate,
        viralLineageFullName: e.node.viralLineageFullName,
        reportNumber: e.node.reportNumber,
        drugRef: e.node.drugRef,
        viralSublineage: e.node.viralSublineage,
        viralName: e.node.viralName
      };

      points.push(point);
    });
    return points;
  }

  public mapToInVivoPoints(edges: InVivoEdge[]): InVivoPoint[] {
    return edges.map((x) => x.node);
  }

  public mapToResourceEntries(edges: ResourceEdge[]): ResourceEntry[] {
    return edges.map((x) => x.node);
  }

  public mapToOosDatasets(edges: OosDataEdge[]): OosDataset[] {
    return edges.map((x) => x.node);
  }

  public mapToLineages(edges: LineageEdge[]): Lineage[] {
    return edges.map((l) => {
      return {
        id: l.node.id,
        viralLineage: l.node.viralLineage,
        viralClassification: l.node.viralClassification,
        viralRank: l.node.viralRank,
        dateUpdated: l.node.dateUpdated,
        WHOName: l.node.WHOName
      } as Lineage;
    });
  }

  public mapToDrugs(edges: DrugEdge[]): Drug[] {
    return edges.map((d) => {
      return {
        id: d.node.id,
        drugName: d.node.drugName,
        drugClass: d.node.drugClass,
        priority: d.node.priority
      };
    });
  }
}
