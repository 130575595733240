<div class="data-container mt-3">
  <div class="h5">Animal Model Summary Overview</div>
  <app-animal-models-feedback></app-animal-models-feedback>

  <div class="col-12 p-0 py-3" id="small_model_section">
    <div class="row">
      <div class="col-9">
        <h4 class="align-bottom">Small Animals</h4>
      </div>
      <div class="col-3 text-right d-none d-md-block d-lg-block">
        <a href="https://fnih.org/" target="_blank">
          <img width="150" src="./assets/images/fnih.png" alt="FNIH Logo" />
        </a>
      </div>
    </div>
  </div>

  <div class="table-container">
    <table class="table table-sm table-bordered table-header-rotated sortable z-depth-1" id="smallmodel_table">
      <thead>
        <tr title="click to sort">
          <th class="table-header" sortBy="species">Species&nbsp;<mat-icon fontIcon="sort"></mat-icon></th>
          <th class="table-header" style="border: 0px" sortBy="modification">
            Modification&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="table-header" sortBy="nomenclature">
            Model Name/Nomenclature&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="rotate-45 sorttable_nosort" style="border: 0px">
            <div><span>Vaccines</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Antivirals</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Neutralizing&nbsp;Antibodies</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Other&nbsp;Therapies</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Infectivity</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Transmission</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div class="border-right"><span>Disease&nbsp;Enhancement</span></div>
          </th>
          <th
            class="table-header"
            style="border: 0px; padding-left: 32px; min-width: 280px"
            sortBy="diseaseManifestationandPathology"
          >
            Disease&nbsp;Manifestation &amp; Pathology&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="table-header" sortBy="extentofdisease">
            Extent of disease&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let animal of smallAnimals">
          <td class="mw-80">{{ animal.species }}</td>
          <td class="mw-120">{{ animal.modification }}</td>
          <td>
            <a
              [routerLink]="['../small-animals', animal.modelId]"
              target="_blank"
              [innerHTML]="animal.nomenclature"
            ></a>
          </td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.vaccines">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.antivirals">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.neutralizing">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.otherTherapies">√</span></td>
          <td class="check-mark text-center">{{ animal.infectivity }}</td>
          <td class="check-mark text-center">{{ animal.transmission }}</td>
          <td class="check-mark text-center">{{ animal.diseaseEnhancement }}</td>
          <td style="padding-left: 15px">{{ animal.diseaseManifestationAndPathology }}</td>
          <td>{{ animal.extentOfDisease }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" *ngIf="!!smallAnimals && !!smallAnimals[0]">
    <div class="col-md-12">Updated: {{ smallAnimals[0].updated }}</div>
  </div>

  <div class="col-12 p-0 py-3" id="nhp_model_section">
    <div class="row">
      <div class="col-9">
        <h4 class="align-bottom">Non-Human Primates</h4>
      </div>
      <div class="col-3 text-right d-none d-md-block d-lg-block">
        <a href="https://fnih.org/" target="_blank">
          <img width="150" src="./assets/images/fnih.png" alt="FNIH Logo" />
        </a>
      </div>
    </div>
  </div>
  <div class="table-container">
    <table class="table table-sm table-bordered table-header-rotated sortable z-depth-1" id="nhp_table">
      <thead>
        <tr title="click to sort">
          <th class="table-header" sortBy="species">Species&nbsp;<mat-icon fontIcon="sort"></mat-icon></th>
          <th class="table-header" style="border: 0; min-width: 130px" sortBy="geographicOrigin">
            Geographic Origin&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="table-header" sortBy="routeofExposure">
            Route of Exposure&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="rotate-45 sorttable_nosort" style="border: 0">
            <div><span>Vaccines</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Antivirals</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Neutralizing&nbsp;Antibodies</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Other&nbsp;Therapies</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Infectivity</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            <div><span>Transmission</span></div>
          </th>
          <th class="rotate-45 sorttable_nosort">
            loc
            <div class="border-right"><span>Disease&nbsp;Enhancement</span></div>
          </th>
          <th
            class="table-header"
            style="border: 0px; padding-left: 32px; min-width: 280px"
            sortBy="diseaseManifestationandPathology"
          >
            Disease&nbsp;Manifestation &amp; Pathology&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
          <th class="table-header" sortBy="extentofdisease" style="min-width: 130px">
            Extent&nbsp;of&nbsp;disease&nbsp;<mat-icon fontIcon="sort"></mat-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let animal of nonHumanPrimates">
          <td>
            <a [routerLink]="['../nhp', animal.modelId]" target="_blank" [innerHTML]="animal.species"></a>
          </td>
          <td class="mw-120">{{ animal.geographicOrigin }}</td>
          <td class="mw-120">{{ animal.routeOfExposure }}</td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.vaccines">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.antivirals">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.neutralizing">√</span></td>
          <td class="check-mark hilit text-center"><span *ngIf="animal.otherTherapies">√</span></td>
          <td class="check-mark text-center">{{ animal.infectivity }}</td>
          <td class="check-mark text-center">{{ animal.transmission }}</td>
          <td class="check-mark text-center">{{ animal.diseaseEnhancement }}</td>
          <td style="padding-left: 15px">{{ animal.diseaseManifestationAndPathology }}</td>
          <td>{{ animal.extentOfDisease }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" *ngIf="!!nonHumanPrimates && !!nonHumanPrimates[0]">
    <div class="col-md-12">Updated: {{ nonHumanPrimates[0].updated }}</div>
  </div>
  <div><br /><br /></div>
  <div class="row">
    <div class="col-md-12 note">
      <div>
        <strong><i>√</i></strong> = The model can be used to evaluate the noted agent type.
      </div>
      <div>
        <strong><i>Y</i></strong> = Yes
      </div>
      <div>
        <strong><i>N</i></strong> = No
      </div>
      <div>
        <strong><i>TBD</i></strong> = *To be determined (TBD) entries indicate those for which there is either in
        progress relevant research studies or an absence of data in the current literature.
      </div>
      <div>
        <strong><i>Other Therapies</i></strong> = Includes immunomodulators.
      </div>
    </div>
  </div>
</div>