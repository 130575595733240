import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-dataviewer',
  templateUrl: './dataviewer.component.html',
  styleUrls: ['./dataviewer.component.scss']
})
export class DataviewerComponent implements OnInit {
  @Input() variants!: any[];
  @Input() reportNumber!: number;
  @Input() expandableContainer!: any;

  dataActivity: any;

  ngOnInit(): void {
    this.getData();
  }

  // Get data from the passed variants
  getData(): void {
    if (this.variants && this.variants.length > 0) {
      this.dataActivity = this.variants.find((variant) => variant.id === this.reportNumber);
    }
  }
}
