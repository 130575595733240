import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterByDrugclassPipe} from './filter-by-drugclass/filter-by-drugclass.pipe';
import {LineBreak} from './linebreak/linebreak.pipe';
import {SortPipe} from './sort/sort.pipe';
import {SafeHtmlPipe} from './safe-html/safe-html.pipe';

@NgModule({
  declarations: [LineBreak, SortPipe, FilterByDrugclassPipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [LineBreak, SortPipe, FilterByDrugclassPipe, SafeHtmlPipe]
})
export class CoreModule {}
