import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {AssayDetails} from '../../../models/dtos/assay-details/assay-details';
import {AssayDetailsApiService} from '../../../services/api/assay-details-api/assay-details-api.service';
import {DataDownloadRendererComponent} from '../data-download-render/data-download-renderer.component';
import {DataExternalLinkRendererComponent} from '../data-external-link-render/data-external-link-renderer.component';
import {ConcatTSVService} from '../../../services/api/misc/concat-tsv.service';
@Component({
  selector: 'app-assay-data',
  templateUrl: './assay-data.component.html',
  styleUrls: ['./assay-data.component.scss']
})
export class AssayDataComponent implements OnInit {
  public allAssayPoints!: AssayDetails[];
  public selectedAssayPoints!: AssayDetails[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;

  constructor(private variantApi: AssayDetailsApiService, private concatTSVService: ConcatTSVService) {
    this.frameworkComponents = {
      datasourceRenderer: DatasourceRendererComponent,
      dataDownloadRender: DataDownloadRendererComponent,
      dataExternalLinkRender: DataExternalLinkRendererComponent
    };
    this.defaultColDef = {
      width: 200,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };
    this.columnDefs = [
      {
        field: 'assayName',
        headerName: 'Assay Name',
        width: 310,
        sortable: true,
        resizable: true,
        cellRenderer: 'datasourceRenderer'
      },
      {
        field: 'assayType',
        headerName: 'Assay Type',
        width: 120
      },
      {
        field: 'category',
        headerName: 'Target Category'
      },
      {
        field: 'detectionType',
        headerName: 'Detection Type'
      },

      {
        field: 'doi',
        headerName: 'Associated Publication',
        cellRenderer: 'dataExternalLinkRender',
        flex: 1 // This line tells ag-Grid to make this column fill the remaining space
      },
      {
        field: 'assayDataFileUrl',
        headerName: 'Data',
        width: 80,
        cellRenderer: 'dataDownloadRender'
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public markChecked = (params: any) => {
    return params.value === 'checked' || params.value === 'yes' ? '<i class="fas fa-check"></i>' : params.value;
  };

  public getTestStrainValue = (params: any) => {
    return params.data.animalModelsLinkedPage
      ? `<a target="_blank" href="${params.data.animalModelsLinkedPage}">${params.value}</a>`
      : params.value;
  };

  public ngOnInit(): void {
    const AssayDataPoints$ = this.variantApi.getAssayDetails();
    AssayDataPoints$.subscribe((AssayDataPoints) => {
      this.allAssayPoints = AssayDataPoints;
      this.selectedAssayPoints = AssayDataPoints;
      this.rowCount = this.selectedAssayPoints.length;
      this.totalRows = this.allAssayPoints.length;
    });
  }
}
