<div class="" [hidden]="dataLoading">
  <!-- Start of multifilter controls potentailly removeThis -->
  <div class="row">
    <div class="col-12">
      <div class="variant-list note">
        <ng-container *ngIf="subLineages.length > 0">
          <div class="prefiltered-msg">Select a Pre-filtered Activity View</div>
          <div class="predefined-view">
            <button
              class="lineage-btn p-2-5"
              (click)="selectView('B.1.1.529')"
              [ngClass]="{active: activeLineage.viralLineage === 'B.1.1.529'}"
            >
              Omicron
            </button>
            <button
              class="lineage-btn p-2-5"
              (click)="selectView('Single mutation variant')"
              [ngClass]="{active: activeLineage.viralLineage === 'Single mutation variant'}"
            >
              Single Mutation Variants
            </button>
            <button
              class="lineage-btn p-2-5"
              (click)="selectView('All Variants')"
              [ngClass]="{active: activeLineage.viralLineage === 'All Variants'}"
            >
              All Variants
            </button>
          </div>
          <div class="or-msg">-OR-</div>
          <div class="build-msg">Build Your Own Activity View</div>
          <button
            class="pt-2 btn prefiltered-btn"
            (click)="selectView('Custom View')"
            [ngClass]="{active: activeLineage.viralLineage === 'Custom View'}"
          >
            Select Variant(s)
          </button>
          <div *ngIf="activeLineage.viralLineage === 'Custom View'">
            <div class="pt-2">
              <div class="d-flex placeholder-space">
                <div class="pr-1" *ngFor="let lineage of selectedSubLineages">
                  <button class="lineage-btn btn sublineage-button">
                    {{ lineage }} <i (click)="removeSubLin(lineage)" class="fas fa-times"></i>
                  </button>
                </div>
                <div *ngIf="selectedSubLineages.length > 0">
                  <button class="lineage-btn btn clear-btn" (click)="clearSublineages()">CLEAR ALL VARIANTS</button>
                  <button class="lineage-btn btn apply-btn" (click)="loadLineage(activeLineage)">
                    VIEW VARIANT ACTIVITY
                  </button>
                </div>
              </div>
            </div>
            <input
              class="auto-complete"
              type="text"
              placeholder="Search for a sublineage"
              [formControl]="selectedSubLineagesControl"
              [matAutocomplete]="auto2"
            />
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="setSelectedSubLineages($event.option.value)">
              <mat-option *ngFor="let subLin of filteredSubLineages | async" [value]="subLin.name">
                {{ subLin.name }} ({{ subLin.count }})
                <mat-icon *ngIf="selectedSubLineages.includes(subLin.name)">check</mat-icon>
              </mat-option>
            </mat-autocomplete>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row banner-container">
    <div class="col-12">
      <div class="mt-2 pb-1 pt-2 banner-warning">
        <div>
          <b>NOTE:</b> the list of therapeutics shown below has been pre-filtered to improve readability, so not all
          agents are shown by default. To see the full list of therapeutics, please select <b>"All"</b> from the
          <b>THERAPEUTICS SHOWN</b> filter below to the left.”
        </div>
      </div>
    </div>
    <img src="./assets/images/filter-arrow.png" class="filter-arrow" alt="" />
  </div>
  <div class="row">
    <div class="col-12">
      <div class="pl-3 pb-3">
        <div class="chart-header h4">
          <span class="lineage-title font-weight-bold" *ngIf="!!activeLineage">{{ activeLineage.viralLineage }}</span> |
          Reported <span class="font-italic">in vitro</span> Therapeutic Activity
        </div>
        <div class="d-flex">
          <div class="font-italic" *ngIf="!!activeLineage">{{ activeLineage.WHOName }}</div>
          <!-- incase above doesn't work removeThis <div class="font-italic" *ngIf="!!activeLineage">{{ activeLineage.whoName }}</div> -->
          <div *ngIf="!!classification && !!activeLineage">
            <span
              *ngIf="
                activeLineage?.viralLineage !== 'All Variants' &&
                activeLineage?.viralLineage !== 'What\'s New?' &&
                activeLineage?.viralLineage !== 'Single mutation variant'
              "
              class="v-class"
              [ngStyle]="{color: classification.color, 'border-color': classification.color}"
              >{{ classification.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <app-activity-filters
        [mutations]="mutations"
        [filterGroups]="filterGroups"
        [filterResults]="initialFilterState"
        (changePointFilters)="changePointFilters($event)"
        (changeDrugFilters)="changeVisibleDrugs($event)"
      ></app-activity-filters>
    </div>
    <div class="col-10 d-flex pb-4">
      <div class="w100 pb-4 mt-2 position-relative center-container">
        <app-legend
          [activeLineage]="activeLineage"
          [variants]="displayLineages"
          (variantSelected)="legendVariantClicked($event)"
          [dataPoints]="displayPoints"
          [subvariants]="subvariants"
          (setSubLegend)="setSubLegend($event)"
          (subvariantSelected)="setSubvariant($event)"
        ></app-legend>
        <div #chart></div>
        <div class="chart-footer">
          <img src="./assets/images/ncats.png" class="ncats-img" alt="" />
          <span class="today">{{ today | date : 'M.d.yyyy' : 'UTC' }}</span>
        </div>
        <div class="beeswarm" #bChart></div>
        <div *ngIf="lineageLoading" class="loader-container">
          <div class="backdrop"></div>
          <app-loader [showText]="false" class="lineage-loader w100 position-absolute"></app-loader>
        </div>
      </div>
      <app-selected-point-panel
        [selectedDataPoint]="selectedDataPoint | async"
        [neighbors]="neighbors | async"
        [colorByVariant]="colorByLineage"
      ></app-selected-point-panel>
    </div>
  </div>
</div>
<ng-container *ngIf="dataLoading">
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
</ng-container>
