import {Injectable} from '@angular/core';
import {Heatmap, HeatmapBin, HeatmapDrug} from '../../../models/view-models/heatmap';
import * as Vals from '../../../constants/ui-constants';
import {DrugClassDensity} from 'projects/covid19/src/lib/models/dtos/variant/data-density';
import {Lineage} from 'projects/covid19/src/lib/models/dtos/lineage/lineage';
@Injectable({
  providedIn: 'root'
})
export class HeatmapMapperService {
  private drugGroupsNameMap: Map<string, [string, number]>;
  constructor() {
    this.drugGroupsNameMap = new Map([
      ['Vaccine', ['Vaccines', 2]],
      ['Convalescent plasma', ['Convalescent plasma', 1]],
      ['Antiviral', ['Antivirals', 3]],
      ['Neutralizing antibody', ['Antibodies', 0]]
    ]);
  }

  public getCharts(allLineages: any[], densities: DrugClassDensity[], allLineagesRaw: Lineage[]): Heatmap[] {
    const heatmaps: Heatmap[] = [];
    densities.sort(
      (a, b) =>
        (this.drugGroupsNameMap.get(a.drugClass)?.[1] ?? 0) - (this.drugGroupsNameMap.get(b.drugClass)?.[1] ?? 0)
    );
    densities.forEach((classGroup) => {
      const flatDrugList = classGroup.variants.reduce((acc: {drugName: string; priority: string}[], b) => {
        return [
          ...new Set([
            ...acc,
            ...b.drugs.map((d) => ({
              drugName: d.drugName,
              priority: d.priority
            }))
          ])
        ];
      }, []);
      const drugMap = new Map<string, HeatmapDrug>(flatDrugList.map((d) => [d.drugName, d]));
      const drugs = [...drugMap.values()];

      const entryMap = new Map<string, Map<string, HeatmapBin>>();
      allLineages.forEach((l) => {
        const dMap = new Map<string, HeatmapBin>();
        entryMap.set(l.viralLineage, dMap);
        drugs.forEach((d) => {
          const {dataset, visualization} = this.getExternalLinks(
            'activity',
            l.viralLineage,
            d.drugName,
            l.viralClassification
          );
          const defaultSquare: HeatmapBin = {
            lineageName: l.viralLineage,
            density: 0,
            drugName: d.drugName,
            datasetCount: 0,
            pointCount: 0,
            viralClassification: l.viralClassification,
            datasetLink: dataset,
            activityDataLink: visualization
          };
          dMap.set(d.drugName, defaultSquare);
        });
      });
      let maxPoints = 0;
      classGroup.variants.forEach((l) => {
        const lLookup = entryMap.get(l.viralLineage);
        const lineageName = l.viralLineage === 'Single mutation variant' ? 'Single mutation' : l.viralLineage;
        l.drugs.forEach((d) => {
          maxPoints = +d.density > maxPoints ? +d.density : maxPoints;
          const entry = entryMap.get(lineageName)?.get(d.drugName);
          if (entry) {
            entry.density = +d.density;
            entry.datasetCount = +d.datasetCount;
            entry.pointCount = +d.density;
          }
        });
      });
      const entries = [...entryMap.values()].reduce((acc: HeatmapBin[], dMap) => {
        return [...acc, ...dMap.values()];
      }, []);
      const heatmap: Heatmap = {
        drugClass: this.drugGroupsNameMap.get(classGroup.drugClass)?.[0] ?? 'na',
        lineages: allLineages,
        drugs,
        entries,
        order: classGroup.order,
        maxPoints
      };
      heatmaps.push(heatmap);
    });
    return heatmaps;
  }

  public getExternalLinks(
    route: string,
    viralLineage: string,
    drugName: string,
    classification: string
  ): {dataset: string; visualization: string} {
    const currentUrl = window.location.href as string;
    const parts = currentUrl.split('/');
    const datasetParts = [...parts];
    datasetParts.splice(
      datasetParts.indexOf(route),
      datasetParts.length - datasetParts.indexOf(route),
      `datasets?query=${viralLineage}&and=${drugName}`
    );
    const dataset = datasetParts.join('/');
    let lineageRef = viralLineage.replace(/\./g, '').replace(/\//g, '').replace(/ /g, '').toLowerCase();
    if (lineageRef === 'singlemutation') {
      lineageRef = 'singlemutationvariant';
    }
    const activityLinkParts = [...parts];
    activityLinkParts.splice(
      activityLinkParts.indexOf(route),
      activityLinkParts.length - activityLinkParts.indexOf(route),
      `activity/${lineageRef}`
    );
    let visualization = activityLinkParts.join('/');

    if (
      classification.toLocaleLowerCase() !== Vals.variantOfConcern &&
      classification.toLocaleLowerCase() !== Vals.variantOfInterest &&
      classification.toLocaleLowerCase() !== Vals.otherVariant &&
      classification.toLocaleLowerCase() !== Vals.variantBeingMonitored
    ) {
      visualization = '';
    }
    return {dataset, visualization};
  }
}
