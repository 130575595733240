<table *ngIf="reportNumber" class="mb-3 w-100">
  <tbody class="my-3">
    <ng-container>
      <!--- Therapeutic Items-->
      <ng-container>
        <div class="inner-table pt-3">
          <table class="table">
            <thead>
              <tr>
                <th class="py-1" style="border-top: 0; border-bottom: 2px solid #000" scope="col">Lineage</th>
                <th class="py-1" style="border-top: 0; border-bottom: 2px solid #000" scope="col">Therapeutic</th>
                <th class="py-1" style="border-top: 0; border-bottom: 2px solid #000" scope="col">Reported Activity</th>
                <th class="py-1" style="border-top: 0; border-bottom: 2px solid #000" scope="col">Assay Type</th>
                <th class="py-1" style="border-top: 0; border-bottom: 2px solid #000" scope="col">Viral Type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let variant of variants; index as i">
                <td class="py-1 viralLineage">{{ variant.viralLineage }}</td>
                <td class="py-1">{{ variant.drugName }}</td>
                <td class="py-1" style="background: #f2fcff">{{ variant.activitySummary }}</td>
                <td class="py-1">{{ variant.assayType }}</td>
                <td class="py-1">{{ variant.viralType }}</td>
                <!-- Debugging placeholders to ensure data is displayed -->
                <!-- td class="py-1">{{ variant | json }}</td -->
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </tbody>
</table>
