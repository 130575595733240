<h4 class="font-weight-bold my-4">Tested variants & mutations, by assay</h4>
<div class="mt-3">
  <div class="mb-5" *ngFor="let item of mutations | keyvalue">
    <h5 class="mb-4 font-weight-bold">{{ item.key }}&nbsp;&nbsp;{{ item.value[0].variantWhoName }}</h5>
    <ng-container *ngFor="let mutation of item.value">
      <div class="row mb-3" *ngIf="mutation.assayType">
        <div class="col-2">{{ mutation.assayType }}</div>
        <div class="col-9">
          <div class="row">
            <div class="col-2 w-150 spike spike-header">Spike</div>
            <div class="col-10 spike">{{ mutation.variantMutationSpike }}</div>
          </div>
          <div class="row">
            <div class="col-2 w-150 spike-header">Non-Spike</div>
            <div class="col-10">{{ mutation.variantMutationNonspike }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <hr />
  </div>
</div>
