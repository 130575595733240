import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApolloModule} from 'apollo-angular';
import {HttpClientModule} from '@angular/common/http';
import {FeaturesModule} from './features/features.module';
import {API_URLS, AppConfig, APP_CONF, EventService, EVENT_SERVICE, GraphQLModule} from '@odp/shared';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FeaturesModule,
    CommonModule,
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    GraphQLModule.forRoot()
  ],
  providers: [
    {
      provide: API_URLS,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.odp;
      },
      deps: [APP_CONF]
    },
    {provide: EVENT_SERVICE, useClass: EventService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
