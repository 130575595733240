import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-assay-grid',
  templateUrl: './assay-grid.component.html',
  styleUrls: ['./assay-grid.component.scss']
})
export class AssayGridComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic Assay Variant Studies');
  }
}
