import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DownloadFilesQL} from '../../../queries/downloadfiles-query';
import {map, Observable} from 'rxjs';
import {DownloadFiles} from '../../../models/dtos/download-files/downloadfiles';
import {API_URLS, Endpoints} from '@odp/shared';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {InMemoryCache} from '@apollo/client/core';
import {DownloadFilesQuery, DownloadFilesEdge} from '../../../models/dtos/download-files/downloadfiles-ql';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesApiService {
  private variantBaseUrl!: string;
  configService: any;

  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private DownloadFilesQL: DownloadFilesQL
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri: uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getAllFiles(): Observable<DownloadFiles[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<DownloadFilesQuery>({query: this.DownloadFilesQL.document})
      .pipe(
        map((result: {data: DownloadFilesQuery}) => {
          return this.mapDownloadFilesToEntry(result.data.files.edges);
        })
      );
  }

  public getFile(name: string): Observable<DownloadFiles[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<DownloadFilesQuery>({query: this.DownloadFilesQL.getFile, variables: {fileName: name}})
      .pipe(
        map((result: {data: DownloadFilesQuery}) => {
          return this.mapDownloadFilesToEntry(result.data.files.edges);
        })
      );
  }

  public mapDownloadFilesToEntry(fileEntry: DownloadFilesEdge[]): DownloadFiles[] {
    const enteries = fileEntry.map((e) => {
      const file: DownloadFiles = {
        id: e.node.id,
        name: e.node.path,
        path: e.node.path
      };
      return file;
    });
    return enteries;
  }
}
