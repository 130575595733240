import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {DownloadFilesQuery} from '../models/dtos/download-files/downloadfiles-ql';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesQL extends Query<DownloadFilesQuery, {}> {
  override document = gql`
    {
      files {
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  `;

  getFile = gql`
    query files($fileName: String!) {
      files(name: $fileName) {
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  `;
}
