import {Component, OnInit} from '@angular/core';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {TraceDataset} from '../../../models/dtos/trace';
import {TraceApiService} from '../../../services/api/trace-api/trace-api.service';
import {TraceLineageHeaderComponent} from '../trace-lineage-header/trace-lineage-header.component';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-trace-variant-data',
  templateUrl: './trace-variant-data.component.html',
  styleUrls: ['./trace-variant-data.component.scss']
})
export class TraceVariantDataComponent implements OnInit {
  public gridData!: any[];
  public defaultColDef: any;
  public columnDefs: any;
  public frameworkComponents: any;
  private gridApi!: GridApi;
  constructor(private traceApi: TraceApiService, private titleService: Title) {
    this.frameworkComponents = {lineageHeader: TraceLineageHeaderComponent};
    this.titleService.setTitle('TRACE Variant Testing');
  }

  private initColDefs(traceApiData: TraceDataset) {
    this.columnDefs = [
      {
        field: 'assayType',
        headerName: 'Assay Type',
        width: 205,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        field: 'therapeuticAgent',
        headerName: 'Therapeutic Agent',
        width: 185,
        filter: true,
        sortable: true,
        resizable: true,
        cellStyle: {'font-weight': 'bold'}
      },
      {
        field: 'cellLine',
        headerName: 'Cell Line',
        width: 220,
        filter: true,
        resizable: true
      }
    ];
    const lineageGroupCol = {
      headerName: 'Viral Lineages',
      children: []
    };
    traceApiData.lineages.forEach((lineage) => {
      this.columnDefs.push({
        field: `_lin_${this.normalizeText(lineage.lineageName)}`,
        headerName: lineage.lineageName,
        headerClass: 'ag-right-aligned-header',
        children: [
          {
            field: `_lin_${this.normalizeText(lineage.lineageName)}`,
            headerName: lineage.whoName ?? '',
            cellRenderer: this.formatNumber,
            width: 117,
            sortable: true,
            resizable: true,
            cellStyle: {'text-align': 'right'},
            headerClass: 'ag-right-aligned-header'
          }
        ]
      });
    });
  }

  public downloadCsv() {
    this.gridApi.exportDataAsCsv();
  }

  private mapLineagesToColumns(unmappedData: TraceDataset) {
    const mappedRows: any[] = [];
    unmappedData.rows.forEach((row) => {
      const rowData = {...row};
      unmappedData.lineages.forEach((lineage) => {
        const linCol = row.lineages.find((l) => l.name === lineage.lineageName);
        const normalizedName = this.normalizeText(lineage.lineageName);
        let foldVal = null;
        if (linCol?.foldChange) {
          if (linCol.stdev) {
            foldVal = `${linCol.foldChange} +/- ${linCol?.stdev}`;
          } else {
            foldVal = linCol.foldChange;
          }
        }
        // @ts-ignore
        rowData[`_lin_${normalizedName}`] = foldVal ?? '-';
      });
      mappedRows.push(rowData);
    });
    return mappedRows;
  }
  private normalizeText(text: string): string {
    return text.replace(/\./g, '').replace(/\?/g, '').replace(/\'/g, '').replace(/\//g, '').replace(/ /g, '');
  }
  public formatNumber = (params: any) => {
    return isNaN(params.value) ? params.value : (+params.value).toFixed(2);
  };
  public onGridReady(params: any) {
    this.gridApi = params.api;
  }
  ngOnInit(): void {
    this.traceApi.getTraceDataset().subscribe((traceData) => {
      this.initColDefs(traceData.data);
      this.gridData = this.mapLineagesToColumns(traceData.data);
    });
  }
}
