import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {AssayOverviews} from '../../../models/dtos/trace';
import {TraceAssayApiService} from '../../../services/api/trace-api/trace-api-new.service';
@Component({
  selector: 'app-trace-assay',
  templateUrl: './trace-assay.component.html',
  styleUrls: ['./trace-assay.component.scss']
})
export class TraceAssayComponent implements OnInit {
  assayTrace: _.Dictionary<AssayOverviews[]> | null = null;
  constructor(private traceApi: TraceAssayApiService) {}

  ngOnInit(): void {
    this.traceApi.getTraceAssay().subscribe((assayData) => {
      this.assayTrace = _.groupBy<AssayOverviews>(assayData, (viral) => {
        return viral.assayCategory;
      });
    });
  }
}
