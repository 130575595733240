import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {NonHumanPrimateModelDetailsResponse} from '../../models/dtos/animal/non-human-primate';

@Injectable({
  providedIn: 'root'
})
export class NonHumanPrimateDetailsQL extends Query<NonHumanPrimateModelDetailsResponse, {}> {
  override document = gql`
    query NonHumanPrimateModelDetails($modelId: Int!) {
      nonhumanprimatebackgroundss(modelId: $modelId) {
        edges {
          node {
            modelId
            species
            geographicOrigin
            viralStrain
            passage
            routeOfExposure
            viralDose
            infectivity
            transmission
            comorbidities
            diseaseManifestationAndPathology
            clinicalSigns
            extentOfDisease
            vendor
            status
            modelDescription
            updated
          }
        }
      }
      nonhumanprimatemodelbgreferencess(modelId: $modelId) {
        edges {
          node {
            modelId
            species
            primaryReference
            authors
            publisher
            doi
            isPrimary
          }
        }
      }
      nonhumanprimatemodelendpointss(modelId: $modelId) {
        edges {
          node {
            modelId
            species
            clinicalObservation
            imaging
            clinicalLabMeasures
            virusReplication
            seroConversion
            grossPathology
            histopathology
            biomarkers
            endpointSummary
            updated
          }
        }
      }
    }
  `;
}
