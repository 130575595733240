<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container">
  <div class="col-12 p-0 py-3">
    <p>
      <a [routerLink]="['/']">Home </a> > <a routerLink="../../summary">Animal Models</a> >
      <a routerLink="../../summary" fragment="small_model_section">Small Animal Model</a> >
      {{ details.background.modelName }}
    </p>
  </div>

  <div class="col-12 p-0 py-3">
    <div class="row">
      <div class="col-9" *ngIf="details.background">
        <h4 class="align-bottom">{{ details.background.modelName }}</h4>
        <div>
          {{ details.background.species.trim() }}<span *ngIf="details.background.species">,</span>
          {{ details.background.modification }}
        </div>
      </div>
      <div class="col-9" *ngIf="!details.background">
        <h4 class="align-bottom">Model Name Not Available</h4>
      </div>
      <div class="col-3 text-right d-none d-md-block d-lg-block">
        <a href="https://fnih.org/" target="_blank">
          <img width="150" src="./assets/fnih.png" alt="FNIH Logo" />
        </a>
      </div>
    </div>
  </div>

  <div class="mt-0 mv-2 container nav-container">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Background">
        <div class="tab-content active background">
          <div *ngIf="details.background; else nodata">
            <div class="row h34">
              <div class="col-md-4"><strong>Modification:</strong></div>
              <div class="col-md-8">{{ details.background.modification }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Viral Strain(s):</strong></div>
              <div class="col-md-8">{{ details.background.viralStrain }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Passage(virus):</strong></div>
              <div class="col-md-8">{{ details.background.passage }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Route of Exposure:</strong></div>
              <div class="col-md-8">{{ details.background.routeOfInfection }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Viral Dose Range:</strong></div>
              <div class="col-md-8">{{ details.background.viralDose }}</div>
            </div>

            <div class="row h34">
              <div class="col-md-4"><strong>Infectivity:</strong></div>
              <div class="col-md-8">{{ details.background.infectivity }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Transmission:</strong></div>
              <div class="col-md-8">{{ details.background.transmission }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Comorbidities:</strong></div>
              <div class="col-md-8">{{ details.background.comorbidities }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Disease Manifestation & Pathology:</strong></div>
              <div class="col-md-8">{{ details.background.diseaseManifestationAndPathology }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Clinical Signs:</strong></div>
              <div class="col-md-8">{{ details.background.clinicalSigns }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Extent of Disease:</strong></div>
              <div class="col-md-8">{{ details.background.extentOfDisease }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Vendor:</strong></div>
              <div class="col-md-8">{{ details.background.vendor }}</div>
            </div>
            <div class="row h34">
              <div class="col-md-4"><strong>Status:</strong></div>
              <div class="col-md-8">{{ details.background.status }}</div>
            </div>
            <div class="row section"><div class="col-md-12 h5">Model Description</div></div>
            <div class="row">
              <div class="col-md-12">{{ details.background.modelDescription }}</div>
            </div>
          </div>

          <div class="row section"><div class="col-md-12 h5">Primary Reference(s)</div></div>
          <div *ngIf="details.primaryReferences && details.primaryReferences.length; else nodata">
            <div *ngFor="let reference of details.primaryReferences">
              <div class="row">
                <div class="col-md-12" style="font-weight: 400">{{ reference.primaryReference }}</div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  {{ reference.authors }} {{ reference.publisher }}, doi:
                  <app-external-link [url]="reference.doi" [text]="reference.doi" class="link"></app-external-link>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div *ngIf="details.additionalReferences && details.additionalReferences.length">
            <div class="row section"><div class="col-md-12 h5">Additional Reference(s)</div></div>
            <div *ngFor="let reference of details.additionalReferences">
              <div class="row">
                <div class="col-md-12" style="font-weight: 400">{{ reference.primaryReference }}</div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  {{ reference.authors }} {{ reference.publisher }}, doi:
                  <app-external-link [url]="reference.doi" [text]="reference.doi" class="link"></app-external-link>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Endpoints">
        <div class="tab-content endpoints" *ngIf="details.endpoint; else nodata">
          <div class="row h34">
            <div class="col-md-3"><strong>Clinical Observation:</strong></div>
            <div class="col-md-9">{{ details.endpoint.clinicalObservation }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Imaging:</strong></div>
            <div class="col-md-9">{{ details.endpoint.imaging }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Clinical Lab Measures:</strong></div>
            <div class="col-md-9">{{ details.endpoint.clinicalLabMeasures }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Virus Replication:</strong></div>
            <div class="col-md-9">{{ details.endpoint.virusReplication }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Seroconversion:</strong></div>
            <div class="col-md-9">{{ details.endpoint.seroConversion }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Gross Pathology:</strong></div>
            <div class="col-md-9">{{ details.endpoint.grossPathology }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Histopathology:</strong></div>
            <div class="col-md-9">{{ details.endpoint.histopathology }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Biomarkers:</strong></div>
            <div class="col-md-9">{{ details.endpoint.biomarkers }}</div>
          </div>
          <div class="row h34">
            <div class="col-md-3"><strong>Endpoint Summary:</strong></div>
            <div class="col-md-9">{{ details.endpoint.endpointSummary }}</div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Example Studies">
        <div class="tab-content studies" *ngIf="details.studies && details.studies.length; else nodata">
          <table class="table table-bordered">
            <thead>
              <th><strong>Example Studies</strong></th>
            </thead>
            <tbody>
              <tr *ngFor="let study of details.studies">
                <td>
                  <div>
                    <h5>{{ study.agent }}</h5>
                  </div>
                  <div style="font-weight: 400">{{ study.title }}</div>
                  <div>
                    {{ study.author }} {{ study.publisher }}, doi:
                    <app-external-link [url]="study.doi" [text]="study.doi" class="link"></app-external-link>
                  </div>
                  <div
                    class="summary-toggle"
                    (click)="studySummaryOpenStates[study.id] = !studySummaryOpenStates[study.id]"
                  >
                    {{ studySummaryOpenStates[study.id] ? 'Hide' : 'Show' }} summary
                  </div>
                  <div class="summary-text" *ngIf="!!studySummaryOpenStates[study.id]">{{ study.summary }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="row">
      <div class="col-md-12">Updated: {{ details.background.updated }}</div>
    </div>
    <hr />
    <em><app-animal-models-feedback></app-animal-models-feedback></em>
  </div>
</div>

<ng-template #nodata>
  <div>Not Available</div>
</ng-template>

<app-footer-view [showCC]="true"></app-footer-view>