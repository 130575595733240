import {Component, Input, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import {PieChartService, PieDataModel} from './pie-chart.service';

@Component({
  selector: 'app-d3-pie',
  templateUrl: './d3-pie.component.html',
  styleUrls: ['./d3-pie.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3PieComponent implements OnInit {
  @Input() pieData: PieDataModel[] = [];

  public chartId;

  constructor(private pieChartService: PieChartService) {
    this.chartId = Math.random();
  }

  ngOnInit() {
    this.pieChartService.onInit(this.pieData);
  }
}
