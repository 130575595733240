<div class="row mt-4">
  <div class="col-md-12">
    <h5>Field Guide: Best Practices for COVID-19 Research in Small Animal Models</h5>
    <div class="mt-2">
      Please visit:
      <app-external-link
        url="https://fnih.org/content/activ-preclinical-working-group-small-animal-model-field-guide-and-video-sessions"
        text="https://fnih.org/content/activ-preclinical-working-group-small-animal-model-field-guide-and-video-sessions"
        class="link"
      ></app-external-link>
      for the PDF field guide and videos.
    </div>
    <div class="mt-4">
      <div class="mt-4 title h5">Abstract</div>
      <div>
        The small animal model videos are a compendium of world expert guidance on best practices for the use of small
        animal models for COVID 19 research. The videos were developed in an effort to support harmonization of best
        practices for the use of small animal models.
      </div>
      <div class="mt-1">
        Since SARS-COV-2 was first reported in late 2019, researchers around the world shifted gears to conduct COVID-19
        studies. However, limited resources, including BSL 3 /4 facilities and COVID-19 animal models, have prompted an
        effort to harmonize and streamline guidance to make the best use of available biomedical research resources and
        facilitate interpretation and comparison of the results across studies. Using the Small Animal Model Field Guide
        and these videos as a guide, researchers will be able to improve harmonization and make the best use of
        available biomedical research resources.
      </div>
    </div>
  </div>
</div>
