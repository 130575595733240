<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container">
  <div class="col-12 p-0 py-3">
    <p><a [routerLink]="['/covid19']">Home </a> > SARS-CoV-2 Screening Data > About NCATS Curve Classes</p>
  </div>

  <div id="legend_interpretation">
    <div class="row">
      <div class="col-md-12">
        <h4 style="font-weight: normal">
          1. Activity category legends on
          <a [routerLink]="['/covid19/databrowser']"><i>OpenData Browser</i></a>
        </h4>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend activity-extra"></div></div>
      <div class="col-md-11">Best quality activities showing strong activity at all testing concentrations</div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend activity-high"></div></div>
      <div class="col-md-11">
        High quality activities with curve class in 1 and 2, |efficacy| &ge; 50, and potency &le; 10 &micro;M
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend activity-low"></div></div>
      <div class="col-md-11">Low quality activities including shallow curves, single point activity, etc</div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend counter-extra"></div></div>
      <div class="col-md-11">
        Best quality activities showing strong activity at all testing concentrations in contraindicated assay
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend counter-high"></div></div>
      <div class="col-md-11">
        High quality activities with curve class in 1 and 2, |efficacy| &ge; 50, and potency &le; 10 &micro;M in
        contraindicated assay
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend counter-low"></div></div>
      <div class="col-md-11">
        Low quality activities including shallow curves and single point activity in contraindicated assay
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend inactive"></div></div>
      <div class="col-md-11">Inactives</div>
    </div>
    <div class="row">
      <div class="col-md-1"><div class="legend untested"></div></div>
      <div class="col-md-11">Untested</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h4 style="font-weight: normal">
        2. <i><b>qHTS</b></i> Curve Classification Criteria
      </h4>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive text-nowrap">
        <table class="table table-sm table-bordered">
          <thead style="border-top: 1px solid #bbb">
            <tr>
              <th>Curve Class</th>
              <th>Description</th>
              <th>Efficacy</th>
              <th>R<sup>2</sup></th>
              <th>Asymptotes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1<sup>&#x0002A;</sup></td>
              <td>
                Complete curve (a)
                <hr />
                Partial curve (b)
              </td>
              <td>
                > 80% (-1.1)
                <hr />
                ≤ 80% (-1.2)
              </td>
              <td>≥ 0.9</td>
              <td>2</td>
            </tr>
            <tr>
              <td>2<sup>&#x02214;</sup></td>
              <td>Incomplete curve</td>
              <td>> 80% (-2.1)<br />< 80% (-2.2)</td>
              <td>&gt; 0.9 (a)<br />&lt; 0.9 (b)</td>
              <td>1</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Single pt activity</td>
              <td>&gt;Min<sup>&#x02213;</sup></td>
              <td>N/A</td>
              <td>1</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Inactive</td>
              <td>N/A</td>
              <td>N/A</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      &#x0002A;AC<sub>50</sub> derived from data.<br />
      <sup>&#x02214;</sup>AC<sub>50</sub> extrapolated from data.<br />
      Min<sup>&#x02213;</sup> is &gt; 3 SD from the mean activity of the sample field at the highest tested
      concentration.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <br />
      <b>Note:</b> In addition there can be noisy curves: Class -1.3 &ge; 80% and r<sup>2</sup> &lt; 0.9 and Class -1.4
      = min – 80% and r<sup>2</sup> &lt; 0.9. Analogously there can be Classes -2.3 and -2.4; Class 5 are unclassified
      curves.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <br />
      <b>Reference:</b>
      <div>
        <app-external-link
          url="https://doi.org/10.1073/pnas.0604348103"
          text="Quantitative high-throughput screening:
        A titration-based approach that efficiently identifies biological activities in large chemical libraries"
          class="external-link"
        ></app-external-link>
      </div>
      <div class="text-muted small">
        James Inglese, Douglas S. Auld, Ajit Jadhav, Ronald L. Johnson, Anton Simeonov, Adam Yasgar, Wei Zheng, and
        Christopher P. Austin
      </div>
      <div class="text-muted small">PNAS August 1, 2006 103 (31) 11473-11478</div>
    </div>
  </div>
</div>
<div class="container-fluid info-content mt-5">
  <div class="container" id="curve_class_example">
    <div class="row row-curve-classes-img">
      <div class="col-md-6">
        <div>
          Curve Class
          <div class="block block-blue text-center">-1.1</div>
          <div class="block block-orange text-center">-1.2</div>
        </div>
        <img src="./assets/images/curve-1.1-1.2.png" class="curve-class-img" />
      </div>
      <div class="col-md-6">
        <div>
          Curve Class
          <div class="block block-blue text-center">-2.1</div>
          <div class="block block-orange text-center">-2.2</div>
        </div>
        <img src="./assets/images/curve-2.1-2.2.png" class="curve-class-img" />
      </div>
    </div>
    <br />
    <div class="row row-curve-classes-img">
      <div class="col-md-6">
        <div>
          Curve Class
          <div class="block block-blue text-center">-3</div>
        </div>
        <img src="./assets/images/curve-3.png" class="curve-class-img" />
      </div>
      <div class="col-md-6">
        <div>
          Curve Class
          <div class="block block-blue text-center">4</div>
        </div>
        <img src="./assets/images/curve4.png" class="curve-class-img" />
      </div>
    </div>
  </div>
</div>

<app-footer-view [showCC]="true"></app-footer-view>
