import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {KeyValue} from '@angular/common';
import {RWEApiService} from '../../../services/api/rwe-api/rwe-api.service';
import {DateRange} from '../../../models/view-models/date-range';
import {RWEPoint} from '../../../models/dtos';

@Component({
  selector: 'app-rwe-filters',
  templateUrl: './rwe-filters.component.html',
  styleUrls: ['./rwe-filters.component.scss']
})
export class RWEFiltersComponent implements OnInit {
  public filterValues: Map<string, string[]> = new Map<string, string[]>();
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public selectedColFilters: Map<string, string[]> = new Map<string, string[]>();
  public dateExpanded = false;
  @Output() filtersChanged: EventEmitter<SelectedRWEFilters> = new EventEmitter<SelectedRWEFilters>();
  public dateRange!: DateRange;
  public dateRangeTxt!: string;
  public filterColumn: string[] = ['Treatment', 'Endpoint', 'Lineage (Variant)'];

  constructor(private RWEApi: RWEApiService) {}

  ngOnInit(): void {
    const getRWE$ = this.RWEApi.getRWE();
    forkJoin([getRWE$]).subscribe(([RWE]) => {
      this.initalizeSelectedFilters();
      this.mapApiFiltersToColumns(RWE);
    });
  }

  private mapApiFiltersToColumns(points: RWEPoint[]) {
    this.initializeFilterEntries();

    const treatmentFilterOptions = Array.from(
      new Set(
        points
          .flatMap((x) => [x.drug1, x.drug2, x.drug3])
          .filter((y) => !!y && y !== 'N/A')
          .sort((a, b) => a.localeCompare(b))
      )
    );

    const endpointFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.hospitalizationEndpoint, x.mortalityEndpoint, x.otherEndpoint])
          .filter((y) => !!y && y !== 'N/A')
      )
    ];

    const endpointFilterLabels = ['Hospitalization', 'Mortality', 'Other'];

    const lineageFilterOptions = [
      ...new Set(
        points
          .map((x) => x.lineage.trim())
          .filter((y) => !!y)
          .sort((a, b) => a.localeCompare(b))
      )
    ];

    this.setColumnValuesFromApi('Treatment', treatmentFilterOptions);
    this.setColumnValuesFromApi('Endpoint', endpointFilterLabels);
    this.setColumnValuesFromApi('Lineage (Variant)', lineageFilterOptions);
  }

  private initializeFilterEntries() {
    for (const col of this.filterColumn) {
      this.filterValues.set(col, []);
      this.filterExpanded.set(col, false);
    }
  }

  private initalizeSelectedFilters() {
    for (const col of this.filterColumn) {
      this.selectedColFilters.set(col, []);
    }
  }

  private setColumnValuesFromApi(columnName: string, recordDB: string | any[]) {
    const dataList = this.filterValues.get(columnName);
    for (const vl of recordDB) {
      if (dataList) {
        dataList.push(vl);
      }
    }
  }
  public changeDate(dateInfo: any) {
    this.dateRange = dateInfo;
    this.emitFilters();
  }

  private emitFilters() {
    this.filtersChanged.emit({
      cols: this.selectedColFilters,
      dateRange: this.dateRange
    });
  }

  public toggleFilter(filterValue: string, column: string) {
    const dataList = this.selectedColFilters.get(column);
    if (!!dataList && dataList.includes(filterValue)) {
      const i = dataList.indexOf(filterValue);
      dataList.splice(i, 1);
      this.emitFilters();
      return;
    }
    if (!!dataList) {
      dataList.push(filterValue);
    }
    this.emitFilters();
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
export interface SelectedRWEFilters {
  cols: Map<string, string[]>;
  dateRange: DateRange;
}
