import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {SelectedHeterologousFilters} from '../heterologous-filters/heterologous-filters.component';
import {HeterologousReportTooltipComponent} from '../heterologous-report-tooltip/heterologous-report-tooltip.component';
import {HeterologousReportTooltipValueComponent} from '../heterologous-report-tooltip/heterologous-report-tooltip-value.component';
import {HeterologousFilterService} from '../heterologous-filter/heterologous-filter.service';
import {HeterologousBoosterPoint} from '../../../models/dtos/heterologous-booster/heterologousbooster';
import {HeterologousBoosterApiService} from '../../../services/api/heterologous-booster-api/heterologous-booster-api.service';
@Component({
  selector: 'app-heterologous-data',
  templateUrl: './heterologous-data.component.html',
  styleUrls: ['./heterologous-data.component.scss']
})
export class HeterologousDataComponent implements OnInit {
  public allHeterologousBoosterPoints!: HeterologousBoosterPoint[];
  public selectedHeterologousBoosterPoints!: HeterologousBoosterPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;

  constructor(
    private variantApi: HeterologousBoosterApiService,
    private HeterologousFilters: HeterologousFilterService
  ) {
    this.frameworkComponents = {
      customTooltip: HeterologousReportTooltipComponent,
      customValueTooltip: HeterologousReportTooltipValueComponent,
      datasourceRenderer: DatasourceRendererComponent
    };
    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };
    this.columnDefs = [
      {
        field: 'hbReportNumber',
        headerName: 'Report Number',
        width: 105,
        sortable: true,
        resizable: true,
        tooltipField: 'hbReportNumber',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'Data Source',
        cellRenderer: 'datasourceRenderer'
      },
      {
        field: 'dataSourceType',
        headerName: 'Data Source Type'
      },
      {
        field: 'sampleSource',
        headerName: 'Sample Source',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'right'}
      },
      {
        field: 'vacPrime',
        headerName: 'Primary Vaccine',
        cellRenderer: this.getTestStrainValue
      },
      {
        field: 'vacBoost1',
        headerName: 'Booster Vaccine'
      },
      {field: 'boost1Class', headerName: 'Booster Class'},
      {field: 'assayCat', headerName: 'Assay Category'},
      {field: 'assayType', headerName: 'Assay Type'},
      {
        field: 'assayTypeMod',
        headerName: 'Assay Modality',
        width: 135,
        cellRenderer: this.markChecked
      },
      {
        field: 'viralSublineage',
        headerName: 'Viral Lineage',
        width: 100,
        tooltipField: 'viralSublineage',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'vaccineActivity',
        headerName: 'Assay Readout',
        width: 150,
        tooltipField: 'vaccineActivity',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Time1Unit',
        headerName: 'Time Point 1',
        width: 125,
        tooltipField: 'boost1Time1Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect1',
        headerName: 'Time Point 1 Effectiveness',
        width: 135,
        cellRenderer: this.markChecked
      },
      {
        field: 'boost1Time2Unit',
        headerName: 'Time Point 2',
        width: 125,
        tooltipField: 'boost1Time2Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect2',
        headerName: 'Time Point 2 Effectiveness',
        width: 125,
        cellRenderer: this.markChecked
      },
      {
        field: 'boost1Time3Unit',
        headerName: 'Time Point 3',
        width: 125,
        tooltipField: 'boost1Time3Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect3',
        headerName: 'Time Point 3 Effectiveness',
        width: 125,
        cellRenderer: this.markChecked
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public markChecked = (params: any) => {
    return params.value === 'checked' || params.value === 'yes' ? '<i class="fas fa-check"></i>' : params.value;
  };

  public getTestStrainValue = (params: any) => {
    return params.data.animalModelsLinkedPage
      ? `<a target="_blank" href="${params.data.animalModelsLinkedPage}">${params.value}</a>`
      : params.value;
  };

  public ngOnInit(): void {
    const HeterologousBoosterDataPoints$ = this.variantApi.getHeterologousBooster();

    forkJoin([HeterologousBoosterDataPoints$]).subscribe(([HeterologousBoosterDataPoints]) => {
      this.allHeterologousBoosterPoints = HeterologousBoosterDataPoints;
      this.selectedHeterologousBoosterPoints = HeterologousBoosterDataPoints;
      this.rowCount = this.selectedHeterologousBoosterPoints.length;
      this.totalRows = this.allHeterologousBoosterPoints.length;
    });
  }

  public filterTable(filters: SelectedHeterologousFilters) {
    this.selectedHeterologousBoosterPoints = this.HeterologousFilters.filterPoints(
      this.allHeterologousBoosterPoints,
      filters
    );
    this.rowCount = this.selectedHeterologousBoosterPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}
