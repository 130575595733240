import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-sam-field-guide',
  templateUrl: './sam-field-guide.component.html',
  styleUrls: ['./sam-field-guide.component.scss']
})
export class SamFieldGuideComponent {
  constructor() {}
}
