<div class="container-fluid hero">
  <div class="container py-4 max-fixed">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Real-World Evidence Studies of COVID-19 Therapeutics
          <span class="btn btn-primary bg-white text-dark border-0 ml-3">Updated {{ lastUpdatedTxt }} </span>
        </h3>

        <ng-template #popContent><div [innerHTML]="popoverTxt"></div></ng-template>
        <div class="pb-4">
          <div>
            Browse high-level summaries of real-world outcomes for EUA/FDA approved and revoked COVID-19 therapeutics.
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="font-size-mid"
              style="color: #ffffff"
              placement="right"
              [ngbPopover]="popContent"
              ngbPopoverTitle="(Which Real-World Evidence studies are being collected here?)"
            >
              Which Real-World Evidence studies are being collected here?
            </a>
          </div>
        </div>

        <a
          href="https://opendata.ncats.nih.gov/public/odp/realworld_evidence.csv"
          class="btn btn-primary bg-white download-all"
          *ngIf="!dataLoading"
        >
          Download real-world evidence dataset here
        </a>
      </div>
    </div>
  </div>
</div>
