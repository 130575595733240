<div class="tab-content" id="resources">
  <div class="row pt-4">
    <div class="col-md-12"><h5>Additional ACTIV Preclinical Resources</h5></div>
  </div>

  <div class="row ">
    <div class="col-md-12">
      <div>
        The ACTIV Preclinical Working Group has also produced the following sources to help researchers develop their
        COVID-19 projects:
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="title">
        1. Field Guide: Considerations for the Use of Primate Models for SARS-CoV-2 Treatments and Vaccines
      </div>
      <div>
        View/Download at:&nbsp;<a href="./assets/pdf/Nonhuman_Primate_Field_Guide_final.pdf" target="_blank">
          Nonhuman Primate Field Guide <img src="./assets/images/pdf.png" style="height:20px"
        /></a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div>
        SARS-CoV-2 burst onto the medical and scientific stage in late 2019, a novel coronavirus that has taken the
        world by storm. One of the most important activities in understanding virus infectivity, life cycle, and
        pathogenicity of a new virus involves the use of animal models. As investigators in academics and in the
        scientific industry grapple with developing effective therapies and vaccines, it is critical to understand how
        different animal models may provide valuable information to inform the science. This Nonhuman Primate Field
        Guide is designed as a companion to the Small Animal Field Guide. The purpose of the Nonhuman Primate Field
        Guide is to inform investigators of the current status of nonhuman primate (NHP) models, the availability of
        resources to perform studies, and to provide some details about experimental design and sampling to guide
        scientific planning.
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="title">2. Field Guide: Best Practices for COVID-19 Research in Small Animal Models</div>
      <div>
        Please visit:
        <app-external-link
          url="https://fnih.org/content/activ-preclinical-working-group-small-animal-model-field-guide-and-video-sessions"
          text="https://fnih.org/content/activ-preclinical-working-group-small-animal-model-field-guide-and-video-sessions"
          class="link"
        ></app-external-link>
        for the PDF field guide and videos.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mt-2 title">Abstract</div>
      <div>
        The small animal model videos are a compendium of world expert guidance on best practices for the use of small
        animal models for COVID 19 research. The videos were developed in an effort to support harmonization of best
        practices for the use of small animal models.
      </div>
      <div class="mt-1">
        Since SARS-COV-2 was first reported in late 2019, researchers around the world shifted gears to conduct COVID-19
        studies. However, limited resources, including BSL 3 /4 facilities and COVID-19 animal models, have prompted an
        effort to harmonize and streamline guidance to make the best use of available biomedical research resources and
        facilitate interpretation and comparison of the results across studies. Using the Small Animal Model Field Guide
        and these videos as a guide, researchers will be able to improve harmonization and make the best use of
        available biomedical research resources.
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="title">3. Accelerated Preclinical Paths to Support Rapid Development of COVID-19 Therapeutics</div>
      <div>
        Grobler, JA, et al. Cell Host & Microbe (2020), doi:
        <app-external-link
          url="https://doi.org/10.1016/j.chom.2020.09.017"
          text="https://doi.org/10.1016/j.chom.2020.09.017"
          class="link"
        ></app-external-link>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mt-2 title">Abstract</div>
      <div>
        When SARS-CoV-2 emerged at the end of 2019, no approved therapeutics or vaccines were available. An urgent need
        for countermeasures during this crisis challenges the current paradigm of traditional drug discovery and
        development, which usually takes years from start to finish. Approaches that accelerate this process need to be
        considered. Here we propose the minimum data package required to move a compound into clinical development
        safely. We further define the additional data that should be collected in parallel without impacting the rapid
        path to clinical development. Accelerated paths for antivirals, immunomodulators, anticoagulants and other
        agents have been developed and can serve as "roadmaps" to support prioritization of compounds for clinical
        testing. These accelerated paths are fueled by a skewed risk-benefit ratio and are necessary to advance
        therapeutic agents into human trials rapidly and safely for COVID-19. Such paths are adaptable to other
        potential future pandemics.
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-12">
      <div class="title">
        4. ACTIVating Resources for the COVID-19 Pandemic: In vivo Models for Vaccines and Therapeutics
      </div>
      <div>
        Hewitt, JA., et al. Cell Host & Microbe (2020), doi:
        <app-external-link
          url="https://doi.org/10.1016/j.chom.2020.09.016"
          text="https://doi.org/10.1016/j.chom.2020.09.016"
          class="link"
        ></app-external-link>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mt-2 title">Abstract</div>
      <div>
        The Preclinical Working Group of Accelerating COVID-19 Therapeutic Interventions and Vaccines (ACTIV), a
        public-private partnership spearheaded by the National Institutes of Health, was charged with identifying,
        prioritizing, and communicating SARS-CoV-2 preclinical resources. Reviewing SARS-CoV-2 animal model data
        facilitates standardization and harmonization and informs knowledge gaps and prioritization of limited
        resources. To date, mouse, hamster, ferret, guinea pig, and non-human primates have been investigated. Several
        species are permissive for SARS-CoV-2 replication, often exhibiting mild disease with resolution, reflecting
        most human COVID-19 cases. More severe disease develops in a few models, some associated with advanced age, a
        risk factor for human disease. This review provides a snapshot that recommends the suitability of models for
        testing vaccines and therapeutics, which may evolve as our understanding of COVID-19 disease biology improves.
        COVID-19 is a complex disease and individual models recapitulate certain aspects of disease; therefore, the
        coordination and assessment of animal models is imperative.
      </div>
    </div>
  </div>
</div>
