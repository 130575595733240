import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {API_URLS, Endpoints} from '@odp/shared';
import {AssayOverviewsQuery, AssayOverviewsNode} from '../../../models/dtos/trace/assay-overviews-ql';
import {AssayOverviewsQL} from '../../../queries/trace/assay-overviews-query.';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {AboutTrace, ReagentDetails, VariantsMutations} from '../../../models/dtos/trace';
import {AboutTraceQuery} from '../../../models/dtos/trace/about-trace-ql';
import {AboutTraceQL} from '../../../queries/trace/about-trace-query';
import {ReagentDetailsQL} from '../../../queries/trace/reagent-details-query.';
import {ReagentDetailsQuery} from '../../../models/dtos/trace/reagent-details-ql';
import {VariantsMutationsQuery} from '../../../models/dtos/trace/variants-mutations-ql';
import {VariantMutationsQL} from '../../../queries/trace/variants-mutations-query.';

@Injectable({
  providedIn: 'root'
})
export class TraceAssayApiService {
  private variantBaseUrl!: string;
  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private TraceAssayQL: AssayOverviewsQL,
    private AboutTraceQL: AboutTraceQL,
    private ReagentDetailsQL: ReagentDetailsQL,
    private VariantsMutationsQL: VariantMutationsQL
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getTraceAssay(): Observable<AssayOverviewsNode[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AssayOverviewsQuery>({query: this.TraceAssayQL.document})
      .pipe(
        map((result) => {
          return result.data.traceassays?.edges?.map((e) => e.node);
        })
      );
  }

  public getAboutTrace(): Observable<AboutTrace[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AboutTraceQuery>({query: this.AboutTraceQL.document})
      .pipe(
        map((result) => {
          return result.data.traceabouts?.edges?.map((e) => e.node);
        })
      );
  }

  public getReagentDetails(): Observable<ReagentDetails[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<ReagentDetailsQuery>({query: this.ReagentDetailsQL.document})
      .pipe(
        map((result) => {
          return result.data.tracereagents?.edges?.map((e) => e.node);
        })
      );
  }

  public getVariantsMutations(): Observable<VariantsMutations[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<VariantsMutationsQuery>({query: this.VariantsMutationsQL.document})
      .pipe(
        map((result) => {
          return result.data.tracemutations?.edges?.map((e) => e.node);
        })
      );
  }
}
