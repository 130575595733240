import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VariantGlossaryComponent} from './glossary.component';
import {SharedModule} from '@odp/shared';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [VariantGlossaryComponent],
  imports: [CommonModule, SharedModule, RouterModule]
})
export class GlossaryModule {}
