import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
@Component({
  selector: 'app-data-external-link-renderer',
  template: ` <app-external-link *ngIf="url" [url]="url" [text]="url"></app-external-link>`,
  styles: ['app-external-link {color: #007bff; cursor: pointer;}  ::ng-deep i {font-size: .7rem;}']
})
export class DataExternalLinkRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public url!: string;

  agInit(params: any): void {
    this.url = params.data.doi as string;
    this.params = params.data;
  }

  refresh(): boolean {
    return false;
  }
}
