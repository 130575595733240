import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import * as _ from 'lodash';
import {Title} from '@angular/platform-browser';
import {RelatedResourceApiService} from '../../services/api/related-resource-api/related-resource-api.service';
import {ResourceEntry} from '../../models/dtos/related-resources/resource';

export interface Label {
  label: string;
  value: ResourceEntry[];
}

@Component({
  selector: 'app-related-resources',
  templateUrl: './related-resources.component.html',
  styleUrls: ['./related-resources.component.scss']
})
export class RelatedResourcesComponent implements OnInit {
  constructor(private titleService: Title, private resourceApi: RelatedResourceApiService) {}

  relatedResources: Label[] = [];
  lastUpdatedTxt: string | null = null;

  ngOnInit(): void {
    this.titleService.setTitle('Variant Therapeutic Related Resources ');
    const getRelatedResources$ = this.resourceApi.getRelatedResourceEntries();
    const metadata$ = this.resourceApi.getRealtedResourceMetadata();
    forkJoin([getRelatedResources$, metadata$]).subscribe(([results, metadata]) => {
      this.relatedResources = _.chain(results)
        .groupBy((resource) => resource.sectionId)
        .map((value, key) => {
          return {
            label: value[0].section,
            value
          };
        })
        .value();
      this.lastUpdatedTxt = metadata.data.dataUploadedDate;
    });
  }
}
