<div class="mt-3">
  <div class="table-header">List of reference reagents</div>
  <div>
    The reagents below have been screened against SARS-CoV-2 variant strains or used to validate the relevant assays.
    <b>Please note that the reagents used are research-grade (not clinical-grade) material.</b>
  </div>
  <br />
  <div class="mb-5" *ngFor="let item of reagentDetails | keyvalue">
    <h4 class="mb-4 font-weight-bold h2">{{ item.key }}</h4>
    <div class="mb-4" *ngFor="let reagent of item.value">
      <h5 class="font-weight-bold">{{ reagent.reagentName }}</h5>
      <div class="row" *ngIf="reagent.reagentCommonName">
        <div class="col-2">Common name</div>
        <div class="col-9">{{ reagent.reagentCommonName }}</div>
      </div>
      <div class="row" *ngIf="reagent.reagentMoa">
        <div class="col-2">Mechanism of action:</div>
        <div class="col-9">{{ reagent.reagentMoa }}</div>
      </div>
      <div class="row" *ngIf="reagent.reagentSource">
        <div class="col-2">Source:</div>
        <div class="col-9">{{ reagent.reagentSource }}</div>
      </div>
      <div class="row" *ngIf="reagent.reagentLot">
        <div class="col-2">Lot:</div>
        <div class="col-9">{{ reagent.reagentLot }}</div>
      </div>
      <div class="row" *ngIf="reagent.reagentTiter">
        <div class="col-2">Titer:</div>
        <div class="col-9">{{ reagent.reagentTiter }}</div>
      </div>
      <div class="row" *ngIf="reagent.reagentLink">
        <div class="col-2">Link:</div>
        <div class="col-9">
          <a [href]="reagent.reagentLink">{{ reagent.reagentLink }}</a>
        </div>
      </div>
    </div>
    <hr />
  </div>
</div>
