import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {SelectedBivalentFilters} from '../bivalent-filters/bivalent-filters.component';
import {BivalentReportTooltipComponent} from '../bivalent-report-tooltip/bivalent-report-tooltip.component';
import {BivalentReportTooltipValueComponent} from '../bivalent-report-tooltip/bivalent-report-tooltip-value.component';
import {BivalentFilterService} from '../bivalent-filter/bivalent-filter.service';
import {BivalentBoosterPoint} from '../../../models/dtos/bivalent-booster/bivalentbooster';
import {BivalentBoosterApiService} from '../../../services/api/bivalent-booster-api/bivalent-booster-api.service';
@Component({
  selector: 'app-bivalent-data',
  templateUrl: './bivalent-data.component.html',
  styleUrls: ['./bivalent-data.component.scss']
})
export class BivalentDataComponent implements OnInit {
  public allBivalentBoosterPoints!: BivalentBoosterPoint[];
  public selectedBivalentBoosterPoints!: BivalentBoosterPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;

  constructor(private variantApi: BivalentBoosterApiService, private BivalentFilters: BivalentFilterService) {
    this.frameworkComponents = {
      customTooltip: BivalentReportTooltipComponent,
      customValueTooltip: BivalentReportTooltipValueComponent,
      datasourceRenderer: DatasourceRendererComponent
    };
    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };
    this.columnDefs = [
      {
        field: 'mvbReportNumber',
        headerName: 'Report Number',
        width: 105,
        sortable: true,
        resizable: true,
        tooltipField: 'mvbReportNumber',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'Data Source',
        cellRenderer: 'datasourceRenderer'
      },
      {
        field: 'dataSourceType',
        headerName: 'Data Source Type'
      },
      {
        field: 'sampleSource',
        headerName: 'Sample Source',
        width: 100,
        filter: true,
        resizable: true
      },
      {
        field: 'infectionHistory',
        headerName: 'Infection Status',
        cellRenderer: this.getTestStrainValue
      },
      {
        field: 'vacHistory',
        headerName: 'Vaccination History',
        cellRenderer: this.getTestStrainValue
      },
      {
        field: 'vacBoost',
        headerName: 'Booster Vaccine'
      },
      {field: 'boostClass', headerName: 'Booster Class'},
      {field: 'assayCat', headerName: 'Assay Category'},
      {field: 'assayType', headerName: 'Assay Type'},
      {
        field: 'assayTypeMod',
        headerName: 'Assay Modality',
        width: 135
      },
      {
        field: 'viralLineage',
        headerName: 'Viral Lineage',
        width: 120,
        tooltipField: 'viralLineage',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'vaccineActivity',
        headerName: 'Assay Readout',
        width: 150,
        tooltipField: 'vaccineActivity',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Time1Unit',
        headerName: 'Time Point 1',
        width: 125,
        tooltipField: 'boost1Time1Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect1',
        headerName: 'Time Point 1 Effectiveness',
        width: 135
      },
      {
        field: 'boost1Time2Unit',
        headerName: 'Time Point 2',
        width: 125,
        tooltipField: 'boost1Time2Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect2',
        headerName: 'Time Point 2 Effectiveness',
        width: 125
      },
      {
        field: 'boost1Time3Unit',
        headerName: 'Time Point 3',
        width: 125,
        tooltipField: 'boost1Time3Unit',
        tooltipComponent: 'customValueTooltip'
      },
      {
        field: 'boost1Effect3',
        headerName: 'Time Point 3 Effectiveness',
        width: 125
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public getTestStrainValue = (params: any) => {
    return params.data.animalModelsLinkedPage
      ? `<a target="_blank" href="${params.data.animalModelsLinkedPage}">${params.value}</a>`
      : params.value;
  };

  public ngOnInit(): void {
    const BivalentBoosterDataPoints$ = this.variantApi.getBivalentBooster();

    forkJoin([BivalentBoosterDataPoints$]).subscribe(([BivalentBoosterDataPoints]) => {
      this.allBivalentBoosterPoints = BivalentBoosterDataPoints;
      this.selectedBivalentBoosterPoints = BivalentBoosterDataPoints;
      this.rowCount = this.selectedBivalentBoosterPoints.length;
      this.totalRows = this.allBivalentBoosterPoints.length;
    });
  }

  public filterTable(filters: SelectedBivalentFilters) {
    this.selectedBivalentBoosterPoints = this.BivalentFilters.filterPoints(this.allBivalentBoosterPoints, filters);
    this.rowCount = this.selectedBivalentBoosterPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}
