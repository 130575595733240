import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {HorizontalChartDataModel, HorizontalChartService} from './d3-horizontal-chart.service';

@Component({
  selector: 'app-d3-horizontal-chart',
  templateUrl: './d3-horizontal-chart.component.html',
  styleUrls: ['./d3-horizontal-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class D3HorizontalChartComponent implements OnInit {
  @Input() horizontalChartData: HorizontalChartDataModel[] = [];

  constructor(private horizontalChartTopTenService: HorizontalChartService) {}

  ngOnInit(): void {
    this.horizontalChartTopTenService.onInit(this.horizontalChartData);
  }
}
