import {Injectable} from '@angular/core';
import * as d3 from 'd3';
import {VariantSummary} from '../../../models/dtos/variant/summary';
import {SummaryDto} from '../../../models/view-models/summary-dto';

@Injectable({
  providedIn: 'root'
})
export class SummaryMapperService {
  constructor() {}

  private flatten(summaries: VariantSummary[]): SummaryDto[] {
    return summaries.reduce<SummaryDto[]>((acc, s) => {
      const items = s.theraputics.map((t) => {
        const summary: SummaryDto = {
          drugName: t.drugName,
          drugClass: t.drugClass,
          viralType: s.viralType,
          variant: s.viralLineage,
          uploadDate: s.lastUploadDate
        };
        return summary;
      });

      return acc.concat(items);
    }, []);
  }

  public mapToMobileFormat(
    summaries: VariantSummary[],
    orderedLineages: string[]
  ): Map<string, Map<string, Map<string, SummaryDto[]>>> {
    const flatSummaries = this.flatten(summaries);
    const data = d3.group(
      flatSummaries,
      (d: SummaryDto) => d.variant,
      (d: SummaryDto) => d.drugClass,
      (d: SummaryDto) => d.drugName
    );
    const orderedData = new Map<string, Map<string, Map<string, SummaryDto[]>>>();
    orderedLineages.forEach((x) => orderedData.set(x, data.get(x) ?? new Map<string, Map<string, SummaryDto[]>>()));
    return orderedData;
  }
}
