import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterStateService {
  private filterStateSubject = new BehaviorSubject<Map<string, any>>(new Map<string, any>());

  getFilterStateObservable() {
    return this.filterStateSubject.asObservable();
  }

  getFilterState(): Map<string, any> {
    return this.filterStateSubject.getValue();
  }

  setFilterState(state: Map<string, any>): void {
    this.filterStateSubject.next(state);
  }

  clearFilterState(): void {
    this.filterStateSubject.next(new Map<string, any>());
  }
}
